import { deserializeLocation, Location } from '../../models/user';
import RequestUtils from '../../utils/request';

// Only use latlng or zipcode
export async function resolveLocationRemote(latlng: string, zipcode: string): Promise<{
  location: Location | null,
  errCode: string,
}> {
  const req: any = {
    resolveLocation: {
      latlng: latlng || undefined,
      zipcode: zipcode || undefined,
    },
  };
  const resp = await RequestUtils.invokeRemoteApi(req, {});

  return {
    location: resp.resolveLocation.location ? deserializeLocation(resp.resolveLocation.location) : null,
    errCode: resp.resolveLocation.errCode,
  };
}

