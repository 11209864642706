import React from 'react';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import {
  Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs, unix } from 'dayjs';

import { TaroPassCategoryType, TaroPassRecord } from 'common/src/models/event';
import { ResourceRef } from 'common/src/models/shared';
import {
  taroPassCategorySchema, taroPassDetailSchema, taroPassIntroSchema, taroPassNameSchema,
  taroPassTsSchema,
} from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';
import { ImageEditor } from '../../../../components/base';
import LocationEditor from '../../../../components/base/LocationEditor';
import StatusBanner from '../../../../components/event/StatusBanner';
import ContactEditor from '../../eventTemplate/EventTemplateEditorScreen/ContactEditor';

interface IProps {
  taroPass: TaroPassRecord;
  showValidationErr: boolean;
  onTaroPassChange: (taroPass: TaroPassRecord) => void;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const Editor: React.FC<IProps> = ({
  taroPass, showValidationErr, onTaroPassChange, onUploadImg,
}) => {
  const {
    id, name, published, category, intro, detail, location, refMedia, contact, createdTs, expiryTs,
  } = taroPass;
  const handleTaroPassChange = (mutationFn: ((prevTaroPass: TaroPassRecord) => void)) => {
    const clonedTaroPass = JSON.parse(JSON.stringify(taroPass));
    mutationFn(clonedTaroPass);
    onTaroPassChange(clonedTaroPass);
  };

  return (
    <Box>
      <Text size='title'>Edit TaroPass</Text>
      <Text variant='italics' color='system' mb={16}>Please confirm the taroPass content</Text>
      <StatusBanner
        confirmPublished={taroPass.published}
        archived={false}
        draftPublished={published}
        readOnlyMode={false}
        onChangePublished={(published) => {
          handleTaroPassChange((prevTaroPass) => {
            prevTaroPass.published = published;
          });
        }}
      />
      <Box display='flex'>
        <Text size='paragraph' mr={8} alignSelf='flex-end' mb={3}>ID:</Text>
        <Text size='title'>{id}</Text>
      </Box>
      <Box display='flex'>
        <Box sx={{ my: 16, mr: 80 }}>
          <UserInput validationFn={showValidationErr ? () => taroPassNameSchema.validate(name) : null}>
            <TextField
              variant='standard'
              required
              label='商家名称'
              placeholder='请输入商家名称'
              value={name}
              onChange={(event) => {
                handleTaroPassChange((prevTaroPass) => {
                  prevTaroPass.name = event.target.value;
                });
              }}
              sx={{ width: 300 }}
            />
          </UserInput>
        </Box>

        <UserInput validationFn={showValidationErr ? () => taroPassCategorySchema.validate(category) : null}>
          <FormControl variant='standard' margin='normal' sx={{ width: 200, mr: 160 }}>
            <InputLabel required>Category</InputLabel>
            <Select
              value={category}
              onChange={(event: SelectChangeEvent)=>{
                handleTaroPassChange((prevTaroPass) => {
                  prevTaroPass.category = event.target.value as TaroPassCategoryType;
                });
              }}
            >
              <MenuItem value={TaroPassCategoryType.UNDEFINED}>-</MenuItem>
              {[
                TaroPassCategoryType.FOOD,
                TaroPassCategoryType.DRINK,
                TaroPassCategoryType.ENTERTAINMENT,
                TaroPassCategoryType.LIFE,
                TaroPassCategoryType.EDUCATION,
              ].
                map((category) => <MenuItem key={category} value={category}>{category}</MenuItem>)}
            </Select>
          </FormControl>
        </UserInput>
      </Box>

      <Box sx={{ my: 16 }}>
        <ImageEditor
          imgUrls={refMedia.downloadUrl ? [refMedia.downloadUrl] : []}
          multipleImgs={false}
          required
          readOnlyMode={false}
          showValidationErr={showValidationErr && published }
          onUploadImgs={async (files: File[]) => {
            if (files.length > 0) {
              const ref = await onUploadImg(files[0]);
              handleTaroPassChange((prevTaroPass) => {
                prevTaroPass.refMedia = ref;
              });
            }
          }}
          onRemoveImg={()=>{
            handleTaroPassChange((prevTaroPass) => {
              prevTaroPass.refMedia = { id: '', downloadUrl: '' };
            });
          }}
        />
      </Box>

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr && published ? () => taroPassDetailSchema.validate(detail): null}>
          <TextField
            required
            multiline
            fullWidth
            label='折扣细节'
            placeholder='请输入折扣细节'
            value={detail}
            onChange={(event) => {
              handleTaroPassChange((prevTaroPass) => {
                prevTaroPass.detail = event.target.value;
              });
            }}
          />
        </UserInput>
      </Box>

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr && published ? () => taroPassIntroSchema.validate(intro): null}>
          <TextField
            required
            multiline
            fullWidth
            label='商家介绍'
            placeholder='请输入商家介绍'
            value={intro}
            onChange={(event) => {
              handleTaroPassChange((prevTaroPass) => {
                prevTaroPass.intro = event.target.value;
              });
            }}
          />
        </UserInput>
      </Box>

      <ContactEditor
        contact={contact}
        readOnlyMode={false}
        showValidationErr={showValidationErr && published}
        onChangeContact={(newContact) => {
          handleTaroPassChange((prevTaroPass) => {
            prevTaroPass.contact = newContact;
          });
        }}
        onUploadImg={onUploadImg}
      />

      <Box sx={{ my: 16 }}>
        <UserInput validationFn={showValidationErr && published ?
          () => taroPassTsSchema.validate({ createdTs, expiryTs }) :
          null}>
          <Box display='flex' alignItems='center'>
            <Text size='paragraph' color='system' width={80}>有效期</Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='From'
                value={unix(createdTs / 1000)}
                onChange={(newValue: Dayjs | null)=>{
                  if (newValue) {
                    handleTaroPassChange((prevTaroPass) => {
                      prevTaroPass.createdTs = newValue.valueOf();
                    });
                  }
                }}
              />
            </LocalizationProvider>
            <ArrowForwardIcon sx={{ mx: 16 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='To'
                value={unix(expiryTs / 1000)}
                onChange={(newValue: Dayjs | null)=>{
                  if (newValue) {
                    handleTaroPassChange((prevTaroPass) => {
                      prevTaroPass.expiryTs = newValue.valueOf();
                    });
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </UserInput>
      </Box>

      <LocationEditor
        location={location}
        title='商家地址'
        readOnlyMode={false}
        showValidationErr={showValidationErr && published}
        onChangeLocation={(newLocation) => {
          handleTaroPassChange((prevTaroPass) => {
            prevTaroPass.location = newLocation;
          });
        }}
      />
    </Box>
  );
};

export default Editor;
