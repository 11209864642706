import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Link } from '@mui/material';
import { ValidationError } from 'yup';

import { Colors } from 'common/src/constants';
import { EventTemplateGroup } from 'common/src/models/event';
import { uploadEventImageRemote } from 'common/src/system/network/event';
import { readFile } from 'common/src/utils/file';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { useEventTemplateGroup, useEventTemplates } from '../../../../hooks/useResource';
import { saveEventTemplateGroup } from '../../../../redux/slices/event';
import { eventTemplateGroupSchema } from '../../../../utils/validation';

import { Page } from 'common/src/components/base';
import Editor from './Editor';

export default function EventTemplateGroupEditorScreen() {
  const { eventTemplateGroupId } = useParams();
  if (!eventTemplateGroupId) {
    throw new Error('BUG: eventTemplateGroupId missing for EventTemplateGroupEditorScreen');
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const eventTemplateGroup = useEventTemplateGroup(eventTemplateGroupId, /* forceRefresh */ true);
  const { data: eventTemplates } = useEventTemplates( /* includePastEvents */ true, /* forceRefresh */ false);

  // eventTemplateGroup is the redux/server view, while draftEventTemplateGroup is WIP.
  const [draftEventTemplateGroup, setDraftEventTemplateGroup] = React.useState<EventTemplateGroup>(
    JSON.parse(JSON.stringify(eventTemplateGroup)),
  );

  React.useEffect(() => {
    if (draftEventTemplateGroup === null) {
      setDraftEventTemplateGroup(JSON.parse(JSON.stringify(eventTemplateGroup)));
    }
  }, [draftEventTemplateGroup, eventTemplateGroup]);

  const [hasPendingChanges, setHasPendingChanges] = React.useState(false);
  React.useEffect(() => {
    const draftEventTemplateGroupCopy = {
      ...draftEventTemplateGroup,
      media: {
        id: draftEventTemplateGroup ? draftEventTemplateGroup.media.id : '',
        // Remove download url since that can change all the time
      },
      thumbnailMedia: {},
      extraMedia: draftEventTemplateGroup ? draftEventTemplateGroup.extraMedia.map((media) => {
        media.id;
      }): [],
      extraThumbnailMedia: [],
    };
    const eventTemplateGroupCopy = {
      ...eventTemplateGroup,
      media: {
        id: eventTemplateGroup ? eventTemplateGroup.media.id : '',
        // Remove download url since that can change all the time
      },
      thumbnailMedia: {},
      extraMedia: eventTemplateGroup ? eventTemplateGroup.extraMedia.map((media) => {
        media.id;
      }): [],
      extraThumbnailMedia: [],
    };

    setHasPendingChanges(JSON.stringify(draftEventTemplateGroupCopy) !== JSON.stringify(eventTemplateGroupCopy));
  }, [eventTemplateGroup, draftEventTemplateGroup]);

  const [showValidationErr, setShowValidationErr] = React.useState(false);
  const [readOnlyMode, setReadOnlyMode] = React.useState(true);

  const validateEventTemplateId = React.useCallback((eventTemplateId: string) => {
    if (!Object.values(eventTemplates).some((eventTemplate) => eventTemplate.id === eventTemplateId)) {
      throw new ValidationError('Event template id \'' + eventTemplateId + '\' is invalid');
    }
  }, [eventTemplates]);
  const isEventTemplateIdValid = React.useCallback((eventTemplateId: string) => {
    try {
      validateEventTemplateId(eventTemplateId);
      return true;
    } catch (err) {
      return false;
    }
  }, [validateEventTemplateId]);

  const savePendingChanges = React.useCallback(async () => {
    // Clean up everything
    const cleanedDraftEventTemplateGroup: EventTemplateGroup = JSON.parse(JSON.stringify(draftEventTemplateGroup));
    cleanedDraftEventTemplateGroup.name = cleanedDraftEventTemplateGroup.name.trim();
    cleanedDraftEventTemplateGroup.description = cleanedDraftEventTemplateGroup.description.trim();
    cleanedDraftEventTemplateGroup.intro = cleanedDraftEventTemplateGroup.intro.trim();
    if (JSON.stringify(draftEventTemplateGroup) !== JSON.stringify(cleanedDraftEventTemplateGroup)) {
      setDraftEventTemplateGroup(cleanedDraftEventTemplateGroup);
    }

    try {
      eventTemplateGroupSchema.validateSync(cleanedDraftEventTemplateGroup);
      for (const groupedEventTemplate of cleanedDraftEventTemplateGroup.groupedEventTemplates) {
        validateEventTemplateId(groupedEventTemplate.eventTemplateId);
      }
    } catch (validationErr) {
      if (validationErr instanceof ValidationError && validationErr.errors.length) {
        console.log('validation error: ' + validationErr.errors[0]);
      } else {
        console.log('unknown validation error');
      }
      setShowValidationErr(true);
      return;
    }
    setShowValidationErr(false);

    // Do update
    setSavingEventTemplateGroup(true);
    await dispatch(saveEventTemplateGroup(cleanedDraftEventTemplateGroup));
    setSavingEventTemplateGroup(false);
  }, [dispatch, draftEventTemplateGroup, validateEventTemplateId]);

  const handleFabPress = () => {
    if (readOnlyMode) {
      setReadOnlyMode(false);
    } else {
      savePendingChanges();
    }
  };

  const [savingEventTemplateGroup, setSavingEventTemplateGroup] = React.useState(false);

  if (!draftEventTemplateGroup || !eventTemplateGroup || !eventTemplates) {
    return null;
  }

  const saveDisabled = !readOnlyMode && (!hasPendingChanges || eventTemplateGroup.id.startsWith('_test_'));
  const fabView = (
    <IconButton
      onClick={handleFabPress}
      sx={{ position: 'fixed', right: 30, bottom: 50 }}
      disabled={savingEventTemplateGroup || saveDisabled}
    >
      <Box
        sx={{
          width: 60, height: 60, p: 10, borderRadius: 60, boxShadow: 1,
          bgcolor: saveDisabled ? Colors.GREY100 : Colors.PRIMARY_BACKGROUND,
        }}
      >
        {
          readOnlyMode ? (
            <EditIcon
              sx={{
                width: 40, height: 40, color: Colors.PRIMARY,
              }}
            />
          ) : !savingEventTemplateGroup ? (
            <SaveIcon
              sx={{
                width: 40, height: 40,
                color: showValidationErr && (
                  !eventTemplateGroupSchema.isValidSync(draftEventTemplateGroup) ||
                  draftEventTemplateGroup.groupedEventTemplates
                    .some((groupedEventTemplate) => !isEventTemplateIdValid(groupedEventTemplate.eventTemplateId))) ?
                  Colors.ERROR :
                  saveDisabled ? Colors.DISABLED : Colors.PRIMARY,
              }}
            />
          ) : (
            <CircularProgress size={40} />
          )
        }
      </Box>
    </IconButton>
  );

  return (
    <Page>
      <Link onClick={() => navigate(-1)} underline='hover' sx={{ display: 'flex' }}>
        <ArrowBackOutlinedIcon />
          Return to Event Template Group selection
      </Link>
      <Editor
        eventTemplateGroup={draftEventTemplateGroup}
        confirmPublished={eventTemplateGroup.published}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr}
        validateEventTemplateId={validateEventTemplateId}
        onEventTemplateGroupChange={(mutationFn: ((prevEventTemplateGroup: EventTemplateGroup) => void)) => {
          setDraftEventTemplateGroup((prevDraftEventTemplateGroup) => {
            const clonedDraftEventTemplateGroup = JSON.parse(JSON.stringify(prevDraftEventTemplateGroup));
            mutationFn(clonedDraftEventTemplateGroup);
            return clonedDraftEventTemplateGroup;
          });
        }}
        onUploadImg={async (f) => {
          const base64Content = await readFile(f);
          const fileType = f.type.replace('image/', '');
          return await uploadEventImageRemote(eventTemplateGroupId, base64Content, fileType);
        }}
      />
      {fabView}
    </Page>
  );
}
