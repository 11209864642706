import React from 'react';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import {
  Box, Button, Checkbox, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Select,
  SelectChangeEvent, Stack, TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs, unix } from 'dayjs';

import { EventTaskCategory, EventTemplate, QuestionType, ScreenType } from 'common/src/models/event';
import { ADDITIONAL_ADVERTISED_STATES, EVENT_TAGS, GeoStateCode, GeoStateName, WILDCARD_STATE } from 'common/src/models/event/eventTemplate';
import { TicketOptionVisibilitySetting } from 'common/src/models/event/ticket';
import { ResourceRef } from 'common/src/models/shared';
import { randStr } from 'common/src/utils/rand';
import { useVendors } from '../../../../hooks/useResource';
import { sortSubsetByRef } from '../../../../utils/string';
import {
  additionalAdvertisedStatesSchema,
  cancellationHoursAfterFreeEventConfirmationSchema,
  cancellationHoursAfterPaidEventConfirmationSchema,
  cancellationHoursBeforeEventSchema, eventDescriptionSchema, eventFeePolicySchema,
  eventIntroSchema, eventNameSchema, eventTagsSchema, eventTsSchema,
  singlesEventMaxSelectableTicketOptionsSchema, urlSchema,
  waiverSchema,
} from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';
import { ImageEditor } from '../../../../components/base';
import LocationEditor from '../../../../components/base/LocationEditor';
import { ArchivalToggle, StatusBanner } from '../../../../components/event';
import ContactEditor from './ContactEditor';
import DiscountEditor from './DiscountEditor';
import SurveyEditor from './SurveyEditor';
import TicketEditor from './TicketEditor';

interface IProps {
  eventTemplate: EventTemplate;
  confirmPublished: boolean;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  onEventTemplateChange: (mutationFn: ((prevEventTemplate: EventTemplate) => void)) => void;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const Editor: React.FC<IProps> = ({ eventTemplate, confirmPublished,
  readOnlyMode, showValidationErr, onEventTemplateChange, onUploadImg }) => {
  const {
    id, published, archived, category, name, intro, description, eventFromTs, eventToTs, tags,
    location, additionalAdvertisedStates, payload, singlesEvent,
  } = eventTemplate;

  const {
    detailsUrl, registrationFromTs, registrationToTs, screeningType, ticketOptions,
    maxSelectableTicketOptions, minSelectableTickets, maxSelectableTickets,
    allowMultipleTicketOrders, surveyQuestions, feePolicy, contact,
    cancellationHoursAfterEventConfirmation, cancellationHoursBeforeEvent, vendorId,
    visibilitySetting, discounts, waiver,
  } = payload.hostedEventPayload;

  const { data: vendors } = useVendors();

  const [inputTaxPercentage, setInputTaxPercentage] = React.useState((feePolicy.taxPercentage * 100).toString());
  const [inputFeePercentage, setInputFeePercentage] = React.useState((feePolicy.feePercentage * 100).toString());
  const [inputFixedFee, setInputFixedFee] = React.useState(
    feePolicy.feeInCents % 100 === 0 ?
      (feePolicy.feeInCents / 100).toFixed(0) :
      (feePolicy.feeInCents / 100).toFixed(2),
  );

  let isFree = true;
  for (const ticketOption of ticketOptions) {
    if (singlesEvent && (ticketOption.maleCostInCents || ticketOption.femaleCostInCents) ||
          !singlesEvent && ticketOption.genericCostInCents) {
      isFree = false;
      break;
    }
  }

  return (
    <Box sx={{ mx: 16, mt: 16 }}>
      <StatusBanner
        confirmPublished={confirmPublished}
        draftPublished={published}
        readOnlyMode={readOnlyMode}
        onChangePublished={(published) => {
          onEventTemplateChange((prevEventTemplate) => {
            prevEventTemplate.published = published;
          });
        }}
        archived={archived}
      />
      <Box display='flex'>
        <Text size='paragraph' mr={8} alignSelf='flex-end' mb={3}>ID:</Text>
        <Text size='title'>{id}</Text>
      </Box>
      <Stack direction='row'>
        <Box sx={{ mt: 16, mr: 80 }}>
          <UserInput validationFn={showValidationErr ? () => eventNameSchema.validate(name) : null}>
            <TextField
              variant='standard'
              required
              label='Name'
              placeholder='请输入活动名称'
              value={name}
              onChange={(event) => {
                onEventTemplateChange((prevEventTemplate) => {
                  prevEventTemplate.name = event.target.value;
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
              sx={{ width: 300 }}
            />
          </UserInput>
        </Box>

        <Box>
          <Box>
            <FormControl variant='standard' margin='normal' sx={{ width: 200, mr: 50 }}>
              <InputLabel>Event Attribute</InputLabel>
              <Select
                value={singlesEvent ? 'singles' : 'general'}
                onChange={(event: SelectChangeEvent)=>{
                  onEventTemplateChange((prevEventTemplate) => {
                    const newSinglesValue = event.target.value === 'singles';
                    if (prevEventTemplate.singlesEvent === newSinglesValue) {
                      return;
                    }
                    prevEventTemplate.singlesEvent = newSinglesValue;

                    const hostedEventPayload = prevEventTemplate.payload.hostedEventPayload;
                    if (newSinglesValue) {
                      hostedEventPayload.maxSelectableTicketOptions = 1;
                      hostedEventPayload.minSelectableTickets = 0;
                      hostedEventPayload.maxSelectableTickets = 0;
                    }
                    hostedEventPayload.ticketOptions.forEach((ticketOption) => {
                      if (newSinglesValue) {
                        ticketOption.maxQuantityPerUser = 1;
                      }
                      ticketOption.maxMaleParticipants = 0;
                      ticketOption.maxFemaleParticipants = 0;
                      ticketOption.maxGenericParticipants = 0;
                      ticketOption.maxTotalParticipants = 0;
                    });
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
              >
                <MenuItem value='singles'>Singles Event</MenuItem>
                <MenuItem value='general'>General Event</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant='standard' margin='normal' disabled sx={{ width: 200 }}>
              <InputLabel>Category</InputLabel>
              <Select
                value={category}
                onChange={(event) => {
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.category = event.target.value as EventTaskCategory;
                  });
                }}
              >
                <MenuItem value={EventTaskCategory.HOSTED_EVENT}>Hosted Event</MenuItem>
                <MenuItem value={EventTaskCategory.TASK_CARD}>Task card</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl variant='standard' margin='normal' sx={{ width: 200, mr: 50 }}>
              <InputLabel>Screening Type</InputLabel>
              <Select
                value={screeningType}
                onChange={(event: SelectChangeEvent)=>{
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.screeningType = event.target.value as ScreenType;
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
              >
                <MenuItem value={ScreenType.BY_SELECTION}>By Selection</MenuItem>
                <MenuItem value={ScreenType.FIRST_COME_FIRST_SERVE}>First Come First Serve</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant='standard' margin='normal' sx={{ width: 200 }}>
              <InputLabel>Vendor</InputLabel>
              <Select
                value={vendorId}
                onChange={(event) => {
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.vendorId = event.target.value;
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
              >
                {(vendors || []).map((vendor) => {
                  return (
                    <MenuItem key={vendor.id} value={vendor.id}>{vendor.name}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Stack>

      <Box sx={{ my: 16 }}>
        <ImageEditor
          imgUrls={eventTemplate.media.downloadUrl ? [eventTemplate.media.downloadUrl] : []}
          multipleImgs={false}
          required
          readOnlyMode={readOnlyMode}
          showValidationErr={showValidationErr && published}
          onUploadImgs={async (files: File[]) => {
            if (files.length > 0) {
              const ref = await onUploadImg(files[0]);
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.media = ref;
              });
            }
          }}
          onRemoveImg={()=>{
            onEventTemplateChange((prevEventTemplate) => {
              prevEventTemplate.media = { id: '', downloadUrl: '' };
            });
          }}
        />
      </Box>

      <Box sx={{ my: 16 }}>
        <UserInput validationFn={showValidationErr && published ? () => eventIntroSchema.validate(intro) : null}>
          <TextField
            variant='standard'
            required
            fullWidth
            label='Event Introduction'
            placeholder='请输入活动介绍'
            value={intro}
            onChange={(event) => {
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.intro = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>
      <Box sx={{ my: 16 }}>
        <UserInput validationFn={showValidationErr && published ? () => eventTagsSchema.validate(tags) : null}>
          <FormControl sx={{ minWidth: 250 }} variant='standard' >
            <InputLabel>Tags</InputLabel>
            <Select
              multiple
              value={tags}
              onChange={(event: SelectChangeEvent<string[]>) => {
                onEventTemplateChange((prevEventTemplate) => {
                  // On autofill we get a stringified value.
                  const newTags = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
                  prevEventTemplate.tags = sortSubsetByRef(newTags, Object.keys(EVENT_TAGS));
                });
              }}
              renderValue={(selectedTags) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                  {selectedTags.map((selectedTag) => (
                    <Chip key={selectedTag} label={selectedTag} variant='outlined' size='small'/>
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 240, // This is enough to cut last item by half to hint scrollability
                  },
                },
              }}
              inputProps={{ readOnly: readOnlyMode }}
            >
              {Object.keys(EVENT_TAGS).map((eventTag) => (
                <MenuItem
                  key={eventTag}
                  value={eventTag}
                  style={tags.includes(eventTag) ? { 'fontWeight': 'bold' } : { 'fontWeight': 'normal' }}
                >
                  {eventTag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </UserInput>
      </Box>

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr && published ? () => eventDescriptionSchema.validate(description): null}>
          <TextField
            required
            multiline
            fullWidth
            label='Event Description'
            placeholder='请输入活动描述'
            value={description}
            onChange={(event) => {
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.description = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>

      <Box sx={{ my: 16 }}>
        <UserInput validationFn={showValidationErr && published ?
          () => eventTsSchema.validate({ registrationFromTs, registrationToTs, eventFromTs, eventToTs }) :
          null}>
          <Box display='flex' alignItems='center'>
            <Text size='paragraph' color='system' width={80}>报名时间</Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='From'
                value={unix(registrationFromTs / 1000)}
                onChange={(newValue: Dayjs | null)=>{
                  if (newValue) {
                    onEventTemplateChange((prevEventTemplate) => {
                      prevEventTemplate.payload.hostedEventPayload.registrationFromTs = newValue.valueOf();
                    });
                  }
                }}
                readOnly={readOnlyMode}
              />
            </LocalizationProvider>
            <ArrowForwardIcon sx={{ mx: 16 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='To'
                value={unix(registrationToTs / 1000)}
                onChange={(newValue: Dayjs | null)=>{
                  if (newValue) {
                    onEventTemplateChange((prevEventTemplate) => {
                      prevEventTemplate.payload.hostedEventPayload.registrationToTs = newValue.valueOf();
                    });
                  }
                }}
                readOnly={readOnlyMode}
              />
            </LocalizationProvider>
          </Box>

          <Box display='flex' alignItems='center' sx={{ mt: 16 }}>
            <Text size='paragraph' color='system' width={80}>活动时间</Text>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='From'
                value={unix(eventFromTs / 1000)}
                onChange={(newValue: Dayjs | null)=>{
                  if (newValue) {
                    onEventTemplateChange((prevEventTemplate) => {
                      prevEventTemplate.eventFromTs = newValue.valueOf();
                    });
                  }
                }}
                readOnly={readOnlyMode}
              />
            </LocalizationProvider>
            <ArrowForwardIcon sx={{ mx: 16 }} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label='To'
                value={unix(eventToTs / 1000)}
                onChange={(newValue: Dayjs | null)=>{
                  if (newValue) {
                    onEventTemplateChange((prevEventTemplate) => {
                      prevEventTemplate.eventToTs = newValue.valueOf();
                    });
                  }
                }}
                readOnly={readOnlyMode}
              />
            </LocalizationProvider>
          </Box>
        </UserInput>
      </Box>

      <TicketEditor
        ticketOptions={ticketOptions}
        isSinglesEvent={singlesEvent}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr && published}
        eventRegistrationFromTs={registrationFromTs}
        eventRegistrationToTs={registrationToTs}
        onChangeTicketOptions={((newTicketOptions) => {
          onEventTemplateChange((prevEventTemplate) => {
            prevEventTemplate.payload.hostedEventPayload.ticketOptions = newTicketOptions;
          });
        })}
      />
      {!readOnlyMode && (
        <Button
          sx={{ mb: 20, ml: 70 }}
          onClick={() => {
            onEventTemplateChange((prevEventTemplate) => {
              const updatedTicketOptions = [
                ...ticketOptions,
                {
                  id: 't_'+randStr(5),
                  name: '',
                  description: '',

                  maxMaleParticipants: 0,
                  maxFemaleParticipants: 0,
                  maxGenericParticipants: 0,
                  maxTotalParticipants: 0,

                  maleCostInCents: 0,
                  femaleCostInCents: 0,
                  genericCostInCents: 0,

                  maxQuantityPerUser: 1,
                  addOns: [],

                  allowWaitlist: true,
                  registrationFromTs: 0,
                  registrationToTs: 0,
                  visibilitySetting: TicketOptionVisibilitySetting.ALWAYS,
                },
              ];

              prevEventTemplate.payload.hostedEventPayload.ticketOptions = updatedTicketOptions;
            });
          }}
        >
          Add Ticket Option
        </Button>
      )}
      <Box ml={80} mt={20} mb={40}>
        <UserInput
          validationFn={showValidationErr && singlesEvent ?
            () => singlesEventMaxSelectableTicketOptionsSchema.validate(maxSelectableTicketOptions) :
            null}
        >
          <Stack direction='row'>
            <Box mr={40}>
              <TextField
                variant='standard'
                required
                disabled={singlesEvent}
                label='最多可选票种类（每订单）'
                placeholder='请输入最多可选票选项'
                value={maxSelectableTicketOptions}
                onChange={(event)=>{
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.maxSelectableTicketOptions = +event.target.value;
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ width: 160 }}
              />
            </Box>
            <Box mr={40}>
              <TextField
                variant='standard'
                required
                disabled={singlesEvent}
                label='最少可选票数（每订单）'
                placeholder='请输入最少可选票数（0 代表无限制）'
                value={minSelectableTickets}
                onChange={(event)=>{
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.minSelectableTickets = +event.target.value;
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ width: 150 }}
              />
            </Box>
            <Box mr={40}>
              <TextField
                variant='standard'
                required
                disabled={singlesEvent}
                label='最多可选票数（每订单）'
                placeholder='请输入最多可选票数（0 代表无限制）'
                value={maxSelectableTickets}
                onChange={(event)=>{
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.maxSelectableTickets = +event.target.value;
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ width: 150 }}
              />
            </Box>
            <Stack direction='row' alignItems='center'>
              <Checkbox
                checked={allowMultipleTicketOrders}
                onChange={(event) => {
                  if (readOnlyMode) {
                    return;
                  }
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.allowMultipleTicketOrders = event.target.checked;
                  });
                }}
              />
              <Text>允许多订单</Text>
            </Stack>
          </Stack>
        </UserInput>
      </Box>

      <Box ml={80} mt={20} mb={40}>
        <UserInput
          validationFn={showValidationErr ? () => eventFeePolicySchema.validate(feePolicy) : null}
        >
          <Stack direction='row'>
            <Box mr={40}>
              <TextField
                variant='standard'
                required
                label='Tax Percentage'
                placeholder='请输入Tax Percentage'
                value={inputTaxPercentage}
                onChange={(event)=>{
                  setInputTaxPercentage(event.target.value.replace(/[^\d.]/g, ''));
                }}
                onBlur={() => {
                  const roundedTaxPercentage = Math.round(+inputTaxPercentage*100)/100;
                  setInputTaxPercentage(roundedTaxPercentage.toString());
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.feePolicy.taxPercentage = roundedTaxPercentage/100;
                  });
                }}
                InputProps={{
                  readOnly: readOnlyMode,
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                }}
                sx={{ width: 100 }}
              />
            </Box>
            <Box mr={40}>
              <TextField
                variant='standard'
                required
                label='Fee Percentage'
                placeholder='Fee Percentage'
                value={inputFeePercentage}
                onChange={(event)=>{
                  setInputFeePercentage(event.target.value.replace(/[^\d.]/g, ''));
                }}
                onBlur={() => {
                  const roundedFeePercentage = Math.round(+inputFeePercentage*100)/100;
                  setInputFeePercentage(roundedFeePercentage.toString());
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.feePolicy.feePercentage = roundedFeePercentage/100;
                  });
                }}
                InputProps={{
                  readOnly: readOnlyMode,
                  endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                }}
                sx={{ width: 100 }}
              />
            </Box>
            <Box>
              <TextField
                variant='standard'
                required
                label='Fixed Fee'
                placeholder='Fixed Fee'
                value={inputFixedFee}
                onChange={(event)=>{
                  setInputFixedFee(event.target.value.replace(/[^\d.]/g, ''));
                }}
                onBlur={() => {
                  const roundedFeeInCents = Math.round(+inputFixedFee*100);
                  if (roundedFeeInCents % 100 === 0) {
                    setInputFixedFee((roundedFeeInCents/100).toFixed(0));
                  } else {
                    setInputFixedFee((roundedFeeInCents/100).toFixed(2));
                  }
                  onEventTemplateChange((prevEventTemplate) => {
                    prevEventTemplate.payload.hostedEventPayload.feePolicy.feeInCents = roundedFeeInCents;
                  });
                }}
                InputProps={{
                  readOnly: readOnlyMode,
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                sx={{ width: 100 }}
              />
            </Box>
          </Stack>
        </UserInput>
      </Box>

      <Box display='flex' alignItems='flex-start'>
        <Text size='paragraph' color='system' width={80} pt={12}>截止时间</Text>
        <UserInput validationFn={showValidationErr && published ? () =>
          cancellationHoursBeforeEventSchema
            .validate(cancellationHoursBeforeEvent) : null}>
          <TextField
            variant='standard'
            required
            label='Cancellation hours before event'
            placeholder='请输入Cancellation hours before event'
            value={cancellationHoursBeforeEvent }
            onChange={(event) => {
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.payload.hostedEventPayload.cancellationHoursBeforeEvent = +event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
            sx={{ mr: 32, width: 200 }}
            helperText = {(eventTemplate.payload.hostedEventPayload.cancellationHoursBeforeEvent/24).toFixed(1) + ' days'}
          />
        </UserInput>

        <UserInput
          validationFn={showValidationErr ? () => {
            const schema = isFree ? cancellationHoursAfterFreeEventConfirmationSchema : cancellationHoursAfterPaidEventConfirmationSchema;
            return schema.validate(cancellationHoursAfterEventConfirmation);
          } : null}
        >
          <TextField
            variant='standard'
            required
            label='Cancellation hours after registration'
            placeholder='请输入Cancellation hours after registration'
            value={cancellationHoursAfterEventConfirmation}
            onChange={(event) => {
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.payload.hostedEventPayload.cancellationHoursAfterEventConfirmation = +event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
            sx={{ mr: 32, width: 200 }}
            helperText = {(eventTemplate.payload.hostedEventPayload.cancellationHoursAfterEventConfirmation/24).toFixed(1) + ' days'}
          />
        </UserInput>
      </Box>

      <Box mt={20} mb={30}>
        <LocationEditor
          location={location}
          title='活动地址'
          readOnlyMode={readOnlyMode}
          showValidationErr={showValidationErr && published}
          onChangeLocation={(newLocation) => {
            onEventTemplateChange((prevEventTemplate) => {
              prevEventTemplate.location = newLocation;
            });
          }}
        />
      </Box>

      <ContactEditor
        contact={contact}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr && published}
        onChangeContact={(newContact) => {
          onEventTemplateChange((prevEventTemplate) => {
            prevEventTemplate.payload.hostedEventPayload.contact = newContact;
          });
        }}
        onUploadImg={onUploadImg}
      />

      <Box display='flex' alignItems='flex-start' my={15}>
        <Text size='paragraph' color='system' width={80} pt={12}>显示设置</Text>

        <Box>
          <Stack direction='row' alignItems='center'>
            <Checkbox
              checked={visibilitySetting.visibleInApp}
              onChange={(event) => {
                if (readOnlyMode) {
                  return;
                }
                onEventTemplateChange((prevEventTemplate) => {
                  prevEventTemplate.payload.hostedEventPayload.visibilitySetting.visibleInApp = event.target.checked;
                });
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Text>在app显示</Text>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <Checkbox
              checked={visibilitySetting.visibleOnWeb}
              onChange={(event) => {
                if (readOnlyMode) {
                  return;
                }
                onEventTemplateChange((prevEventTemplate) => {
                  prevEventTemplate.payload.hostedEventPayload.visibilitySetting.visibleOnWeb = event.target.checked;
                });
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Text>在web显示</Text>
          </Stack>
          <Box sx={{ mx: 16, mb: 30 }}>
            <UserInput validationFn={showValidationErr && published ?
              () => additionalAdvertisedStatesSchema.validate(additionalAdvertisedStates) :
              null}
            >
              <FormControl sx={{ minWidth: 250 }} variant='standard' >
                <InputLabel>Additional Advertised States</InputLabel>
                <Select
                  multiple
                  value={additionalAdvertisedStates}
                  onChange={(event: SelectChangeEvent<string[]>) => {
                    onEventTemplateChange((prevEventTemplate) => {
                      // On autofill we get a stringified value.
                      const newStates = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
                      if (newStates.includes(WILDCARD_STATE)) {
                        prevEventTemplate.additionalAdvertisedStates = [WILDCARD_STATE];
                      } else {
                        prevEventTemplate.additionalAdvertisedStates = sortSubsetByRef(newStates, ADDITIONAL_ADVERTISED_STATES);
                      }
                    });
                  }}
                  renderValue={(additionalAdvertisedStates) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                      {additionalAdvertisedStates.map((state) => (
                        <Chip key={state} label={state.toUpperCase()} variant='outlined' size='small'/>
                      ))}
                    </Box>
                  )}
                  inputProps={{ readOnly: readOnlyMode }}
                >
                  {ADDITIONAL_ADVERTISED_STATES.map((state) => (
                    <MenuItem
                      key={state}
                      value={state}
                      style={additionalAdvertisedStates.includes(state) ? { 'fontWeight': 'bold' } : { 'fontWeight': 'normal' }}
                    >
                      {state in GeoStateName ? GeoStateName[state as GeoStateCode] : state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </UserInput>
          </Box>
        </Box>
      </Box>

      {(!!eventTemplate.extraMedia.length || !readOnlyMode) && (
        <Box display='flex' alignItems='flex-start' my={15}>
          <Text size='paragraph' color='system' width={80} pt={12}>附加图片</Text>
          <Box>
            <ImageEditor
              imgUrls={eventTemplate.extraMedia.map((media) => media.downloadUrl)}
              multipleImgs
              required={false}
              readOnlyMode={readOnlyMode}
              showValidationErr={false}
              onUploadImgs={async (files: File[]) => {
                const extraMediaCopy = [...eventTemplate.extraMedia];
                const promises = files.map(async (file) => {
                  const ref = await onUploadImg(file);
                  extraMediaCopy.push(ref);
                  onEventTemplateChange((prevEventTemplate) => prevEventTemplate.extraMedia = extraMediaCopy);
                });
                await Promise.all(promises);
              }}
              onRemoveImg={(idx: number)=>{
                onEventTemplateChange((prevEventTemplate) => {
                  prevEventTemplate.extraMedia.splice(idx, 1);
                });
              }}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr ? () => urlSchema.validate(detailsUrl) : null}>
          <TextField
            variant='standard'
            fullWidth
            label='详情链接'
            value={detailsUrl}
            onChange={(event) => {
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.payload.hostedEventPayload.detailsUrl = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr ? () => waiverSchema.validate(waiver): null}>
          <TextField
            multiline
            fullWidth
            label='waiver'
            placeholder='请输入waiver'
            value={waiver}
            onChange={(event) => {
              onEventTemplateChange((prevEventTemplate) => {
                prevEventTemplate.payload.hostedEventPayload.waiver = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>

      <DiscountEditor
        discounts={discounts}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr && published}
        allTicketOptions={ticketOptions}
        onChangeDiscounts={((newDiscounts) => {
          onEventTemplateChange((prevEventTemplate) => {
            prevEventTemplate.payload.hostedEventPayload.discounts = newDiscounts;
          });
        })}
      />
      {!readOnlyMode && (
        <Button
          sx={{ mb: 20 }}
          onClick={() => {
            onEventTemplateChange((prevEventTemplate) => {
              const updatedDiscounts = [
                ...discounts,
                {
                  couponCode: '',
                  automatic: false,
                  discountQualifier: {
                    ticketOptionIds: [],
                    clientTypes: [],
                    minTicketsInOrder: 0,
                  },
                  discountValue: {
                    unitAdjustmentInCents: 0,
                    percentAdjustment: 0,
                    adjustedUnitCostInCents: 0,
                  },
                },
              ];

              prevEventTemplate.payload.hostedEventPayload.discounts = updatedDiscounts;
            });
          }}
        >
          Add Discount
        </Button>
      )}

      <SurveyEditor
        surveyQuestions={surveyQuestions}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr}
        onChangeSurveyQuestions={((newSurveyQuestions) => {
          onEventTemplateChange((prevEventTemplate) => {
            prevEventTemplate.payload.hostedEventPayload.surveyQuestions = newSurveyQuestions;
          });
        })}
      />
      {!readOnlyMode && (
        <Button
          sx={{ mb: 20 }}
          onClick={() => {
            onEventTemplateChange((prevEventTemplate) => {
              const updatedSurveyQuestions = [
                ...surveyQuestions,
                {
                  id: 'q_'+randStr(5),
                  question: '',
                  type: QuestionType.FREEFORM,
                  choices: [],
                },
              ];

              prevEventTemplate.payload.hostedEventPayload.surveyQuestions = updatedSurveyQuestions;
            });
          }}
        >
          Add Question
        </Button>
      )}

      {!readOnlyMode && (
        <ArchivalToggle
          archived={archived}
          onArchivedChange={(archived: boolean) => {
            onEventTemplateChange((prevEventTemplate) => {
              prevEventTemplate.archived = archived;
            });
          }}
        />
      )}
    </Box>
  );
};

export default Editor;
