import React from 'react';
import {
  Box, TextField,
} from '@mui/material';

import { Vendor } from 'common/src/models/event';
import { ResourceRef } from 'common/src/models/shared';
import { vendorDescriptionSchema, vendorNameSchema,
} from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';
import { ImageEditor } from '../../../../components/base';

interface IProps {
  vendor: Vendor;
  showValidationErr: boolean;
  onVendorChange: (vendor: Vendor) => void;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const Editor: React.FC<IProps> = ({
  vendor, showValidationErr, onVendorChange, onUploadImg,
}) => {
  const {
    id, name, description, refMedia,
  } = vendor;
  const handleVendorChange = (mutationFn: ((prevVendor: Vendor) => void)) => {
    const clonedVendor = JSON.parse(JSON.stringify(vendor));
    mutationFn(clonedVendor);
    onVendorChange(clonedVendor);
  };

  return (
    <Box>
      <Text size='title'>Edit Vendor</Text>
      <Text variant='italics' color='system' mb={16}>Please confirm the vendor content</Text>
      <Box display='flex'>
        <Text size='paragraph' mr={8} alignSelf='flex-end' mb={3}>ID:</Text>
        <Text size='title'>{id}</Text>
      </Box>
      <Box display='flex'>
        <Box sx={{ my: 16, mr: 80 }}>
          <UserInput validationFn={showValidationErr ? () => vendorNameSchema.validate(name) : null}>
            <TextField
              variant='standard'
              required
              label='商家名称'
              placeholder='请输入商家名称'
              value={name}
              onChange={(event) => {
                handleVendorChange((prevVendor) => {
                  prevVendor.name = event.target.value;
                });
              }}
              sx={{ width: 300 }}
            />
          </UserInput>
        </Box>
      </Box>

      <Box sx={{ my: 16 }}>
        <ImageEditor
          imgUrls={refMedia.downloadUrl ? [refMedia.downloadUrl] : []}
          multipleImgs={false}
          required
          readOnlyMode={false}
          showValidationErr={showValidationErr }
          onUploadImgs={async (files: File[]) => {
            if (files.length > 0) {
              const ref = await onUploadImg(files[0]);
              handleVendorChange((prevVendor) => {
                prevVendor.refMedia = ref;
              });
            }
          }}
          onRemoveImg={()=>{
            handleVendorChange((prevVendor) => {
              prevVendor.refMedia = { id: '', downloadUrl: '' };
            });
          }}
        />
      </Box>

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr ? () => vendorDescriptionSchema.validate(description): null}>
          <TextField
            required
            multiline
            fullWidth
            label='商家介绍'
            placeholder='请输入商家介绍'
            value={description}
            onChange={(event) => {
              handleVendorChange((prevVendor) => {
                prevVendor.description = event.target.value;
              });
            }}
          />
        </UserInput>
      </Box>
    </Box>
  );
};

export default Editor;
