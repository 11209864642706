import React from 'react';
import {
  Cancel as CancelIcon, Image as ImageIcon,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Stack } from '@mui/material';

import { Colors } from 'common/src/constants/styles';
import { eventMediaSchema } from '../../utils/validation';

import { UserInput } from 'common/src/components/base';

interface IProps {
  imgUrls: string[];
  multipleImgs: boolean;
  required: boolean;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  onUploadImgs: (files: File[]) => Promise<void>;
  onRemoveImg: (idx: number) => void;
}

const ImageEditor: React.FC<IProps> = ({
  imgUrls, multipleImgs, required, readOnlyMode, showValidationErr, onUploadImgs, onRemoveImg,
}) => {
  const [uploadingImg, setUploadingImg] = React.useState(false);

  return (
    <Box>
      <UserInput validationFn={required && showValidationErr ? () => eventMediaSchema.validate(imgUrls.length > 0 ? imgUrls[0] : '') : null}>
        <Stack direction='row'>
          {
            imgUrls.map((imgUrl, idx) => (
              <Box key={imgUrl} sx={{ position: 'relative', border: 1, borderColor: Colors.GREY500, boxShadow: 1, width: 200 }}>
                <img src={imgUrl} width='100%' />
                {
                  !readOnlyMode && (
                    <IconButton
                      onClick={() => onRemoveImg(idx)}
                      sx={{ position: 'absolute', right: 0, top: 0 }}
                    >
                      <CancelIcon sx={{ bgcolor: Colors.WHITE, borderRadius: 50 }} />
                    </IconButton>
                  )
                }
              </Box>
            ))
          }
        </Stack>
        {
          ((multipleImgs || (!multipleImgs && imgUrls.length === 0)) && !readOnlyMode) && (
            <Button
              variant='contained'
              component='label'
              startIcon={<ImageIcon />}
              endIcon={uploadingImg && <CircularProgress size={25} sx={{ color: Colors.GREY100 }} />}
            >
              Upload Image
              <input
                hidden accept='image/*' multiple={multipleImgs} type='file'
                onChange={async (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setUploadingImg(true);
                    await onUploadImgs(Array.from(e.target.files || []));
                    setUploadingImg(false);
                  }
                }}
              />
            </Button>
          )
        }
      </UserInput>
    </Box>
  );
};

export default ImageEditor;
