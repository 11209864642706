import React from 'react';
import {
  ArrowDownwardOutlined as ArrowDownwardOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import {
  Box, Checkbox, FormControl, IconButton, InputAdornment, InputLabel, MenuItem,
  Select, SelectChangeEvent,
  Stack, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs, unix } from 'dayjs';

import { Colors } from 'common/src/constants';
import { TicketOption } from 'common/src/models/event';
import { TicketOptionVisibilitySetting } from 'common/src/models/event/ticket';
import { snakeToTitleCase } from '../../../../utils/string';
import { eventCostInCentsSchema, maxTotalParticipantsSchema, singlesEventMaxQuantityPerUserSchema } from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';

interface IProps {
  ticketOptions: TicketOption[];
  isSinglesEvent: boolean;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  eventRegistrationFromTs: number;
  eventRegistrationToTs: number;
  onChangeTicketOptions: (newTicketOptions: TicketOption[]) => void;
}

const TicketEditor: React.FC<IProps> = ({
  ticketOptions, isSinglesEvent, readOnlyMode, showValidationErr, eventRegistrationFromTs,
  eventRegistrationToTs, onChangeTicketOptions,
}) => {
  return (
    <Box>
      {ticketOptions.map((ticketOption, ticketOptionIdx)=>{
        const { id: ticketOptionId } = ticketOption;
        const canMoveDown = ticketOptionIdx < ticketOptions.length - 1;

        return (
          <Box
            key={ticketOptionId}
            display='flex' alignItems='center'
            px={20}
          >
            <Text size='paragraph' color='system-light' width={50}>{'门票' + (ticketOptionIdx+1)}</Text>
            <TicketOptionEditor
              ticketOption={ticketOption}
              isSinglesEvent={isSinglesEvent}
              readOnlyMode={readOnlyMode}
              showValidationErr={showValidationErr}
              eventRegistrationFromTs={eventRegistrationFromTs}
              eventRegistrationToTs={eventRegistrationToTs}
              onChangeTicketOption={
                (newTicketOption) => {
                  const updatedTicketOptions = [...ticketOptions];
                  updatedTicketOptions.splice(ticketOptionIdx, 1, newTicketOption);
                  onChangeTicketOptions(updatedTicketOptions);
                }
              }
            />
            {!readOnlyMode && (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <IconButton
                  onClick={()=>{
                    const updatedTicketOptions = [...ticketOptions];
                    updatedTicketOptions.splice(ticketOptionIdx, 1);
                    onChangeTicketOptions(updatedTicketOptions);
                  }}
                  disabled={ticketOptions.length === 1}
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={()=>{
                    const updatedTicketOptions = [...ticketOptions];
                    const tmp = updatedTicketOptions[ticketOptionIdx];
                    updatedTicketOptions[ticketOptionIdx] = updatedTicketOptions[ticketOptionIdx+1];
                    updatedTicketOptions[ticketOptionIdx+1] = tmp;

                    onChangeTicketOptions(updatedTicketOptions);
                  }}
                  sx={{ opacity: canMoveDown ? 0.8 : 0 }}
                >
                  <ArrowDownwardOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

interface ITicketOptionEditorProps {
  ticketOption: TicketOption;
  isSinglesEvent: boolean;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  eventRegistrationFromTs: number;
  eventRegistrationToTs: number;
  onChangeTicketOption: (newTicketOption: TicketOption) => void;
}

const TicketOptionEditor: React.FC<ITicketOptionEditorProps> = ({
  ticketOption, isSinglesEvent, readOnlyMode, showValidationErr,
  eventRegistrationFromTs, eventRegistrationToTs, onChangeTicketOption,
}) => {
  const {
    name, description, maxMaleParticipants, maxFemaleParticipants, maxTotalParticipants,
    maleCostInCents, femaleCostInCents, genericCostInCents, maxQuantityPerUser, allowWaitlist,
    registrationFromTs, registrationToTs, visibilitySetting,
  } = ticketOption;

  const [inputMaleCost, setInputMaleCost] = React.useState(
    maleCostInCents % 100 === 0 ?
      (maleCostInCents / 100).toFixed(0) :
      (maleCostInCents / 100).toFixed(2),
  );
  const [inputFemaleCost, setInputFemaleCost] = React.useState(
    femaleCostInCents % 100 === 0 ?
      (femaleCostInCents / 100).toFixed(0) :
      (femaleCostInCents / 100).toFixed(2),
  );
  const [inputGenericCost, setInputGenericCost] = React.useState(
    genericCostInCents % 100 === 0 ?
      (genericCostInCents / 100).toFixed(0) :
      (genericCostInCents / 100).toFixed(2),
  );

  return (
    <Box m={16} p={16} width='100%' border={1} borderRadius={5} borderColor={Colors.TEXT_SYSTEM_LIGHT}>
      <Box>
        <Box display='flex' alignItems='center'>
          <Text size='paragraph' color='system' width={80}>门票</Text>
          <Box>
            <Box>
              <TextField
                variant='standard'
                required
                label='名字'
                placeholder='请输入门票名字'
                value={name}
                onChange={(event) => {
                  onChangeTicketOption({
                    ...ticketOption,
                    name: event.target.value,
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ mr: 32, width: 365 }}
              />
            </Box>
            <Box mt={16}>
              <TextField
                variant='standard'
                required
                label='介绍'
                placeholder='请输入门票介绍'
                value={description}
                onChange={(event) => {
                  onChangeTicketOption({
                    ...ticketOption,
                    description: event.target.value,
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ mr: 32, width: 365 }}
              />
            </Box>
          </Box>
        </Box>

        <Box mt={16}>
          <Box display='flex' alignItems='flex-start'>
            <Text size='paragraph' color='system' width={80} pt={12}>活动人数</Text>
            {isSinglesEvent && (
              <>
                <TextField
                  variant='standard'
                  required
                  label='男'
                  hidden={!isSinglesEvent}
                  placeholder='请输入最多男性报名人数'
                  value={maxMaleParticipants}
                  onChange={(event) => {
                    onChangeTicketOption({
                      ...ticketOption,
                      maxMaleParticipants: +event.target.value,
                    });
                  }}
                  inputProps={{ readOnly: readOnlyMode }}
                  sx={{ mr: 32, width: 100 }}
                />
                <TextField
                  variant='standard'
                  required
                  label='女'
                  hidden={!isSinglesEvent}
                  placeholder='请输入最多女性报名人数'
                  value={maxFemaleParticipants}
                  onChange={(event) => {
                    onChangeTicketOption({
                      ...ticketOption,
                      maxFemaleParticipants: +event.target.value,
                    });
                  }}
                  inputProps={{ readOnly: readOnlyMode }}
                  sx={{ mr: 32, width: 100 }}
                />
              </>
            )}

            <UserInput validationFn={showValidationErr ?
              () => maxTotalParticipantsSchema.validate(maxTotalParticipants) : null}
            >
              <TextField
                variant='standard'
                required
                label='总共'
                placeholder='请输入最多总共报名人数'
                value={maxTotalParticipants}
                onChange={(event) => {
                  onChangeTicketOption({
                    ...ticketOption,
                    maxGenericParticipants: isSinglesEvent ? 0 : +event.target.value,
                    maxTotalParticipants: +event.target.value,
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ width: 100 }}
              />
            </UserInput>
          </Box>
        </Box>

        <Box display='flex' alignItems='flex-start' mt={16}>
          <Text size='paragraph' color='system' width={80} pt={12}>活动价格</Text>
          {isSinglesEvent ? (
            <>
              <UserInput validationFn={showValidationErr ? () => eventCostInCentsSchema.validate(maleCostInCents) : null}>
                <TextField
                  variant='standard'
                  required
                  label='男'
                  placeholder='请输入男性报名者活动花费'
                  InputProps={{
                    readOnly: readOnlyMode,
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  value={inputMaleCost}
                  onChange={(event) => {
                    setInputMaleCost(event.target.value.replace(/[^\d.]/g, ''));
                  }}
                  onBlur={() => {
                    const roundedMaleCostInCents = Math.round(+inputMaleCost*100);
                    if (roundedMaleCostInCents % 100 === 0) {
                      setInputMaleCost((roundedMaleCostInCents/100).toFixed(0));
                    } else {
                      setInputMaleCost((roundedMaleCostInCents/100).toFixed(2));
                    }
                    onChangeTicketOption({
                      ...ticketOption,
                      maleCostInCents: roundedMaleCostInCents,
                    });
                  }}
                  sx={{ mr: 32, width: 100 }}
                />
              </UserInput>
              <UserInput validationFn={showValidationErr ? () => eventCostInCentsSchema.validate(femaleCostInCents) : null}>
                <TextField
                  variant='standard'
                  required
                  label='女'
                  placeholder='请输入女性报名者活动花费'
                  InputProps={{
                    readOnly: readOnlyMode,
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  value={inputFemaleCost}
                  onChange={(event) => {
                    setInputFemaleCost(event.target.value.replace(/[^\d.]/g, ''));
                  }}
                  onBlur={() => {
                    const roundedFemaleCostInCents = Math.round(+inputFemaleCost*100);
                    if (roundedFemaleCostInCents % 100 === 0) {
                      setInputFemaleCost((roundedFemaleCostInCents/100).toFixed(0));
                    } else {
                      setInputFemaleCost((roundedFemaleCostInCents/100).toFixed(2));
                    }
                    onChangeTicketOption({
                      ...ticketOption,
                      femaleCostInCents: roundedFemaleCostInCents,
                    });
                  }}
                  sx={{ width: 100 }}
                />
              </UserInput>
            </>
          ) : (
            <UserInput validationFn={showValidationErr ? () => eventCostInCentsSchema.validate(genericCostInCents) : null}>
              <TextField
                variant='standard'
                required
                label='价格'
                placeholder='请输入报名者活动花费'
                InputProps={{
                  readOnly: readOnlyMode,
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                value={inputGenericCost}
                onChange={(event) => {
                  setInputGenericCost(event.target.value.replace(/[^\d.]/g, ''));
                }}
                onBlur={() => {
                  const roundedGenericCostInCents = Math.round(+inputGenericCost*100);
                  if (roundedGenericCostInCents % 100 === 0) {
                    setInputGenericCost((roundedGenericCostInCents/100).toFixed(0));
                  } else {
                    setInputGenericCost((roundedGenericCostInCents/100).toFixed(2));
                  }
                  onChangeTicketOption({
                    ...ticketOption,
                    genericCostInCents: roundedGenericCostInCents,
                  });
                }}
                sx={{ width: 100 }}
              />
            </UserInput>
          )}
        </Box>

        <Box display='flex' alignItems='center' mt={16}>
          <Text size='paragraph' color='system' width={80}>其他</Text>
          <Box>
            <Box display='flex' alignItems='flex-start'>
              <UserInput validationFn={showValidationErr && isSinglesEvent ?
                () => singlesEventMaxQuantityPerUserSchema.validate(maxQuantityPerUser) : null}
              >
                <TextField
                  variant='standard'
                  required
                  disabled={isSinglesEvent}
                  label='用户可购买数量（每订单）'
                  placeholder='请输入用户可购买数量'
                  value={maxQuantityPerUser}
                  onChange={(event) => {
                    onChangeTicketOption({
                      ...ticketOption,
                      maxQuantityPerUser: +event.target.value,
                    });
                  }}
                  InputProps={{ readOnly: readOnlyMode }}
                  sx={{ mr: 16, width: 160 }}
                />
              </UserInput>

              <Stack direction='row' alignItems='center' alignSelf='flex-end' mr={16}>
                <Checkbox
                  checked={allowWaitlist}
                  onChange={(event) => {
                    if (readOnlyMode) {
                      return;
                    }
                    onChangeTicketOption({
                      ...ticketOption,
                      allowWaitlist: event.target.checked,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Text>允许Waitlist</Text>
              </Stack>

              <Stack direction='row' alignItems='center' alignSelf='flex-end'>
                <Checkbox
                  checked={registrationFromTs > 0 || registrationToTs > 0}
                  onChange={(event) => {
                    if (readOnlyMode) {
                      return;
                    }

                    if (event.target.checked) {
                      onChangeTicketOption({
                        ...ticketOption,
                        registrationFromTs: eventRegistrationFromTs,
                        registrationToTs: eventRegistrationToTs,
                      });
                    } else {
                      onChangeTicketOption({
                        ...ticketOption,
                        registrationFromTs: 0,
                        registrationToTs: 0,
                      });
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Text>设置有效期</Text>
              </Stack>
            </Box>

            {(registrationFromTs > 0 || registrationToTs > 0) && (
              <Box mt={20} display='flex' alignItems='center'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label='From'
                    value={unix(registrationFromTs / 1000)}
                    onChange={(newValue: Dayjs | null)=>{
                      if (newValue) {
                        onChangeTicketOption({
                          ...ticketOption,
                          registrationFromTs: newValue.valueOf(),
                        });
                      }
                    }}
                    readOnly={readOnlyMode}
                  />
                </LocalizationProvider>
                <ArrowForwardIcon sx={{ mx: 16 }} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label='To'
                    value={unix(registrationToTs / 1000)}
                    onChange={(newValue: Dayjs | null)=>{
                      if (newValue) {
                        onChangeTicketOption({
                          ...ticketOption,
                          registrationToTs: newValue.valueOf(),
                        });
                      }
                    }}
                    readOnly={readOnlyMode}
                  />
                </LocalizationProvider>
              </Box>
            )}
            <FormControl variant='standard' margin='normal' sx={{ width: 200, mr: 50 }}>
              <InputLabel>显示设置</InputLabel>
              <Select
                value={visibilitySetting}
                onChange={(event: SelectChangeEvent)=>{
                  onChangeTicketOption({
                    ...ticketOption,
                    visibilitySetting: event.target.value as TicketOptionVisibilitySetting,
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
              > {
                  Object.values(TicketOptionVisibilitySetting).map((value) => (
                    <MenuItem key={value} value={value}>{snakeToTitleCase(value)}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box display='flex' justifyContent='flex-end' mb={-10}>
        <Text color='system' size='subnote'>id: {ticketOption.id}</Text>
      </Box>
    </Box>
  );
};

export default TicketEditor;
