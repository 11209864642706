import React from 'react';
import { Box } from '@mui/material';

import { TicketOrder, TicketOrderVirtualStatus } from 'common/src/models/event';
import { User } from 'common/src/models/user';

import { Text } from 'common/src/components/base';

interface IProps {
  detailedTicketOrders: {
    user: User | null,
    ticketOrder: TicketOrder,
    virtualStatus: TicketOrderVirtualStatus,
  }[];
  rowLabel: string;
  aggregationFn: (user: User, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus) => { key: string, count: number }[];
}

const StatusRow: React.FC<IProps> = ({
  detailedTicketOrders, rowLabel, aggregationFn,
}) => {
  const allKeys= detailedTicketOrders.reduce((prevVal, detailedTicketOrder) => {
    if (!detailedTicketOrder.user) {
      return prevVal;
    }
    aggregationFn(detailedTicketOrder.user, detailedTicketOrder.ticketOrder, detailedTicketOrder.virtualStatus).forEach((entry) => {
      if (prevVal.findIndex((elem) => elem.key === entry.key) === -1) {
        prevVal.push({ key: entry.key, count: 0 });
      }
    });
    return prevVal;
  }, [] as {key: string, count: number}[]);

  const key2Count = detailedTicketOrders
    .reduce((prevVal, detailedTicketOrder) => {
      if (!detailedTicketOrder.user) {
        return prevVal;
      }
      aggregationFn(detailedTicketOrder.user, detailedTicketOrder.ticketOrder, detailedTicketOrder.virtualStatus).forEach((aggEntry) => {
        const entry = prevVal.find((elem) => elem.key === aggEntry.key)!;
        entry.count += aggEntry.count;
      });

      return prevVal;
    }, allKeys);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
      <Text size='note' variant='bold' mr={10}>{rowLabel}:</Text>
      {
        [...key2Count].map((entry, idx) => {
          const padding = '00'.slice(entry.count.toString().length);
          return (
            <Box
              key={idx}
              sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', ml: 16 }}
            >
              <Text size='note' mr={10}>{entry.key}:</Text>
              <Text size='note' fontFamily='monospace' sx={{ opacity: 0 }}>{padding}</Text>
              <Text size='note' fontFamily='monospace'>{entry.count}</Text>
            </Box>
          );
        })
      }
    </Box>
  );
};

export default StatusRow;
