import React from 'react';
import { PhotoSlider } from 'react-photo-view';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import { Box, IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';

import { Vendor } from 'common/src/models/event';
import { getHumanReadableDateTime } from 'common/src/utils/time';

import { AgGridTableTooltip } from '../../../components/base';

interface IProps {
  vendors: Vendor[] | null;
  onEdit: (vendorId: string) => void;
}
const VendorViewer: React.FC<IProps> = ({ vendors, onEdit }) => {
  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
    filter: true,
    tooltipComponent: AgGridTableTooltip,
  }), []);

  const columnDefs = [
    { field: 'id' },
    { field: 'name' },
    { field: 'description', width: 400 },
    { field: 'createdTs', headerName: 'Created Time' },
  ];
  let effectiveColumns = columnDefs.map((col) => {
    const effectiveColumn = {
      ...col,
      tooltipField: col.field,
    };
    return effectiveColumn;
  }) as any[];
  effectiveColumns = [
    // Add a fake column on left for drag operations
    { field: '', rowDrag: true, editable: false, lockPosition: 'left', width: 130,
      cellRenderer: FirstCellRenderer,
      cellRendererParams: {
        onEdit: onEdit,
        onViewImage: (vendorId: string) => {
          if (!vendors) {
            return;
          }
          const mactchingVendor = vendors.find((vendor) => vendor.id === vendorId);
          if (!mactchingVendor) {
            return;
          }
          setShownRowDetails(mactchingVendor);
        },
      },
    },
    ...effectiveColumns,
  ];
  effectiveColumns[1].lockPosition = 'left';

  const agGridRef = React.useRef<AgGridReactType>(null);

  const [shownRowDetails, setShownRowDetails] = React.useState<Vendor | null>(null);
  const rowDetailsModal = !!shownRowDetails && (
    <PhotoSlider
      images={[shownRowDetails.refMedia].map((img)=>
        ({ src: img.downloadUrl, key: img.id }))}
      visible
      onClose={() => setShownRowDetails(null)}
    />
  );

  const vendorData = React.useMemo(() => {
    if (vendors === null) {
      return null;
    }

    return vendors.map((vendor) => {
      return {
        id: vendor.id,
        name: vendor.name,
        description: vendor.description,
        createTs: getHumanReadableDateTime(vendor.createdTs, 'local'),
      };
    });
  }, [vendors]);

  if (vendorData === null) {
    return null;
  }

  return (
    <Box>
      <Box className='ag-theme-alpine' style={{ height: 500 }}>
        <AgGridReact
          ref={agGridRef}
          rowData={vendorData}
          columnDefs={effectiveColumns}
          defaultColDef={defaultColDef}
          animateRows
          rowDragManaged
          undoRedoCellEditing
          onCellEditingStopped={() => {
            try {
              // automatically reset cell content, so it doesn't change
              agGridRef.current?.api.undoCellEditing();
            } catch (e) {
              // do nothing
            }
          }}
        />
      </Box>
      {rowDetailsModal}
    </Box>
  );
};

const FirstCellRenderer = (props: any) => {
  return (
    <Box>
      <IconButton onClick={() => props.onEdit(props.data.id)}>
        <EditIcon sx={{ fontSize: 18 }}></EditIcon>
      </IconButton>
      <IconButton onClick={() => props.onViewImage(props.data.id)}>
        <ImageIcon sx={{ fontSize: 18 }}></ImageIcon>
      </IconButton>
    </Box>
  );
};

export default VendorViewer;
