import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LibraryAdd as LibraryAddIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import useAppDispatch from '../../../../hooks/useAppDispatch';
import { saveEventTemplateGroup } from '../../../../redux/slices/event';

import { Page, Text } from 'common/src/components/base';
import { EventTemplateGroupSelector } from '../../../../components/event';
import NewEventModal from './NewGroupModal';

export default function EventTemplateGroupSelectorScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSelectEventTemplateGroup = React.useCallback((eventTemplateGroupId: string) => {
    navigate(`/editEventTemplateGroup/${eventTemplateGroupId}`);
  }, [navigate]);

  const [newGroupModalOpen, setNewGroupModalOpen] = React.useState(false);

  return (
    <Page sx={{ mt: 16 }}>
      <Box sx={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', display: 'grid' }}>
        <Text size='banner' mb={35}>Create or Edit an Event Template Group</Text>

        <Button
          sx={{ py: 10, mb: 30, width: 260 }}
          variant='contained'
          onClick={() => setNewGroupModalOpen(true)}
        >
          <LibraryAddIcon sx={{ mr: 10 }}/>
          Create New Group
        </Button>

        <Text size='title' color='system-light'>- or select a group below to edit -</Text>

        <EventTemplateGroupSelector onSelectEventTemplateGroup={handleSelectEventTemplateGroup} />
      </Box>
      <NewEventModal
        isOpen={newGroupModalOpen}
        onClose={() => setNewGroupModalOpen(false)}
        onConfirm={async (id, name) => {
          // Save new group and then navigate there
          await dispatch(saveEventTemplateGroup({
            id: id,
            published: false,
            archived: false,
            visible: true,

            name: name,
            intro: '',
            description: '',
            media: { id: '', downloadUrl: '' },
            thumbnailMedia: { id: '', downloadUrl: '' },
            extraMedia: [],
            extraThumbnailMedia: [],
            groupedEventTemplates: [],
            showGroupOnly: false,
            detailsUrl: '',
            visibilitySetting: { visibleInApp: true, visibleOnWeb: true },
            createdTs: 0,
          }));

          navigate(`/editEventTemplateGroup/${id}`);
        }}
      />
    </Page>
  );
}
