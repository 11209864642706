import React from 'react';
import {
  EventBusyOutlined as EventBusyOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, InputAdornment, Modal, Stack, TextField } from '@mui/material';

import { EventTemplate } from 'common/src/models/event';
import { PaymentAuthorityRecord } from 'common/src/models/event/payment';
import { AdjustTicketOrderAction } from 'common/src/models/event/ticket';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { adjustTicketOrder } from '../../../../../redux/slices/event';

import { Text, UserInput } from 'common/src/components/base';

interface IProps {
  eventTemplate: EventTemplate;
  selectedPaymentAuthorityRecords: PaymentAuthorityRecord[];
  onClose: () => void;
}

const RefundModal: React.FC<IProps> = ({ selectedPaymentAuthorityRecords, onClose }) => {
  const dispatch = useAppDispatch();

  const [submittingAction, setSubmittingAction] = React.useState(false);
  const [partialRefundInCents, setPartialRefundInCents] = React.useState(0);
  const [isPartialRefund, setIsPartialRefund] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState('');

  const handleRefund = React.useCallback(async (refundInCents?: number) => {
    setSubmittingAction(true);
    for (const paymentAuthorityRecord of selectedPaymentAuthorityRecords) {
      await dispatch(adjustTicketOrder({
        ticketOrderId: paymentAuthorityRecord.ticketOrderId,
        actionType: AdjustTicketOrderAction.REFUND,
        refundInCents: refundInCents,
      }));
    }
    setSubmittingAction(false);
    onClose();
  }, [dispatch, onClose, selectedPaymentAuthorityRecords]);

  const { isRefundAllowed, totalRefundInCents } = React.useMemo(() => {
    let isRefundAllowed_ = true;
    for (const paymentAuthorityRecord of selectedPaymentAuthorityRecords) {
      if (paymentAuthorityRecord.capturedAmountInCents === 0) {
        // Cannot refund uncaptured payment
        console.log('Cannot refund uncaptured payment');
        isRefundAllowed_ = false;
      }
      if (paymentAuthorityRecord.refundInCents > 0) {
        // Cannot refund more than once
        console.log('Cannot refund more than once');
        isRefundAllowed_ = false;
      }
    }

    let totalRefundInCents_ = 0;
    if (isPartialRefund) {
      for (const paymentAuthorityRecord of selectedPaymentAuthorityRecords) {
        if (paymentAuthorityRecord.intendedAmountInCents < partialRefundInCents) {
          // Cannot refund more than charged
          console.log('Cannot refund more than charged');
          isRefundAllowed_ = false;
        }
        totalRefundInCents_ += partialRefundInCents;
      }
    } else {
      for (const paymentAuthorityRecord of selectedPaymentAuthorityRecords) {
        if (paymentAuthorityRecord.intendedAmountInCents === 0) {
          // Cannot refund free events
          console.log('Cannot refund free events');
          isRefundAllowed_ = false;
        }
        totalRefundInCents_ += paymentAuthorityRecord.intendedAmountInCents;
      }
    }
    if (totalRefundInCents_ === 0) {
      isRefundAllowed_ = false;
    }

    return {
      isRefundAllowed: isRefundAllowed_,
      totalRefundInCents: totalRefundInCents_,
    };
  }, [isPartialRefund, partialRefundInCents, selectedPaymentAuthorityRecords]);

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Refund</Text>
        {submittingAction ? (
          <Box sx={{ display: 'flex', mt: 16 }}>
            <CircularProgress size={20} sx={{ mr: 8 }} />
            Submitting Action
          </Box>
        ) : (
          <Box>
            <Text variant='italics' size='paragraph' color='system' my={5}>This operation will refund the cost without cancelling the order.</Text>
            <Box border={isPartialRefund ? 1 : 0} borderRadius={2} mb={20}>
              <Button size='small' onClick={() => setIsPartialRefund((val) => !val)}>
                <Checkbox size='small' sx={{ pr: 5, mb: 3 }} checked={isPartialRefund} />Partial Refund
              </Button>

              {isPartialRefund && (
                <Box ml={20} mb={10}>
                  <Text size='paragraph' color='primary'>Refund Amount (per order)</Text>
                  <UserInput>
                    <TextField
                      variant='standard'
                      required
                      InputProps={{
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      }}
                      value={(partialRefundInCents / 100).toFixed(2)}
                      onChange={(event) => {
                        setPartialRefundInCents(+event.target.value.replace(/[^\d]/g, ''));
                      }}
                      sx={{ mr: 32, width: 100 }}
                    />
                  </UserInput>
                </Box>
              )}
            </Box>

            <Text mb={16} px={20}>You are about to refund <b>${(totalRefundInCents/100).toFixed(2)}</b> across <b>{selectedPaymentAuthorityRecords.length}</b> order(s)</Text>

            <Text variant='italics' color='system' mb={16} px={20}>This operation is irreversible. Please confirm.</Text>

            <Box ml={20} mb={20}>
              <Text size='paragraph' color='primary'>Type <i>refund</i> to confirm</Text>
              <UserInput>
                <TextField
                  variant='standard'
                  required
                  value={confirmationText}
                  onChange={(event) => {
                    setConfirmationText(event.target.value);
                  }}
                  autoComplete='off'
                  sx={{ mr: 32, width: 100 }}
                />
              </UserInput>
            </Box>

            <Stack direction='row' justifyContent='center'>
              <Button
                startIcon={<EventBusyOutlinedIcon />}
                variant='contained'
                onClick={() => onClose()}
              >
                Do not Refund
              </Button>

              <Box width={15} />
              <Button
                startIcon={<EventBusyOutlinedIcon />}
                variant='outlined'
                disabled={!isRefundAllowed || confirmationText !== 'refund'}
                onClick={() => handleRefund(/* refundInCents*/ isPartialRefund ? partialRefundInCents : undefined)}
              >
                Refund
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default RefundModal;
