import * as React from 'react';
import { Box } from '@mui/material';

import Logger from '../utils/logger';

import LogoSvg from '../assets/svgs/logoSvg';
import { Text } from './base';

const LOGGER = Logger.getInstance();

// This creates a splash screen for the webapp while it is not ready.
export interface IProps {
  children: React.ReactNode;
  showApp: boolean;
}
const AppLoader: React.FC<IProps> = ({ children, showApp }) => {
  React.useEffect(() => {
    if (!showApp) {
      LOGGER.info('Loading app');
    }
  }, [showApp]);

  return (
    <Box>
      {showApp && children}
      {!showApp && (
        <Box textAlign='center' marginTop={80}>
          <Box sx={{
            'animation': 'spin 2s linear infinite',
            '@keyframes spin': {
              '0%': {
                transform: 'rotateY(360deg)',
              },
              '100%': {
                transform: 'rotateY(0deg)',
              },
            },
          }}>
            <LogoSvg size={100} />
          </Box>
          <Text size='title' color='primary' mt={8}>Loading...</Text>
        </Box>
      )}
    </Box>
  );
};


export default AppLoader;
