import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HomeOutlined as HomeOutlinedIcon, LogoutOutlined as LogoutOutlinedIcon,
} from '@mui/icons-material';

import { AuthContext } from 'common/src/context/auth';
import { centralScreens } from '../../navigation';

import { Page } from 'common/src/components/base';
import NavigationMenuBase from './NavigationMenuBase';

export const MainLayout = () => {
  const navigate = useNavigate();
  const { userRole, logout } = React.useContext(AuthContext);

  const menuItems = [
    [
      { icon: <HomeOutlinedIcon />, path: '/', name: 'Home', actionFn: () => navigate('/') },
    ],
    centralScreens
      .filter((centralScreen) => centralScreen.allowedUserRoles.includes(userRole))
      .map((centralScreen) => (
        {
          icon: centralScreen.icon,
          path: centralScreen.path,
          name: centralScreen.name,
          actionFn: () => navigate(centralScreen.path),
        }
      )),
    [
      { icon: <LogoutOutlinedIcon />, name: 'Logout', actionFn: logout },
    ],
  ];
  return (
    <Page>
      <NavigationMenuBase menuItems={menuItems} />
    </Page>
  );
};
