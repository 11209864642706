import React from 'react';
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Box, CircularProgress, Container, IconButton, Modal,
} from '@mui/material';
import { ValidationError } from 'yup';

import { Colors } from 'common/src/constants';
import { TaroPassRecord } from 'common/src/models/event';
import { ResourceRef } from 'common/src/models/shared';
import { taroPassSchema } from '../../../../utils/validation';

import Editor from './Editor';

interface IProps {
  taroPass: TaroPassRecord;
  onClose: () => void;
  onSave: (taroPass: TaroPassRecord) => Promise<void>;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const TaroPassEditor: React.FC<IProps> = ({
  taroPass, onClose, onSave, onUploadImg,
}) => {
  // taroPass is the redux/server view, while draftTaroPass is WIP.
  const [draftTaroPass, setDraftTaroPass] = React.useState<TaroPassRecord>(
    JSON.parse(JSON.stringify(taroPass)),
  );

  const [hasPendingChanges, setHasPendingChanges] = React.useState(false);
  React.useEffect(() => {
    const draftTaroPassCopy = {
      ...draftTaroPass,
      refMedia: {
        id: draftTaroPass.refMedia.id,
        // Remove download url since that can change all the time
      },
    };
    const taroPassCopy = {
      ...taroPass,
      refMedia: {
        id: taroPass.refMedia.id,
        // Remove download url since that can change all the time
      },
    };

    setHasPendingChanges(JSON.stringify(draftTaroPassCopy) !== JSON.stringify(taroPassCopy));
  }, [taroPass, draftTaroPass]);

  const [showValidationErr, setShowValidationErr] = React.useState(false);

  const savePendingChanges = React.useCallback(async () => {
    // Clean up everything
    const cleanedDraftTaroPass: TaroPassRecord = JSON.parse(JSON.stringify(draftTaroPass));
    cleanedDraftTaroPass.name = cleanedDraftTaroPass.name.trim();
    cleanedDraftTaroPass.intro = cleanedDraftTaroPass.intro.trim();
    cleanedDraftTaroPass.detail = cleanedDraftTaroPass.detail.trim();
    if (JSON.stringify(draftTaroPass) !== JSON.stringify(cleanedDraftTaroPass)) {
      setDraftTaroPass(cleanedDraftTaroPass);
    }

    try {
      taroPassSchema.validateSync(cleanedDraftTaroPass);
    } catch (validationErr) {
      if (validationErr instanceof ValidationError && validationErr.errors.length) {
        console.log('validation error: ' + validationErr.errors[0]);
      } else {
        console.log('unknown validation error');
      }
      setShowValidationErr(true);
      return;
    }
    setShowValidationErr(false);

    // Do update
    setSavingTaroPass(true);
    await onSave(draftTaroPass);
    setSavingTaroPass(false);
  }, [draftTaroPass, onSave]);

  const [savingTaroPass, setSavingTaroPass] = React.useState(false);

  const saveDisabled = !hasPendingChanges;

  return (
    <Modal open>
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: '90%', height: '95%', bgcolor: 'background.default', boxShadow: 24,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: 'fixed', right: 10, top: 10 }}
        >
          <CloseIcon color='primary' />
        </IconButton>
        <Container sx={{ overflow: 'scroll', height: '100%' }}>
          <Editor
            taroPass={draftTaroPass}
            showValidationErr={showValidationErr}
            onTaroPassChange={setDraftTaroPass}
            onUploadImg={onUploadImg}
          />
        </Container>

        <IconButton
          onClick={savePendingChanges}
          sx={{ position: 'fixed', right: 30, bottom: 50 }}
          disabled={savingTaroPass || saveDisabled}
        >
          <Box
            sx={{
              width: 60, height: 60, p: 10, borderRadius: 60, boxShadow: 1,
              bgcolor: saveDisabled ? Colors.GREY100 : Colors.PRIMARY_BACKGROUND,
            }}
          >
            {
              !savingTaroPass ? (
                <SaveIcon
                  sx={{
                    width: 40, height: 40,
                    color: showValidationErr && !taroPassSchema.isValidSync(draftTaroPass) ?
                      Colors.ERROR :
                      saveDisabled ? Colors.DISABLED : Colors.PRIMARY,
                  }}
                />
              ) : (
                <CircularProgress size={40} />
              )
            }
          </Box>
        </IconButton>
      </Box>
    </Modal>
  );
};

export default TaroPassEditor;
