import React from 'react';
import { Button } from '@mui/material';

import { Vendor } from 'common/src/models/event';
import { uploadVendorImageRemote } from 'common/src/system/network/event';
import { readFile } from 'common/src/utils/file';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { useVendors } from '../../../hooks/useResource';
import { saveVendor } from '../../../redux/slices/event';

import { Page } from 'common/src/components/base';
import NewVendorModal from './NewVendorModal';
import VendorEditor from './VendorEditor';
import VendorViewer from './VendorViewer';

const VendorSelectorScreen = () => {
  const dispatch = useAppDispatch();

  const { data: vendors } = useVendors();
  const [selectedVendorId, setSelectedVendorId] = React.useState<string|null>(null);

  const selectedVendor = React.useMemo(() => {
    if (!selectedVendorId || !vendors) {
      return null;
    }
    const matchingVendor = vendors.find((vendor) => vendor.id === selectedVendorId);
    if (!matchingVendor) {
      throw new Error('BUG: no matching vendor found for ' + selectedVendorId);
    }
    return {
      ...matchingVendor,
    };
  }, [vendors, selectedVendorId]);

  const [newVendorModalOpen, setNewVendorModalOpen] = React.useState(false);

  const handleSaveVendor = React.useCallback(async (vendor: Vendor) => {
    await dispatch(saveVendor(vendor));
  }, [dispatch]);

  if (vendors === null) {
    return null;
  }

  return (
    <Page sx={{ mt: 16 }}>
      <VendorViewer
        vendors={vendors}
        onEdit={setSelectedVendorId}
      />
      <Button
        sx={{ my: 24 }}
        variant='contained'
        onClick={() => setNewVendorModalOpen(true)}
      >
        Create New Vendor
      </Button>
      { !!selectedVendor &&(
        <VendorEditor
          vendor={selectedVendor}
          onClose={() => setSelectedVendorId(null)}
          onSave = {handleSaveVendor}
          onUploadImg={async (f: File) => {
            if (!selectedVendorId) {
              throw new Error('BUG: adding vendor image for invalid id: ' + selectedVendorId);
            }
            const base64Content = await readFile(f);
            const fileType = f.type.replace('image/', '');
            return await uploadVendorImageRemote(selectedVendorId, base64Content, fileType);
          }}
        />
      )}
      <NewVendorModal
        isOpen = {newVendorModalOpen}
        onConfirm = { async ( id, name)=>{
          await dispatch(saveVendor({
            id: id,
            name: name,
            description: '',
            refMedia: { id: '', downloadUrl: '' },
            createdTs: Date.now(),
          }));
          setSelectedVendorId(id);
          setNewVendorModalOpen(false);
        }}
        onClose = {()=>setNewVendorModalOpen(false)}
      />
    </Page>
  );
};

export default VendorSelectorScreen;
