import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';
import QRCode from 'qrcode.react';

import { EventTemplate, resolveTicketOption, Ticket, TicketOrder } from 'common/src/models/event';
import { getHumanReadableDateTimeRange } from 'common/src/utils/time';
import { useEventTemplate, useTicketOrdersByEventTemplate } from '../../../hooks/useResource';

import { Page, Text } from 'common/src/components/base';

export default function FreeTicketViewerScreen() {
  const { eventTemplateId } = useParams();
  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for FreeTicketViewerScreen');
  }

  const eventTemplate = useEventTemplate(eventTemplateId);
  const eventTemplateName = eventTemplate ? eventTemplate.name : '';

  const ticketOrders = useTicketOrdersByEventTemplate(eventTemplateId);
  const filteredTicketOrders = React.useMemo(() => {
    if (!ticketOrders) {
      return null;
    }
    return ticketOrders.filter((ticketOrder) => ticketOrder.userId === '_ticketGenerator');
  }, [ticketOrders]);

  const agGridRef = React.useRef<AgGridReactType>(null);

  const [detailedTickets, setDetailedTickets] = React.useState<{
    ticket: Ticket,
    ticketOrder: TicketOrder,
    eventTemplate: EventTemplate,
  }[] | null>(null);

  React.useEffect(() => {
    if (filteredTicketOrders === null || !eventTemplate) {
      setDetailedTickets(null);
      return;
    }

    setDetailedTickets(filteredTicketOrders //
      .flatMap((ticketOrder) => {
        return ticketOrder.tickets.map((ticket) => ({
          ticket: ticket,
          ticketOrder: ticketOrder,
          eventTemplate: eventTemplate,
        }));
      }) //
      .sort((detailedTicket1, detailedTicket2) => {
        if (detailedTicket1.ticket.seatId && detailedTicket2.ticket.seatId) {
          return detailedTicket1.ticket.seatId.localeCompare(detailedTicket2.ticket.seatId);
        } else {
          return detailedTicket1.ticketOrder.id.localeCompare(detailedTicket2.ticketOrder.id);
        }
      }),
    );
  }, [eventTemplate, filteredTicketOrders]);


  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
    filter: true,
  }), []);

  const columns = React.useMemo(() => {
    return [
      { field: 'ticket',
        width: 250,
        autoHeight: true,
        cellRenderer: TicketRenderer,
      },
      { field: 'ticketOption', width: 180 },
      { field: 'seatId', width: 180 },
      { field: 'price', autoHeight: true, width: 180, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
    ];
  }, []);


  if (!detailedTickets || !eventTemplate) {
    return <Page><Text>Loading...</Text></Page>;
  }

  const userData = detailedTickets.map((detailedTicket) => {
    const ticketOption = resolveTicketOption(eventTemplate, detailedTicket.ticket.ticketOptionId);

    const cost = detailedTicket.ticketOrder.costDetails.ticketIdToCost[detailedTicket.ticket.id];
    let priceStr = '';
    priceStr += `Subtotal: $${(cost.subtotalInCents/100).toFixed(2)}\n`;
    cost.costAdjustors.forEach((costAdjustor) => {
      priceStr += `${costAdjustor.adjustorName}: $${(-costAdjustor.adjustmentInCents/100).toFixed(2)}\n`;
    });
    if (cost.taxInCents > 0) {
      priceStr += `Tax: $${(cost.taxInCents/100).toFixed(2)}\n`;
    }
    priceStr += `Fee: $${(cost.feeInCents/100).toFixed(2)}\n`;
    priceStr += `Total: $${(cost.finalCostInCents/100).toFixed(2)}\n`;

    return {
      detailedTicket: detailedTicket,
      ticketOption: ticketOption.name,
      seatId: detailedTicket.ticket.seatId,
      price: priceStr,
    };
  });

  return (
    <Page>
      <Container>
        <Text size='banner' ml={16} mt={16}>{eventTemplateName}</Text>
        <Box className='ag-theme-alpine' sx={{ mb: 10 }}>
          <AgGridReact
            ref={agGridRef}
            rowData={userData}
            columnDefs={columns} // Column Defs for Columns
            defaultColDef={defaultColDef}
            animateRows // Optional - set to 'true' to have rows animate when sorted
            suppressRowClickSelection
            domLayout='print'
          />
        </Box>
      </Container>
    </Page>
  );
}

const TicketRenderer = (props: any) => {
  const detailedTicket = props.data.detailedTicket;
  const ticketOrder:TicketOrder = detailedTicket.ticketOrder;
  const ticket:Ticket = detailedTicket.ticket;
  const eventTemplate:EventTemplate = detailedTicket.eventTemplate;
  return (
    <Stack p={20} alignItems='center'>
      <QRCode value={`${ticketOrder.id}-${ticket.id}`} size={100} />
      <Text size='paragraph' centered mt={15}>{eventTemplate.name}</Text>
      <Text size='note' centered mt={10}>
        {getHumanReadableDateTimeRange(eventTemplate.eventFromTs, eventTemplate.eventToTs, eventTemplate.eventTimeZone)}
      </Text>
    </Stack>
  );
};
