import React from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { ChevronLeft as ChevronLeftIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Box, Divider, Drawer as MuiDrawer,
  FormControl, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem,
  Select, SelectChangeEvent, Stack, Toolbar,
} from '@mui/material';
import { CSSObject, styled, Theme } from '@mui/material/styles';

import { Colors } from 'common/src/constants';
import { GeoStateCode, GeoStateName } from 'common/src/models/event';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { selectStateFilter, setStateFilter } from '../../redux/slices/setting';

import { Text } from 'common/src/components/base';

const drawerWidth = 240;
const allStateFilter = '_all';

interface CustomMenuItem { icon: JSX.Element, path?: string, name: string, actionFn: () => void }
interface IProps {
  menuItems: CustomMenuItem[][]
}

const NavigationMenuBase: React.FC<IProps> = ({ menuItems }) => {
  const outlet = useOutlet();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const stateFilter = useAppSelector(selectStateFilter);

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const screenName = getScreenName(location.pathname, menuItems);

  const menuView = menuItems.map((menuList, idx) => {
    return (
      <Box key={idx}>
        <Divider />
        <List>
          {
            menuList.map((menuItem) => {
              return (
                <ListItem key={menuItem.name} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: drawerOpen ? 'initial' : 'center',
                      px: 18,
                    }}
                    onClick={menuItem.actionFn}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 24 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {menuItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={menuItem.name} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              );
            })
          }
        </List>
      </Box>
    );
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed' open={drawerOpen} >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Toolbar style={{ paddingLeft: 24, border: 1, borderWidth: 1, borderColor: 'white' }}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => setDrawerOpen(true)}
              edge='start'
              sx={{
                mr: 40,
                ...(drawerOpen && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Text size='title' noWrap>{screenName}</Text>
          </Toolbar>
          <FormControl variant='standard' margin='normal' sx={{ width: 120, mr: 50, px: 10, bgcolor: Colors.PRIMARY_BACKGROUND }}>
            <Select
              value={stateFilter ? stateFilter.toString() : allStateFilter}
              onChange={(event: SelectChangeEvent)=>{
                if (!event.target.value) {
                  return;
                }
                dispatch(setStateFilter(event.target.value === allStateFilter ?
                  null : event.target.value as GeoStateCode));
              }}
            >
              <MenuItem value={allStateFilter}>所有地区</MenuItem>
              {Object.values(GeoStateCode).map((stateCode) => (
                <MenuItem key={stateCode} value={stateCode}>{GeoStateName[stateCode]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </AppBar>
      <Drawer variant='permanent' open={drawerOpen}>
        <DrawerHeader>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        {menuView}

      </Drawer>

      <Box component='main' sx={{ flexGrow: 1, p: 24 }}>
        <DrawerHeader />
        {outlet}
      </Box>
    </Box>
  );
};

const getScreenName = (pathname: string, menuItems: CustomMenuItem[][]) => {
  for (const menuList of menuItems) {
    for (const menuItem of menuList) {
      if (menuItem.path === pathname) {
        return menuItem.name;
      }
    }
  }
  return '';
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(56)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(64)} + 1px)`,
  },
});

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 8),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default NavigationMenuBase;
