import React from 'react';
import {
  Box, Button, FormControl, InputAdornment, MenuItem, Modal, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { ValidationError } from 'yup';

import { GeoStateCode, GeoStateName } from 'common/src/models/event';
import useAppSelector from '../../../hooks/useAppSelector';
import { selectStateFilter } from '../../../redux/slices/setting';
import {
  eventLocationStateSchema, taroPassIdSchema, taroPassNameSchema,
} from '../../../utils/validation';

import { Text } from 'common/src/components/base';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (state: string, id: string, name: string) => void;
}

const NewTaroPassModal: React.FC<IProps> = ({ isOpen, onClose, onConfirm }) => {
  const stateFilter = useAppSelector(selectStateFilter);

  const [taroPassState, setTaroPassState] = React.useState('');
  const [taroPassId, setTaroPassId] = React.useState('');
  const [taroPassName, setTaroPassName] = React.useState('');

  React.useEffect(() => {
    setTaroPassState(stateFilter || GeoStateCode.WA.toString());
  }, [stateFilter]);

  const effectiveTaroPassId = `${taroPassState}_${taroPassId}`;

  const [errMsg, setErrMsg] = React.useState('');
  const [showErrMsg, setShowErrMsg] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          eventLocationStateSchema.validate(taroPassState),
          taroPassIdSchema.validate(effectiveTaroPassId),
          taroPassNameSchema.validate(taroPassName),
        ]);

        setErrMsg('');
      } catch (e) {
        if (e instanceof ValidationError && e.errors.length) {
          setErrMsg(e.errors[0]);
        } else {
          setErrMsg('错误'); // This means there's a bug in the code.
        }
      }
    })();
  }, [taroPassState, effectiveTaroPassId, taroPassName]);

  const handleConfirm = () => {
    if (
      !eventLocationStateSchema.isValidSync(taroPassState) ||
      !taroPassIdSchema.isValidSync(effectiveTaroPassId) ||
      !taroPassNameSchema.isValidSync(taroPassName)
    ) {
      setShowErrMsg(true);
      return;
    }

    onConfirm(taroPassState, effectiveTaroPassId, taroPassName);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Create a new TaroPass record</Text>
        <Text variant='italics' color='system' mb={16}>Please confirm creation of a new TaroPass record</Text>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>活动地区</Text>
          <FormControl margin='normal' size='small' sx={{ width: 320 }}>
            <Select
              required
              value={taroPassState}
              onChange={(event: SelectChangeEvent)=>{
                if (!event.target.value) {
                  return;
                }
                setTaroPassState(event.target.value);
              }}
            >
              {Object.values(GeoStateCode).map((stateCode) => (
                <MenuItem key={stateCode} value={stateCode}>{GeoStateName[stateCode]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>TaroPass ID</Text>
          <TextField
            margin='normal'
            size='small'
            required
            autoFocus
            placeholder='tina_dance'
            value={taroPassId}
            onChange={(event) => setTaroPassId(event.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position='start'>{`${taroPassState}_`}</InputAdornment>,
            }}
            sx={{ width: 300 }}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>商家名称</Text>
          <TextField
            margin='normal'
            size='small'
            required
            placeholder="Tina's Dance Studio"
            value={taroPassName}
            onChange={(event) => setTaroPassName(event.target.value)}
            sx={{ width: 300 }}
          />
        </Box>

        <Button
          variant='contained'
          disabled={!taroPassId || !taroPassName || (showErrMsg && !!errMsg)}
          onClick={handleConfirm}
          sx={{ marginTop: 24 }}
        >
            Confirm
        </Button>
        {showErrMsg && <Text size='paragraph' color='error' mt={4}>{errMsg}</Text>}
      </Box>
    </Modal>
  );
};

export default NewTaroPassModal;
