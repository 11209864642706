import React from 'react';
import {
  Box, Button, FormControl, InputAdornment, MenuItem, Modal, Select, SelectChangeEvent, TextField,
} from '@mui/material';
import { ValidationError } from 'yup';

import { GeoStateCode, GeoStateName } from 'common/src/models/event';
import useAppSelector from '../../../../hooks/useAppSelector';
import { selectStateFilter } from '../../../../redux/slices/setting';
import {
  eventIdSchema, eventLocationStateSchema, eventNameSchema,
} from '../../../../utils/validation';

import { Text } from 'common/src/components/base';

const EVENT_ID_PREFIX = new Date().getFullYear() + '_';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (state: string, id: string, name: string) => void;
}

const NewEventModal: React.FC<IProps> = ({ isOpen, onClose, onConfirm }) => {
  const stateFilter = useAppSelector(selectStateFilter);

  const [eventState, setEventState] = React.useState('');
  const [eventId, setEventId] = React.useState('');
  const [eventName, setEventName] = React.useState('');

  React.useEffect(() => {
    setEventState(stateFilter || GeoStateCode.WA.toString());
  }, [stateFilter]);

  const effectiveEventId = `${EVENT_ID_PREFIX}${eventState}_${eventId}`;

  const [errMsg, setErrMsg] = React.useState('');
  const [showErrMsg, setShowErrMsg] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          eventLocationStateSchema.validate(eventState),
          eventIdSchema.validate(effectiveEventId),
          eventNameSchema.validate(eventName),
        ]);

        setErrMsg('');
      } catch (e) {
        if (e instanceof ValidationError && e.errors.length) {
          setErrMsg(e.errors[0]);
        } else {
          setErrMsg('错误'); // This means there's a bug in the code.
        }
      }
    })();
  }, [eventState, effectiveEventId, eventName]);

  const handleConfirm = () => {
    if (
      !eventLocationStateSchema.isValidSync(eventState) ||
      !eventIdSchema.isValidSync(effectiveEventId) ||
      !eventNameSchema.isValidSync(eventName)
    ) {
      setShowErrMsg(true);
      return;
    }
    onConfirm(eventState, effectiveEventId, eventName);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Create a new event</Text>
        <Text variant='italics' color='system' mb={16}>Please confirm creation of a new event</Text>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>活动地区</Text>
          <FormControl margin='normal' size='small' sx={{ width: 320 }}>
            <Select
              required
              value={eventState}
              onChange={(event: SelectChangeEvent)=>{
                if (!event.target.value) {
                  return;
                }
                setEventState(event.target.value);
              }}
            >
              {Object.values(GeoStateCode).map((stateCode) => (
                <MenuItem key={stateCode} value={stateCode}>{GeoStateName[stateCode]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>活动 ID</Text>
          <TextField
            margin='normal'
            size='small'
            required
            autoFocus
            placeholder='halloween_dance'
            value={eventId}
            onChange={(event) => setEventId(event.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position='start'>{`${EVENT_ID_PREFIX}${eventState}_`}</InputAdornment>,
            }}
            sx={{ width: 320 }}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>活动名</Text>
          <TextField
            margin='normal'
            size='small'
            required
            placeholder='TARO魔法酒吧万圣节狂欢夜'
            value={eventName}
            onChange={(event) => setEventName(event.target.value)}
            sx={{ width: 320 }}
          />
        </Box>

        <Button
          variant='contained'
          disabled={!eventId || !eventName || (showErrMsg && !!errMsg)}
          onClick={handleConfirm}
          sx={{ marginTop: 24 }}
        >
            Confirm
        </Button>
        {showErrMsg && <Text size='paragraph' color='error' mt={4}>{errMsg}</Text>}
      </Box>
    </Modal>
  );
};

export default NewEventModal;
