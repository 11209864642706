import React from 'react';
import {
  ClearOutlined as ClearOutlinedIcon,
  DoneOutlined as DoneOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, Modal, Stack } from '@mui/material';

import { TicketOrder, TicketOrderVirtualStatus } from 'common/src/models/event';
import { User } from 'common/src/models/user';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { screenTicketOrder } from '../../../../../redux/slices/event';

import { Text } from 'common/src/components/base';

interface IProps {
  selectedDetailedTicketOrders: { user: User | null, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus }[];
  onClose: () => void;
}

const ScreenModal: React.FC<IProps> = ({ selectedDetailedTicketOrders, onClose }) => {
  const dispatch = useAppDispatch();

  const [submittingAction, setSubmittingAction] = React.useState(false);

  const handleScreenResult = React.useCallback(async (screenResult: 'approved' | 'rejected') => {
    setSubmittingAction(true);
    for (const detailedTicketOrder of selectedDetailedTicketOrders) {
      await dispatch(screenTicketOrder({
        ticketOrderId: detailedTicketOrder.ticketOrder.id,
        screenResult: screenResult,
      }));
    }

    setSubmittingAction(false);
    onClose();
  }, [dispatch, onClose, selectedDetailedTicketOrders]);

  const statusMap = selectedDetailedTicketOrders
    .map((selectedTicketOrder) => selectedTicketOrder.virtualStatus)
    .reduce((prevVal, status) => {
      if (prevVal[status]) {
        prevVal[status] += 1;
      } else {
        prevVal[status] = 1;
      }
      return prevVal;
    }, {} as Record<string, number>);
  const selectionSummary = Object.keys(statusMap).map((key) => `${key}: ${statusMap[key]}`).join(', ');

  const hasOnlyStatuses = (statuses: TicketOrderVirtualStatus[])=>{
    return selectedDetailedTicketOrders
      .every((detailedTicketOrder) => {
        return statuses.includes(detailedTicketOrder.virtualStatus);
      });
  };

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Screen Ticket Orders</Text>
        {submittingAction ? (
          <Box sx={{ display: 'flex', mt: 16 }}>
            <CircularProgress size={20} sx={{ mr: 8 }} />
            Submitting Action
          </Box>
        ) : (
          <Box>
            <Text variant='italics' color='system' mb={16}>You have selected -- {selectionSummary}</Text>

            <Stack direction='row' justifyContent='center'>
              <Button
                startIcon={<DoneOutlinedIcon />}
                variant='contained'
                disabled={!selectedDetailedTicketOrders.length ||
                  !hasOnlyStatuses([TicketOrderVirtualStatus.PENDING, TicketOrderVirtualStatus.WAITLIST])}
                onClick={() => handleScreenResult('approved')}
              >
                Approve
              </Button>
              <Box width={15} />
              <Button
                startIcon={<ClearOutlinedIcon />}
                variant='contained'
                disabled={!selectedDetailedTicketOrders.length ||
                  !hasOnlyStatuses([TicketOrderVirtualStatus.PENDING])}
                onClick={() => handleScreenResult('rejected')}
              >
                Reject
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ScreenModal;
