import React from 'react';
import BoltIcon from '@mui/icons-material/Bolt';
import { Box, Button, CircularProgress } from '@mui/material';
import { RowClassParams } from 'ag-grid-community';
import { AgGridReact, AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';

import { getTicketVirtualStatus, resolveTicketOption, TicketOrder, TicketVirtualStatus } from 'common/src/models/event';
import { TicketCost } from 'common/src/models/event/ticket';
import { Gender } from 'common/src/models/user';
import { queryTicketOrdersRemote } from 'common/src/system/network/event';
import { adminBulkGetUsersRemote } from 'common/src/system/network/user';
import { useEventTemplatesWithFilter } from '../../../../hooks/useResource';

import { Page } from 'common/src/components/base';
import { AgGridTableTooltip } from '../../../../components/base';

type TicketMetadata = {
  ticketOrderId: string;
  ticketOptionId: string;
  ticketOptionName: string;
  status: TicketVirtualStatus;
  gender: Gender;
  cost: TicketCost;
}
type FeedbackMetadata = {
  ticketOrderId: string;
  rating: number;
  feedback: string;
  gender: Gender;
}
type EventTemplateMetadata = {
  tickets: TicketMetadata[];
  feedbacks: FeedbackMetadata[];
}

interface IProps {}

const FinanceView: React.FC<IProps> = () => {
  const { data: eventTemplates } = useEventTemplatesWithFilter(
    /* includePastEvents */ true, /* forceRefresh */ false);

  const agGridRef = React.useRef<AgGridReactType>(null);

  const [eventTemplateMetadataMap, setEventTemplateMetadataMap] = React.useState<Record<string, EventTemplateMetadata>>({});
  const [selectedEventTemplateIds, setSelectedEventTemplateIds] = React.useState<string[]>([]);
  const [loadingEventData, setLoadingEventData] = React.useState(false);

  const loadEventTemplateMetadata = React.useCallback(async () => {
    setLoadingEventData(true);

    // Get ticketOrders
    const ticketOrdersPromises = selectedEventTemplateIds.map((eventTemplateId) => queryTicketOrdersRemote(eventTemplateId));
    const eventTemplateToTicketOrders = (await Promise.all(ticketOrdersPromises))
      .reduce((acc, cur, idx) => {
        acc[selectedEventTemplateIds[idx]] = cur.filter((ticketOrder) => !ticketOrder.internal);
        return acc;
      }, {} as Record<string, TicketOrder[]>);

    // Get users
    const userIds = Array.from(new Set(Object.values(eventTemplateToTicketOrders).flat().map((ticketOrder) => ticketOrder.userId)));
    const users = await adminBulkGetUsersRemote(userIds);

    // Package data into ticket metadata
    const newMetadataMap: Record<string, EventTemplateMetadata> = {};
    for (const eventTemplateId of selectedEventTemplateIds) {
      const eventTemplate = eventTemplates.find((eventTemplate) => eventTemplate.id === eventTemplateId);
      if (!eventTemplate) {
        continue;
      }
      const ticketOrders = eventTemplateToTicketOrders[eventTemplateId];

      const ticketMetadataList: TicketMetadata[] = [];
      const feedbacks: FeedbackMetadata[] = [];
      ticketOrders.forEach((ticketOrder) => {
        const user = users.get(ticketOrder.userId);
        const gender = user ? user.userProfile.coreUserInfo.gender : Gender.UNKNOWN;

        for (const ticket of ticketOrder.tickets) {
          const ticketOptionName = resolveTicketOption(eventTemplate, ticket.ticketOptionId).name;
          ticketMetadataList.push({
            ticketOrderId: ticketOrder.id,
            ticketOptionId: ticket.ticketOptionId,
            ticketOptionName: ticketOptionName,
            status: getTicketVirtualStatus(ticketOrder, ticket),
            gender: gender,
            cost: ticketOrder.costDetails.ticketIdToCost[ticket.id],
          });
        }

        const feedback = ticketOrder.feedback;
        if (feedback && !feedback.optOut) {
          feedbacks.push({
            ticketOrderId: ticketOrder.id,
            rating: feedback.rating,
            feedback: feedback.comments,
            gender: gender,
          });
        }
      });

      newMetadataMap[eventTemplateId] = {
        tickets: ticketMetadataList,
        feedbacks: feedbacks,
      };
    }

    // Set state
    setEventTemplateMetadataMap((curMetadataMap)=> ({
      ...curMetadataMap,
      ...newMetadataMap,
    }));

    setLoadingEventData(false);
    setSelectedEventTemplateIds([]);
  }, [eventTemplates, selectedEventTemplateIds]);

  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
    filter: true,
    tooltipComponent: AgGridTableTooltip,
  }), []);

  // See https://www.ag-grid.com/javascript-data-grid/column-properties/
  const showFeedbackCol = !!Object.keys(eventTemplateMetadataMap).length;
  const effectiveCols = React.useMemo(() => {
    const columnDefs = [
      { field: 'status', lockPosition: 'left', width: 100 },
      { field: 'event', lockPosition: 'left', width: 200, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
      { field: 'tickets', autoHeight: true, width: 300, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
      { field: 'price', autoHeight: true, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
      { field: 'expectedRevenue', autoHeight: true, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
      { field: 'actualRevenue', autoHeight: true, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
      { field: 'expenses', autoHeight: true, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } },
    ];
    if (showFeedbackCol) {
      columnDefs.splice(4, 0, { field: 'feedbacks', autoHeight: true, cellStyle: { 'whiteSpace': 'break-spaces', 'lineHeight': 1.5, 'padding': 5 } });
    }
    let effectiveColumns = columnDefs.map((col) => {
      const effectiveColumn = {
        ...col,
        tooltipField: col.field,
      };
      return effectiveColumn;
    }) as any[];
    effectiveColumns = [
      // Add a fake column on left for checkbox
      { field: '', rowDrag: false, editable: false, lockPosition: 'left', width: 50,
        headerCheckboxSelection: false,
        checkboxSelection: true,
      },
      ...effectiveColumns,
    ];
    return effectiveColumns;
  }, [showFeedbackCol]);

  const eventData = React.useMemo(() => {
    return eventTemplates
      .sort((eventTemplate1, eventTemplate2) => {
        return eventTemplate2.eventFromTs - eventTemplate1.eventFromTs;
      })
      .map((eventTemplate) => {
        const payload = eventTemplate.payload.hostedEventPayload;

        let status = '';
        let bgColor = 'white';
        const now = Date.now();
        if (!eventTemplate.published) {
          status = '未发布';
          bgColor = '#ECECEC';
        } else if (payload.registrationFromTs > now) {
          status = '报名未开始';
          bgColor = '#FFF8E7';
        } else if (eventTemplate.eventFromTs > now) {
          status = '报名中';
          bgColor='#A8E4A0';
        } else if (eventTemplate.eventFromTs < now) {
          status = '报名结束';
          bgColor='#F0F8FF';
        }

        const eventTemplateMetadata = eventTemplateMetadataMap[eventTemplate.id];

        // Get ticket str
        const ticketsStrLst: string[] = [];
        payload.ticketOptions.forEach((ticketOption) => {
          let genderToCount: Record<Gender, number>| null = null;
          if (eventTemplateMetadata) {
            const relevantTicketMetadataList = eventTemplateMetadata.tickets
              .filter((ticketMetadata) => ticketMetadata.ticketOptionId === ticketOption.id)
              .filter((ticketMetadata) =>
                ticketMetadata.status === TicketVirtualStatus.CONFIRMED ||
                ticketMetadata.status === TicketVirtualStatus.CHECKED_IN);
            genderToCount = relevantTicketMetadataList.reduce((acc, { gender }) => ({
              ...acc,
              [gender]: (acc[gender] || 0) + 1,
            }), {} as Record<Gender, number>);
          }

          let ticketStr = `🎫 ${ticketOption.name}\n`;
          if (eventTemplate.singlesEvent) {
            ticketStr += `     👥 ${genderToCount ? Object.values(genderToCount).reduce((sum, count) => sum + count, 0) : '?'}/${ticketOption.maxTotalParticipants}`;
            ticketStr += ' (';
            ticketStr += `👨 ${genderToCount ? genderToCount[Gender.MALE] || 0 : '?'}/${ticketOption.maxMaleParticipants} `;
            ticketStr += `👩 ${genderToCount ? genderToCount[Gender.FEMALE] || 0 : '?'}/${ticketOption.maxFemaleParticipants}`;
            ticketStr += ')';
          } else {
            ticketStr += `     👥 ${genderToCount ? Object.values(genderToCount).reduce((sum, count) => sum + count, 0) : '?'}/${ticketOption.maxTotalParticipants}`;
            const genderStrs = [];
            if (genderToCount) {
              if (genderToCount[Gender.MALE]) {
                genderStrs.push(`👨${genderToCount[Gender.MALE]}`);
              }
              if (genderToCount[Gender.FEMALE]) {
                genderStrs.push(`👩${genderToCount[Gender.FEMALE]}`);
              }
              if (genderToCount[Gender.UNKNOWN]) {
                genderStrs.push(`🥷🏻${genderToCount[Gender.UNKNOWN]}`);
              }
            }
            if (genderStrs.length > 0) {
              ticketStr += ` (${genderStrs.join(' ')})`;
            }
          }
          ticketsStrLst.push(ticketStr);
        });

        // Get price str
        const pricesStrLst: string[] = [];
        payload.ticketOptions.forEach((ticketOption) => {
          let priceStr = `🎫 ${ticketOption.name}\n`;
          if (eventTemplate.singlesEvent) {
            priceStr += `     👨 $${ticketOption.maleCostInCents/100} 👩 $${ticketOption.femaleCostInCents/100}`;
          } else {
            priceStr += `     👥 $${ticketOption.genericCostInCents/100}`;
          }
          pricesStrLst.push(priceStr);
        });

        let expectedRevenueStr = '?';
        if (eventTemplateMetadata) {
          const expectedRevenueInCents = eventTemplateMetadata.tickets
            .filter((ticketMetadata) =>
              ticketMetadata.status === TicketVirtualStatus.CONFIRMED ||
              ticketMetadata.status === TicketVirtualStatus.CHECKED_IN)
            .map((ticketMetadata) => ticketMetadata.cost.finalCostInCents)
            .reduce((acc, cost) => acc + cost, 0);
          expectedRevenueStr = `$${expectedRevenueInCents/100}`;
        }

        let feedbacks = '';
        if (eventTemplateMetadata) {
          feedbacks = eventTemplateMetadata.feedbacks.map((feedback) => {
            let genderEmoji = '';
            switch (feedback.gender) {
              case Gender.MALE:
                genderEmoji = '👨';
                break;
              case Gender.FEMALE:
                genderEmoji = '👩';
                break;
              case Gender.UNKNOWN:
                genderEmoji = '🥷🏻';
            }

            return `(${feedback.rating}) ${genderEmoji} ${feedback.feedback}`;
          }).join('\n');
        }

        return {
          status: status,
          bgColor: bgColor,
          id: eventTemplate.id,
          event: `${eventTemplate.id}\n\n${eventTemplate.name}`,
          published: eventTemplate.published,
          tickets: ticketsStrLst.join('\n'),
          price: pricesStrLst.join('\n'),
          expectedRevenue: expectedRevenueStr,
          feedbacks: feedbacks,
        };
      });
  }, [eventTemplates, eventTemplateMetadataMap]);

  const handleSelectionChanged = React.useCallback(() => {
    const selectedData = agGridRef.current?.api.getSelectedRows();
    const newSelectedIds = (selectedData || []).map((data) => data.id);

    setSelectedEventTemplateIds(newSelectedIds);
  }, []);

  const handleCellEditingStopped = React.useCallback(() => {
    try {
      // automatically reset cell content, so it doesn't change
      agGridRef.current?.api.undoCellEditing();
    } catch (e) {
      // do nothing
    }
  }, []);

  const handleGetRowStatusParams = React.useCallback((params: RowClassParams<any>) => {
    return { background: params.data.bgColor };
  }, []);

  if (eventData === null) {
    return null;
  }

  return (
    <Page sx={{ mt: 16 }}>
      <Box className='ag-theme-alpine' style={{ height: 500 }}>
        <AgGridReact
          ref={agGridRef}
          rowData={eventData}
          columnDefs={effectiveCols}
          defaultColDef={defaultColDef}
          animateRows
          rowSelection='multiple'
          rowDragManaged
          onSelectionChanged={handleSelectionChanged}
          undoRedoCellEditing
          onCellEditingStopped={handleCellEditingStopped}
          getRowStyle={handleGetRowStatusParams}
        />
      </Box>
      <Button
        sx={{ mt: 10 }}
        onClick={loadEventTemplateMetadata}
        startIcon={<BoltIcon sx={{ fontSize: 18 }} />}
        disabled={loadingEventData}
      >
        Load Event Data {selectedEventTemplateIds.length ? `(${selectedEventTemplateIds.length})` : '' } {loadingEventData && <CircularProgress size={15} sx={{ ml: 2 }} />}
      </Button>
    </Page>
  );
};

export default FinanceView;
