import React from 'react';
import { Box } from '@mui/material';

import { EventTemplate, resolveTicketOption, Ticket, TicketOrder, TicketVirtualStatus } from 'common/src/models/event';
import { User, UserContact } from 'common/src/models/user';
import { getHumanReadableDateTime } from 'common/src/utils/time';

import TicketsTable from '../../../../components/event/TicketsTable';


interface IProps {
  eventTemplate: EventTemplate;
}

const TicketsView: React.FC<IProps> = ({
  eventTemplate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDetailedTickets, setSelectedDetailedTickets] =
    React.useState<{ user: User | null, ticket:Ticket, ticketOrder: TicketOrder, virtualStatus: TicketVirtualStatus }[]>([]);

  const columns = React.useMemo(() => {
    if (!eventTemplate) {
      return [];
    }

    const temp = [
      { field: 'status', width: 160 },
      { field: 'registeredTs', width: 150, valueFormatter: (data: any) => {
        const registrationTs = data.value;
        return registrationTs && eventTemplate ?
          getHumanReadableDateTime(registrationTs, eventTemplate.eventTimeZone) : '-';
      } },
      { field: 'id', width: 100 },
      { field: 'ticketOrderId', width: 130 },
      { field: 'ticketOptionId', width: 130 },
    ];
    if (eventTemplate.seatMapId) {
      temp.push({ field: 'seatId', width: 130 });
    }
    temp.push(
      { field: 'userId', width: 90 },
      { field: 'name', width: 150 },
      { field: 'tracking', width: 150 },
    );
    return temp;
  }, [eventTemplate]);

  const transformRowFn = React.useCallback((
    eventTemplate: EventTemplate, user: User, userContact: UserContact, ticket:Ticket, ticketOrder: TicketOrder, virtualStatus: TicketVirtualStatus,
  ) => {
    if (!eventTemplate) {
      return {};
    }

    const ticketOption = resolveTicketOption(eventTemplate, ticket.ticketOptionId);

    return {
      status: virtualStatus,
      id: ticket.id,
      ticketOrderId: ticketOrder.id,
      ticketOptionId: ticketOption.name,
      seatId: ticket.seatId,
      userId: user.id,
      registeredTs: ticketOrder.registrationTs,
      name: user.userProfile.coreUserInfo.name,
      tracking: ticketOrder.utm,
    };
  }, []);

  return (
    <Box>
      <TicketsTable
        eventTemplateId={eventTemplate.id}
        columns={columns}
        transformRowFn={transformRowFn}
        statusSortOrder={statusSortOrder}
        hideTitle
        onSelectedDetailedTicketsChange={setSelectedDetailedTickets}
      >
        <Box />
      </TicketsTable>
    </Box>
  );
};

const statusSortOrder = [
  TicketVirtualStatus.PENDING,
  TicketVirtualStatus.WAITLIST,
  TicketVirtualStatus.CONFIRMING,
  TicketVirtualStatus.CONFIRMED,
  TicketVirtualStatus.ERROR,
  TicketVirtualStatus.CHECKED_IN,
  TicketVirtualStatus.CANCELLED,
  TicketVirtualStatus.INIT,
];

export default TicketsView;
