import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EventTemplate, TaroPassRecord, TicketOrder, Vendor } from 'common/src/models/event';
import { EventContextFilter } from 'common/src/models/event/eventTemplate';
import { EventTemplateGroup } from 'common/src/models/event/eventTemplateGroup';
import { AdjustTicketOrderAction } from 'common/src/models/event/ticket';
import {
  adjustTicketOrderRemote, bulkGetEventTemplatesRemote, checkInTicketsRemote,
  cloneEventTemplateRemote, getEventTemplateGroupsRemote, listEventTemplatesRemote,
  listTaroPassesRemote, listVendorsRemote, queryTicketOrdersRemote, saveEventTemplateGroupRemote,
  saveEventTemplateRemote, saveTaroPassRemote, saveVendorRemote, screenTicketOrderRemote,
} from 'common/src/system/network/event';
import { retainRef } from 'common/src/utils/comparison';

import { AppDispatch, RootState } from '../store';
import { CacheCompleteness } from './common';

export enum EventTemplatesCacheCompleteness {
  PARTIAL = 'partial',
  CURRENT = 'current',
  FULL = 'full',
}

export interface EventTemplatesCache {
  recordById: Record<string, EventTemplate>;
  completeness: EventTemplatesCacheCompleteness;
}

export interface EventTemplateGroupsCache {
  records: EventTemplateGroup[]
  completeness: CacheCompleteness;
}

export interface VendorsCache {
  records: Vendor[];
  completeness: CacheCompleteness;
}

export interface TaroPassesCache {
  records: TaroPassRecord[];
  completeness: CacheCompleteness;
}

// ### State ###

interface EventState {
  eventTemplatesCache: EventTemplatesCache;
  eventTemplateGroupsCache: EventTemplateGroupsCache;
  vendorsCache: VendorsCache;
  ticketOrdersByEventTemplateId: Record<string, TicketOrder[]>;
  taroPassesCache: TaroPassesCache;
}

const initialState: EventState = {
  eventTemplatesCache: {
    recordById: {},
    completeness: EventTemplatesCacheCompleteness.PARTIAL,
  },
  eventTemplateGroupsCache: {
    records: [],
    completeness: CacheCompleteness.PARTIAL,
  },
  vendorsCache: {
    records: [],
    completeness: CacheCompleteness.PARTIAL,
  },
  ticketOrdersByEventTemplateId: {},
  taroPassesCache: {
    records: [],
    completeness: CacheCompleteness.PARTIAL,
  },
};


// ### Actions ### //

export const refreshSelectEventTemplates = createAsyncThunk<
  void,
  { eventTemplateIds: string[], onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshSelectEventTemplates', async ({ eventTemplateIds, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  const eventTemplateIdsRequireRefreshing = new Set<string>();
  for (const eventTemplateId of eventTemplateIds) {
    if (onlyIfUncached && state.event.eventTemplatesCache.recordById[eventTemplateId]) {
      continue;
    }
    eventTemplateIdsRequireRefreshing.add(eventTemplateId);
  }
  if (eventTemplateIdsRequireRefreshing.size === 0) {
    return;
  }

  const eventTemplates = await bulkGetEventTemplatesRemote(Array.from(eventTemplateIdsRequireRefreshing));
  dispatch(setEventTemplates({
    eventTemplates: eventTemplates,
    completeness: EventTemplatesCacheCompleteness.PARTIAL,
  }));
});

export const refreshEventTemplates = createAsyncThunk<
  void,
  { includePastEvents: boolean, onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplates', async ({ includePastEvents, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  if (onlyIfUncached) {
    if (includePastEvents) {
      if (state.event.eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.FULL) {
        return;
      }
    } else {
      if (state.event.eventTemplatesCache.completeness !== EventTemplatesCacheCompleteness.PARTIAL) {
        return;
      }
    }
  }

  const eventTemplates = await listEventTemplatesRemote(includePastEvents, EventContextFilter.ALL);
  dispatch(setEventTemplates({
    eventTemplates: eventTemplates,
    completeness: includePastEvents ? EventTemplatesCacheCompleteness.FULL : EventTemplatesCacheCompleteness.CURRENT,
  }));
});

export const refreshEventTemplateGroups = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplateGroups', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.eventTemplateGroupsCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const eventTemplateGroups = await getEventTemplateGroupsRemote();
  dispatch(setEventTemplateGroups(eventTemplateGroups));
});

export const refreshVendors = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshVendors', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.vendorsCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const vendors = await listVendorsRemote();
  dispatch(setVendors(vendors));
});

export const refreshTicketOrders = createAsyncThunk<
  void,
  { eventTemplateId: string, onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshTicketOrders', async ({ eventTemplateId, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && eventTemplateId in state.event.ticketOrdersByEventTemplateId) {
    return;
  }

  const ticketOrders = await queryTicketOrdersRemote(eventTemplateId);
  dispatch(setTicketOrders({
    eventTemplateId: eventTemplateId,
    ticketOrders: ticketOrders,
  }));
});

export const refreshTaroPasses = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshTaroPasses', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && state.event.taroPassesCache.completeness === CacheCompleteness.FULL) {
    return;
  }

  const taroPasses = await listTaroPassesRemote();
  dispatch(setTaroPasses(taroPasses));
});


// #### Ticket management ####

export const screenTicketOrder = createAsyncThunk<
  void,
  { ticketOrderId: string, screenResult: 'approved' | 'rejected' },
  { state: RootState; dispatch: AppDispatch }
>('event/screenTicketOrder', async ({ ticketOrderId, screenResult }, { getState, dispatch }) => {
  const state = getState();
  await screenTicketOrderRemote(ticketOrderId, screenResult);

  const ticketOrder = selectTicketOrder(state, ticketOrderId);

  await dispatch(refreshTicketOrders({
    eventTemplateId: ticketOrder.eventTemplateId, onlyIfUncached: false,
  }));
});

export const adjustTicketOrder = createAsyncThunk<
  void,
  { ticketOrderId: string, actionType: AdjustTicketOrderAction, refundInCents?: number},
  { state: RootState; dispatch: AppDispatch }
>('event/adjustTicketOrder', async ({ ticketOrderId, actionType, refundInCents }, { getState, dispatch }) => {
  const state = getState();
  await adjustTicketOrderRemote(ticketOrderId, actionType, refundInCents);

  const ticketOrder = selectTicketOrder(state, ticketOrderId);

  await dispatch(refreshTicketOrders({
    eventTemplateId: ticketOrder.eventTemplateId, onlyIfUncached: false,
  }));
});

export const checkInTickets = createAsyncThunk<
  void,
  {ticketOrderId: string, ticketIds: string[]},
  { state: RootState; dispatch: AppDispatch }
>('event/checkInTickets', async ({ ticketOrderId, ticketIds }, { getState, dispatch }) => {
  const state = getState();
  await checkInTicketsRemote(ticketOrderId, ticketIds);

  const ticketOrder = selectTicketOrder(state, ticketOrderId);

  await dispatch(refreshTicketOrders({
    eventTemplateId: ticketOrder.eventTemplateId, onlyIfUncached: false,
  }));
});

export const saveEventTemplate = createAsyncThunk<
  void,
  EventTemplate,
  { dispatch: AppDispatch }
>('event/saveEventTemplate', async (eventTemplate, { dispatch }) => {
  await saveEventTemplateRemote(eventTemplate);
  await dispatch(refreshEventTemplates({ includePastEvents: true, onlyIfUncached: false }));
});

export const saveEventTemplateGroup = createAsyncThunk<
  void,
  EventTemplateGroup,
  { dispatch: AppDispatch }
>('event/saveEventTemplateGroup', async (eventTemplateGroup, { dispatch }) => {
  await saveEventTemplateGroupRemote(eventTemplateGroup);
  await dispatch(refreshEventTemplateGroups({ onlyIfUncached: false }));
});

export const cloneEventTemplate = createAsyncThunk<
  void,
  {originEventTemplateId: string, newEventTemplateId: string},
  { dispatch: AppDispatch }
>('event/cloneEventTemplate', async ({ originEventTemplateId, newEventTemplateId },
  { dispatch }) => {
  await cloneEventTemplateRemote(originEventTemplateId, newEventTemplateId);
  await dispatch(refreshEventTemplates({ includePastEvents: true, onlyIfUncached: false }));
});

export const saveTaroPass = createAsyncThunk<
  void,
  TaroPassRecord,
  { dispatch: AppDispatch }
>('event/saveTaroPass', async (taroPass, { dispatch }) => {
  await saveTaroPassRemote(taroPass);
  await dispatch(refreshTaroPasses({ onlyIfUncached: false }));
});

export const saveVendor = createAsyncThunk<
  void,
  Vendor,
  { dispatch: AppDispatch }
>('event/saveVendor', async (vendor, { dispatch }) => {
  await saveVendorRemote(vendor);
  await dispatch(refreshVendors({ onlyIfUncached: false }));
});


// ### Slice ###

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventTemplates: (state, action: PayloadAction<{
      eventTemplates: EventTemplate[],
      completeness: EventTemplatesCacheCompleteness,
    }>) => {
      const originalEventTemplatesList: EventTemplate[] = Object.values(state.eventTemplatesCache.recordById);
      const effectiveEventTemplatesList: EventTemplate[] =
        retainRef(originalEventTemplatesList, action.payload.eventTemplates);

      const eventTemplates: Record<string, EventTemplate> = {};
      // Retain old entries
      originalEventTemplatesList.forEach((eventTemplate) => {
        eventTemplates[eventTemplate.id] = eventTemplate;
      });
      // And overwrite with new (for incremental refreshes)
      effectiveEventTemplatesList.forEach((eventTemplate) => {
        eventTemplates[eventTemplate.id] = eventTemplate;
      });

      let effectiveCacheCompleteness = state.eventTemplatesCache.completeness;
      if (action.payload.completeness === EventTemplatesCacheCompleteness.FULL) {
        effectiveCacheCompleteness = EventTemplatesCacheCompleteness.FULL;
      } else if (action.payload.completeness === EventTemplatesCacheCompleteness.CURRENT) {
        if (state.eventTemplatesCache.completeness !== EventTemplatesCacheCompleteness.FULL) {
          effectiveCacheCompleteness = EventTemplatesCacheCompleteness.CURRENT;
        }
      }
      state.eventTemplatesCache = {
        recordById: eventTemplates,
        completeness: effectiveCacheCompleteness,
      };
    },
    setEventTemplateGroups: (state, action: PayloadAction<EventTemplateGroup[]>) => {
      state.eventTemplateGroupsCache = {
        records: retainRef(state.eventTemplateGroupsCache.records, action.payload),
        completeness: CacheCompleteness.FULL,
      };
    },
    setVendors: (state, action: PayloadAction<Vendor[]>) => {
      state.vendorsCache = {
        records: retainRef(state.vendorsCache.records, action.payload),
        completeness: CacheCompleteness.FULL,
      };
    },
    setTicketOrders: (state, action: PayloadAction<{
      eventTemplateId: string,
      ticketOrders: TicketOrder[],
    }>) => {
      const { eventTemplateId, ticketOrders } = action.payload;
      const originalTicketOrdersForEventTemplate = state.ticketOrdersByEventTemplateId[eventTemplateId] || [];
      const effectiveTicketOrdersForEventTemplate = retainRef(originalTicketOrdersForEventTemplate, ticketOrders);
      state.ticketOrdersByEventTemplateId[eventTemplateId] = effectiveTicketOrdersForEventTemplate;
    },
    setTaroPasses: (state, action: PayloadAction<TaroPassRecord[]>) => {
      state.taroPassesCache = {
        records: retainRef(state.taroPassesCache.records, action.payload),
        completeness: CacheCompleteness.FULL,
      };
    },
  },
});


// ### Selectors ### //

export const selectEventTemplatesCache = (state: RootState): EventTemplatesCache => {
  return state.event.eventTemplatesCache;
};

export const selectOptEventTemplate = (
  state: RootState, eventTemplateId: string,
): EventTemplate | null => {
  return state.event.eventTemplatesCache.recordById[eventTemplateId] || null;
};

export const selectEventTemplateGroupsCache = (state: RootState): EventTemplateGroupsCache => {
  return state.event.eventTemplateGroupsCache;
};

export const selectOptEventTemplateGroup = (
  state: RootState, eventTemplateGroupId: string,
): EventTemplateGroup | null => {
  return state.event.eventTemplateGroupsCache.records
    .find((eventTemplateGroup_) => eventTemplateGroup_.id === eventTemplateGroupId) || null;
};

export const selectVendorsCache = (state: RootState): VendorsCache => {
  return state.event.vendorsCache;
};

export const selectTicketOrdersByTemplate = (
  state: RootState, eventTemplateId: string,
): TicketOrder[] | null => {
  return state.event.ticketOrdersByEventTemplateId[eventTemplateId] || null;
};

export const selectTicketOrder = (
  state: RootState, ticketOrderId: string,
): TicketOrder => {
  for (const ticketOrders of Object.values(state.event.ticketOrdersByEventTemplateId)) {
    const matchingTicketOrder = ticketOrders
      .find((ticketOrder) => ticketOrder.id === ticketOrderId);
    if (matchingTicketOrder) {
      return matchingTicketOrder;
    }
  }
  throw new Error('Unknown ticketOrderId: ' + ticketOrderId);
};

export const selectTaroPassesCache = (state: RootState): TaroPassesCache => {
  return state.event.taroPassesCache;
};


// ### Exports ### //

export const { setEventTemplates, setEventTemplateGroups, setTicketOrders, setTaroPasses, setVendors } = eventSlice.actions;
export default eventSlice.reducer;
