import React from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import { ValidationError } from 'yup';

import { vendorIdSchema, vendorNameSchema } from '../../../utils/validation';

import { Text } from 'common/src/components/base';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: ( id: string, name: string) => void;
}

const NewVendorModal: React.FC<IProps> = ({ isOpen, onClose, onConfirm }) => {
  const [vendorId, setVendorId] = React.useState('');
  const [vendorName, setVendorName] = React.useState('');

  const [errMsg, setErrMsg] = React.useState('');
  const [showErrMsg, setShowErrMsg] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          vendorIdSchema.validate(vendorId),
          vendorNameSchema.validate(vendorName),
        ]);

        setErrMsg('');
      } catch (e) {
        if (e instanceof ValidationError && e.errors.length) {
          setErrMsg(e.errors[0]);
        } else {
          setErrMsg('错误'); // This means there's a bug in the code.
        }
      }
    })();
  }, [vendorId, vendorName]);

  const handleConfirm = () => {
    if (
      !vendorIdSchema.isValidSync(vendorId) ||
      !vendorNameSchema.isValidSync(vendorName)
    ) {
      setShowErrMsg(true);
      return;
    }

    onConfirm(vendorId, vendorName);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Create a new Vendor record</Text>
        <Text variant='italics' color='system' mb={16}>Please confirm creation of a new Vendor record</Text>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>Vendor ID</Text>
          <TextField
            margin='normal'
            size='small'
            required
            autoFocus
            placeholder='tina_dance'
            value={vendorId}
            onChange={(event) => setVendorId(event.target.value)}
            sx={{ width: 300 }}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>商家名称</Text>
          <TextField
            margin='normal'
            size='small'
            required
            placeholder="Tina's Dance Studio"
            value={vendorName}
            onChange={(event) => setVendorName(event.target.value)}
            sx={{ width: 300 }}
          />
        </Box>

        <Button
          variant='contained'
          disabled={!vendorId || !vendorName || (showErrMsg && !!errMsg)}
          onClick={handleConfirm}
          sx={{ marginTop: 24 }}
        >
            Confirm
        </Button>
        {showErrMsg && <Text size='paragraph' color='error' mt={4}>{errMsg}</Text>}
      </Box>
    </Modal>
  );
};

export default NewVendorModal;
