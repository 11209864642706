import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  ContentCopy as ContentCopyIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Link } from '@mui/material';
import { ValidationError } from 'yup';

import { Colors } from 'common/src/constants';
import { EventTemplate } from 'common/src/models/event';
import { uploadEventImageRemote } from 'common/src/system/network/event';
import { readFile } from 'common/src/utils/file';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { useEventTemplate } from '../../../../hooks/useResource';
import { cloneEventTemplate, saveEventTemplate } from '../../../../redux/slices/event';
import { eventTemplateSchema } from '../../../../utils/validation';

import { Page } from 'common/src/components/base';
import CloneEventModal from '../EventTemplateSelectorScreen/CloneEventModal';
import Editor from './Editor';

export default function EventTemplateEditorScreen() {
  const { eventTemplateId } = useParams();
  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for EventTemplateEditorScreen');
  }

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const eventTemplate = useEventTemplate(eventTemplateId, /* forceRefresh */ true);

  // eventTemplate is the redux/server view, while draftEventTemplate is WIP.
  const [draftEventTemplate, setDraftEventTemplate] = React.useState<EventTemplate>(
    JSON.parse(JSON.stringify(eventTemplate)),
  );

  const [copyEventModalOpen, setCopyEventModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (draftEventTemplate === null) {
      setDraftEventTemplate(JSON.parse(JSON.stringify(eventTemplate)));
    }
  }, [draftEventTemplate, eventTemplate]);

  const [hasPendingChanges, setHasPendingChanges] = React.useState(false);
  React.useEffect(() => {
    const draftEventTemplateCopy = {
      ...draftEventTemplate,
      media: {
        id: draftEventTemplate ? draftEventTemplate.media.id : '',
        // Remove download url since that can change all the time
      },
      thumbnailMedia: {},
      extraMedia: draftEventTemplate ? draftEventTemplate.extraMedia.map((media) => {
        media.id;
      }): [],
      extraThumbnailMedia: [],
    };
    const eventTemplateCopy = {
      ...eventTemplate,
      media: {
        id: eventTemplate ? eventTemplate.media.id : '',
        // Remove download url since that can change all the time
      },
      thumbnailMedia: {},
      extraMedia: eventTemplate ? eventTemplate.extraMedia.map((media) => {
        media.id;
      }): [],
      extraThumbnailMedia: [],
    };

    setHasPendingChanges(JSON.stringify(draftEventTemplateCopy) !== JSON.stringify(eventTemplateCopy));
  }, [eventTemplate, draftEventTemplate]);

  const [showValidationErr, setShowValidationErr] = React.useState(false);
  const [readOnlyMode, setReadOnlyMode] = React.useState(true);

  const savePendingChanges = React.useCallback(async () => {
    // Clean up everything
    const cleanedDraftEventTemplate: EventTemplate = JSON.parse(JSON.stringify(draftEventTemplate));
    cleanedDraftEventTemplate.name = cleanedDraftEventTemplate.name.trim();
    cleanedDraftEventTemplate.description = cleanedDraftEventTemplate.description.trim();
    cleanedDraftEventTemplate.intro = cleanedDraftEventTemplate.intro.trim();
    if (JSON.stringify(draftEventTemplate) !== JSON.stringify(cleanedDraftEventTemplate)) {
      setDraftEventTemplate(cleanedDraftEventTemplate);
    }

    try {
      eventTemplateSchema.validateSync(cleanedDraftEventTemplate);
    } catch (validationErr) {
      if (validationErr instanceof ValidationError && validationErr.errors.length) {
        console.log('validation error: ' + validationErr.errors[0]);
      } else {
        console.log('unknown validation error');
      }
      setShowValidationErr(true);
      return;
    }
    setShowValidationErr(false);

    // Do update
    setSavingEventTemplate(true);
    await dispatch(saveEventTemplate(cleanedDraftEventTemplate));
    setSavingEventTemplate(false);
  }, [dispatch, draftEventTemplate]);

  const handleFabPress = () => {
    if (readOnlyMode) {
      setReadOnlyMode(false);
    } else {
      savePendingChanges();
    }
  };

  const [savingEventTemplate, setSavingEventTemplate] = React.useState(false);

  if (!draftEventTemplate || !eventTemplate) {
    return null;
  }

  const saveDisabled = !readOnlyMode && (!hasPendingChanges || eventTemplate.id.startsWith('_test_'));
  const fabView = (
    <IconButton
      onClick={handleFabPress}
      sx={{ position: 'fixed', right: 30, bottom: 50 }}
      disabled={savingEventTemplate || saveDisabled}
    >
      <Box
        sx={{
          width: 60, height: 60, p: 10, borderRadius: 60, boxShadow: 1,
          bgcolor: saveDisabled ? Colors.GREY100 : Colors.PRIMARY_BACKGROUND,
        }}
      >
        {
          readOnlyMode ? (
            <EditIcon
              sx={{
                width: 40, height: 40, color: Colors.PRIMARY,
              }}
            />
          ) : !savingEventTemplate ? (
            <SaveIcon
              sx={{
                width: 40, height: 40,
                color: showValidationErr && !eventTemplateSchema.isValidSync(draftEventTemplate) ?
                  Colors.ERROR :
                  saveDisabled ? Colors.DISABLED : Colors.PRIMARY,
              }}
            />
          ) : (
            <CircularProgress size={40} />
          )
        }
      </Box>
    </IconButton>
  );
  const cloneFab = (
    <IconButton
      onClick={()=>setCopyEventModalOpen(true)}
      sx={{ position: 'fixed', right: 120, bottom: 50 }}
      disabled={savingEventTemplate || saveDisabled}
    >
      <Box
        sx={{
          width: 60, height: 60, p: 10, borderRadius: 60, boxShadow: 1,
          bgcolor: saveDisabled ? Colors.GREY100 : Colors.PRIMARY_BACKGROUND,
        }}
      >
        <ContentCopyIcon
          sx={{
            width: 40, height: 40,
            color: Colors.PRIMARY,
          }}
        />
      </Box>
    </IconButton>
  );

  const cloneEventModal = (
    <CloneEventModal
      originEvent ={eventTemplate}
      isOpen={copyEventModalOpen}
      onClose={() => setCopyEventModalOpen(false)}
      onConfirm={async (originEventTemplateId: string, newEventTemplateId: string) => {
        // Save new event and then navigate there
        await dispatch(cloneEventTemplate({ originEventTemplateId, newEventTemplateId }));
        setCopyEventModalOpen(false);
        navigate(`/editEventTemplate/${newEventTemplateId}`);
        location.reload();
      }}
    />
  );

  return (
    <Page>
      <Link onClick={() => navigate('/eventTemplate')} underline='hover' sx={{ display: 'flex' }}>
        <ArrowBackOutlinedIcon />
          Return to Event Template selection
      </Link>
      <Editor
        eventTemplate={draftEventTemplate}
        confirmPublished={eventTemplate.published}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr}
        onEventTemplateChange={(mutationFn: ((prevEventTemplate: EventTemplate) => void)) => {
          setDraftEventTemplate((prevDraftEventTemplate) => {
            const clonedDraftEventTemplate = JSON.parse(JSON.stringify(prevDraftEventTemplate));
            mutationFn(clonedDraftEventTemplate);
            return clonedDraftEventTemplate;
          });
        }}
        onUploadImg={async (f) => {
          const base64Content = await readFile(f);
          const fileType = f.type.replace('image/', '');
          return await uploadEventImageRemote(eventTemplateId, base64Content, fileType);
        }}
      />
      {cloneFab}
      {fabView}
      {cloneEventModal}
    </Page>
  );
}
