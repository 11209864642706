import React from 'react';
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Box, CircularProgress, Container, IconButton, Modal,
} from '@mui/material';
import { ValidationError } from 'yup';

import { Colors } from 'common/src/constants';
import { Vendor } from 'common/src/models/event';
import { ResourceRef } from 'common/src/models/shared';
import { vendorSchema } from '../../../../utils/validation';

import Editor from './Editor';

interface IProps {
  vendor: Vendor;
  onClose: () => void;
  onSave: (vendor: Vendor) => Promise<void>;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const VendorEditor: React.FC<IProps> = ({
  vendor, onClose, onSave, onUploadImg,
}) => {
  // vendor is the redux/server view, while draftVendor is WIP.
  const [draftVendor, setDraftVendor] = React.useState<Vendor>(
    JSON.parse(JSON.stringify(vendor)),
  );

  const [hasPendingChanges, setHasPendingChanges] = React.useState(false);
  React.useEffect(() => {
    const draftVendorCopy = {
      ...draftVendor,
      refMedia: {
        id: draftVendor.refMedia.id,
        // Remove download url since that can change all the time
      },
    };
    const vendorCopy = {
      ...vendor,
      refMedia: {
        id: vendor.refMedia.id,
        // Remove download url since that can change all the time
      },
    };
    setHasPendingChanges(JSON.stringify(draftVendorCopy) !== JSON.stringify(vendorCopy));
  }, [vendor, draftVendor]);

  const [showValidationErr, setShowValidationErr] = React.useState(false);

  const savePendingChanges = React.useCallback(async () => {
    // Clean up everything

    const cleanedDraftVendor: Vendor = JSON.parse(JSON.stringify(draftVendor));
    cleanedDraftVendor.name = cleanedDraftVendor.name.trim();
    cleanedDraftVendor.description = cleanedDraftVendor.description.trim();
    if (JSON.stringify(draftVendor) !== JSON.stringify(cleanedDraftVendor)) {
      setDraftVendor(cleanedDraftVendor);
    }

    try {
      vendorSchema.validateSync(cleanedDraftVendor);
    } catch (validationErr) {
      if (validationErr instanceof ValidationError && validationErr.errors.length) {
        console.log('validation error: ' + validationErr.errors[0]);
      } else {
        console.log('unknown validation error');
      }
      setShowValidationErr(true);
      return;
    }
    setShowValidationErr(false);

    // Do update
    setSavingVendor(true);
    await onSave(draftVendor);
    setSavingVendor(false);
  }, [draftVendor, onSave]);

  const [savingVendor, setSavingVendor] = React.useState(false);

  const saveDisabled = !hasPendingChanges;

  return (
    <Modal open>
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: '90%', height: '95%', bgcolor: 'background.default', boxShadow: 24,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: 'fixed', right: 10, top: 10 }}
        >
          <CloseIcon color='primary' />
        </IconButton>
        <Container sx={{ overflow: 'scroll', height: '100%' }}>
          <Editor
            vendor={draftVendor}
            showValidationErr={showValidationErr}
            onVendorChange={setDraftVendor}
            onUploadImg={onUploadImg}
          />
        </Container>

        <IconButton
          onClick={savePendingChanges}
          sx={{ position: 'fixed', right: 30, bottom: 50 }}
          disabled={savingVendor || saveDisabled}
        >
          <Box
            sx={{
              width: 60, height: 60, p: 10, borderRadius: 60, boxShadow: 1,
              bgcolor: saveDisabled ? Colors.GREY100 : Colors.PRIMARY_BACKGROUND,
            }}
          >
            {
              !savingVendor ? (
                <SaveIcon
                  sx={{
                    width: 40, height: 40,
                    color: showValidationErr && !vendorSchema.isValidSync(draftVendor) ?
                      Colors.ERROR :
                      saveDisabled ? Colors.DISABLED : Colors.PRIMARY,
                  }}
                />
              ) : (
                <CircularProgress size={40} />
              )
            }
          </Box>
        </IconButton>
      </Box>
    </Modal>
  );
};

export default VendorEditor;
