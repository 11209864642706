import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User, UserContact } from 'common/src/models/user';
import { adminBulkGetUsersRemote, bulkGetUserContactsRemote } from 'common/src/system/network/user';

import { AppDispatch, RootState } from '../store';


// ### State ###

interface UserState {
  userCache: Record<string, User | null>;
  contactCache: Record<string, UserContact | null>;
}
const initialState: UserState = {
  userCache: {},
  contactCache: {},
};


// ### Actions ###

export const fetchUsersIfUncached = createAsyncThunk<
  void,
  string[],
  { state: RootState; dispatch: AppDispatch }
>('user/fetchUsersIfUncached', async (userIds, { getState, dispatch }) => {
  const userCache = getState().user.userCache;

  const missingUserIds = [];
  for (const userId of userIds) {
    if (!(userId in userCache)) {
      missingUserIds.push(userId);
    }
  }
  if (missingUserIds.length === 0) {
    return;
  }

  const resp = await adminBulkGetUsersRemote(missingUserIds);
  const fetchedUsers: Record<string, User|null> = {};
  for (const [fetchedUserId, fetchedUser] of Array.from(resp.entries())) {
    fetchedUsers[fetchedUserId] = fetchedUser;
  }
  dispatch(setUsers(fetchedUsers));
});


export const fetchUserContactsIfUncached = createAsyncThunk<
  void,
  string[],
  { state: RootState; dispatch: AppDispatch }
>('user/fetchUserContactsIfUncached', async (userIds, { getState, dispatch }) => {
  const contactCache = getState().user.contactCache;

  const missingUserIds = [];
  for (const userId of userIds) {
    if (!(userId in contactCache)) {
      missingUserIds.push(userId);
    }
  }
  if (missingUserIds.length === 0) {
    return;
  }

  const resp = await bulkGetUserContactsRemote(missingUserIds);
  const fetchedUserContacts: Record<string, UserContact|null> = {};
  for (const [fetchedUserId, fetchedUserContact] of Array.from(resp.entries())) {
    fetchedUserContacts[fetchedUserId] = fetchedUserContact;
  }
  dispatch(setUserContacts(fetchedUserContacts));
});


// ### Slice ###

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<Record<string, User | null>>) => {
      state.userCache = {
        ...state.userCache,
        ...action.payload,
      };
    },
    setUserContacts: (state, action: PayloadAction<Record<string, UserContact | null>>) => {
      state.contactCache = {
        ...state.contactCache,
        ...action.payload,
      };
    },
  },
});


// ### Selectors ###

export const selectAllUsers = (state: RootState) => {
  return state.user.userCache;
};

export const selectUsers = (state: RootState, userIds: string[]) => {
  const users: Record<string, User|null> = {};
  for (const userId of userIds) {
    users[userId] = state.user.userCache[userId];
  }
  return users;
};

export const selectAllUserContacts = (state: RootState) => {
  return state.user.contactCache;
};

export const selectUserContacts = (state: RootState, userIds: string[]) => {
  const userContacts: Record<string, UserContact|null> = {};
  for (const userId of userIds) {
    userContacts[userId] = state.user.contactCache[userId];
  }
  return userContacts;
};


// ### Exports ### //

export const { setUsers, setUserContacts } = userSlice.actions;
export default userSlice.reducer;
