import React from 'react';
import {
  DownloadOutlined as DownloadOutlinedIcon,
  RefreshOutlined as RefreshOutlinedIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, InputAdornment, TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';

import {
  CostAdjustor, getTicketOrderVirtualStatus, TicketOption, TicketOrder, TicketOrderVirtualStatus,
} from 'common/src/models/event';
import { Gender, User, UserContact } from 'common/src/models/user';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { useEventTemplate, useTicketOrdersByEventTemplate } from '../../hooks/useResource';
import { refreshTicketOrders } from '../../redux/slices/event';
import {
  fetchUserContactsIfUncached, fetchUsersIfUncached, selectAllUserContacts, selectAllUsers,
} from '../../redux/slices/user';

import { Text } from 'common/src/components/base';
import AgGridTableTooltip from '../base/AgGridTableTooltip';
import StatusRow from './StatusRow';

interface IProps {
  topChildren?: React.ReactNode;
  children: React.ReactNode;
  eventTemplateId: string;
  columns: {
    field: string, width?: number, minWidth?: number, flex?: number, wrapText?: boolean,
    autoHeight?: boolean, valueFormatter?: (val: any) => any
  }[];
  transformRowFn: (
    user: User, userContact: UserContact, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus
  ) => Record<string, any>;
  statusSortOrder: TicketOrderVirtualStatus[];
  selectableStatuses?: TicketOrderVirtualStatus[];
  disableMultiSelect?: boolean;
  hideTitle?: boolean;
  onSelectedDetailedTicketOrdersChange: (
    selectedDetailedTicketOrders: { user: User | null, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus }[]
  ) => void;
  onShowRowDetails?: (user: User, ticketOrder: TicketOrder) => void;
}

const TicketOrdersTable: React.FC<IProps> = ({
  topChildren, children, eventTemplateId, columns, transformRowFn, statusSortOrder,
  selectableStatuses, disableMultiSelect, hideTitle, onSelectedDetailedTicketOrdersChange, onShowRowDetails,
}) => {
  const dispatch = useAppDispatch();

  const eventTemplate = useEventTemplate(eventTemplateId);
  const eventTemplateName = eventTemplate ? eventTemplate.name : '';

  const [showInternal, setShowInternal] = React.useState(false);

  const ticketOrders = useTicketOrdersByEventTemplate(eventTemplateId);
  const filteredTicketOrders = React.useMemo(() => {
    if (!ticketOrders) {
      return null;
    }
    return ticketOrders.filter((ticketOrder) => showInternal || !ticketOrder.internal);
  }, [ticketOrders, showInternal]);

  const agGridRef = React.useRef<AgGridReactType>(null);

  const [searchTerm, setSearchTerm] = React.useState('');
  React.useEffect(() => {
    if (!ticketOrders) {
      return;
    }
    if (!searchTerm) {
      setShowInternal(false);
      agGridRef.current?.api.setQuickFilter('');
      return;
    }

    if (searchTerm.length >= 4) {
      const filteredTicketOrders = [
        ...ticketOrders.filter((ticketOrder) => ticketOrder.id.includes(searchTerm)),
        ...ticketOrders.filter((ticketOrder) => ticketOrder.userId.includes(searchTerm)),
      ];
      if (filteredTicketOrders.length) {
        if (filteredTicketOrders.some((ticketOrder) => ticketOrder.internal)) {
          setShowInternal(true);
        } else {
          setShowInternal(false);
        }
        agGridRef.current?.api.setQuickFilter(searchTerm);
        return;
      }
    }

    agGridRef.current?.api.setQuickFilter('dummy$$$');
  }, [searchTerm, ticketOrders]);


  const [detailedTicketOrders, setDetailedTicketOrders] = React.useState<{
    user: User | null,
    userContact: UserContact | null,
    ticketOrder: TicketOrder,
    virtualStatus: TicketOrderVirtualStatus,
  }[] | null>(null);

  const [enableMultiline, setEnableMultiline] = React.useState(false);
  const users = useAppSelector(selectAllUsers);
  const userContacts = useAppSelector(selectAllUserContacts);

  const [selectionSummary, setSelectionSummary] = React.useState('');

  // Load users for ticketOrders
  React.useEffect(() => {
    if (ticketOrders === null) {
      return;
    }
    const userIds = ticketOrders.map((ticketOrder) => ticketOrder.userId);
    const deduppedUserIds = Array.from(new Set(userIds));
    dispatch(fetchUsersIfUncached(deduppedUserIds));
    dispatch(fetchUserContactsIfUncached(deduppedUserIds));
  }, [dispatch, ticketOrders]);

  React.useEffect(() => {
    if (filteredTicketOrders === null) {
      setDetailedTicketOrders(null);
      return;
    }

    setDetailedTicketOrders(filteredTicketOrders //
      .map((ticketOrder) => {
        const user = users[ticketOrder.userId] || null;
        const userContact = userContacts[ticketOrder.userId] || null;
        return {
          user: user,
          userContact: userContact,
          ticketOrder: ticketOrder,
          virtualStatus: getTicketOrderVirtualStatus(ticketOrder),
        };
      }) //
      .sort((detailedTicketOrder1, detailedTicketOrder2) =>
        statusSortOrder.indexOf(detailedTicketOrder1.virtualStatus) -
        statusSortOrder.indexOf(detailedTicketOrder2.virtualStatus),
      ),
    );
  }, [filteredTicketOrders, users, userContacts, statusSortOrder]);

  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
    filter: true,
    tooltipComponent: AgGridTableTooltip,
  }), []);

  // See https://www.ag-grid.com/javascript-data-grid/column-properties/
  const effectiveCols = React.useMemo(() => {
    if (!columns.length) {
      // not loaded yet
      return [];
    }

    let effectiveColumns = columns.map((col) => {
      const effectiveColumn = {
        ...col,
        tooltipField: col.field,
      };
      if (effectiveColumn.wrapText) {
        if (!enableMultiline) {
          effectiveColumn.wrapText = false;
        }
      }
      return effectiveColumn;
    }) as any[];
    effectiveColumns = [
    // Add a fake column on left for drag operations
      { field: '', rowDrag: true, lockPosition: 'left', width: 30 },
      ...effectiveColumns,
    ];
    effectiveColumns[1].headerCheckboxSelection = false;
    effectiveColumns[1].checkboxSelection = true;
    effectiveColumns[1].showDisabledCheckboxes = true;
    effectiveColumns[1].lockPosition = 'left';

    return effectiveColumns;
  }, [columns, enableMultiline]);

  const hasWrapText = !!columns.find((col) => col.wrapText);

  const isRowSelectable = React.useMemo(() => {
    return (params: any) => {
      return !!params.data && (!selectableStatuses || selectableStatuses.includes(params.data.status));
    };
  }, [selectableStatuses]);

  const handleSelectionChanged = React.useCallback(() => {
    const selectedData = agGridRef.current?.api.getSelectedRows();
    const newSelectedTicketOrders = (selectedData || []).map((data) => {
      const matchingDetailedTicketOrder =
        detailedTicketOrders!.find((detailedTicketOrder) => detailedTicketOrder.ticketOrder.id === data.id);
      if (!matchingDetailedTicketOrder) {
        throw new Error('BUG: no matching detailedTicketOrder found for ' + data.id);
      }
      return matchingDetailedTicketOrder;
    });

    // Compute selection summary
    const statusMap = newSelectedTicketOrders
      .map((selectedTicketOrder) => selectedTicketOrder.virtualStatus)
      .reduce((prevVal, status) => {
        if (prevVal[status]) {
          prevVal[status] += 1;
        } else {
          prevVal[status] = 1;
        }
        return prevVal;
      }, {} as Record<string, number>);
    const selectionSummary = Object.keys(statusMap).map((key) => `${key}: ${statusMap[key]}`).join(', ');
    setSelectionSummary(selectionSummary);

    onSelectedDetailedTicketOrdersChange(newSelectedTicketOrders);
  }, [detailedTicketOrders, onSelectedDetailedTicketOrdersChange]);

  const handleCellClicked = React.useCallback((e: any) => {
    if (!onShowRowDetails || e.column.colId !== '0') {
      return;
    }

    const matchingDetailedTicketOrder =
      detailedTicketOrders!.find((detailedTicketOrder) => detailedTicketOrder.ticketOrder.id === e.data.id);

    if (matchingDetailedTicketOrder && matchingDetailedTicketOrder.user) {
      onShowRowDetails(matchingDetailedTicketOrder.user, matchingDetailedTicketOrder.ticketOrder);
    }
  }, [onShowRowDetails, detailedTicketOrders]);

  const handleRefreshTicketOrders = React.useCallback(async () => {
    await dispatch(refreshTicketOrders({
      eventTemplateId: eventTemplateId, onlyIfUncached: false,
    }));
  }, [dispatch, eventTemplateId]);

  const handleCellEditingStopped = React.useCallback(() => {
    try {
      // automatically reset cell content, so it doesn't change
      agGridRef.current?.api.undoCellEditing();
    } catch (e) {
      // do nothing
    }
  }, []);

  if (detailedTicketOrders === null) {
    return <CircularProgress />;
  }

  const userData = detailedTicketOrders
    .map((detailedTicketOrder) => {
      const user = detailedTicketOrder.user;
      const userContact = detailedTicketOrder.userContact;
      const ticketOrder = detailedTicketOrder.ticketOrder;
      const virtualStatus = detailedTicketOrder.virtualStatus;
      if (!user || !userContact) {
        // This can happen because in staging, users can expire. This should never happen in prod
        return {
          status: detailedTicketOrder.virtualStatus,
          userId: ticketOrder.userId,
          name: 'MISSING',
        };
      }

      return transformRowFn(user, userContact, ticketOrder, virtualStatus);
    });

  const getStatusRowByGender = (gender: Gender | null) => {
    return (
      <StatusRow
        detailedTicketOrders={detailedTicketOrders}
        rowLabel={gender ? gender : 'total'}
        aggregationFn={(user, ticketOrder, virtualStatus) => {
          return [{
            key: virtualStatus.toString(),
            count: !!gender && user.userProfile.coreUserInfo.gender !== gender ? 0 : 1,
          }];
        }}
      />
    );
  };

  const getStatusRowByTicketCoupon = (appliedTicketOption: TicketOption | null) => {
    return (
      <StatusRow
        detailedTicketOrders={detailedTicketOrders}
        rowLabel={appliedTicketOption ? appliedTicketOption.name : 'total'}
        aggregationFn={(user, ticketOrder, virtualStatus) => {
          if (virtualStatus !== TicketOrderVirtualStatus.CONFIRMING &&
              virtualStatus !== TicketOrderVirtualStatus.CONFIRMED &&
              virtualStatus !== TicketOrderVirtualStatus.CHECKED_IN) {
            return [];
          }

          let total = 0;
          let none = 0;
          const costAdjustorIdToCount: Record<string, number> = {};
          for (const ticket of ticketOrder.tickets) {
            if (appliedTicketOption && ticket.ticketOptionId !== appliedTicketOption.id) {
              continue;
            }
            total += 1;

            const costAdjustors = ticketOrder.costDetails.ticketIdToCost[ticket.id].costAdjustors
              .filter((costAdjustor: CostAdjustor) => !!costAdjustor.adjustorId);
            if (!costAdjustors || costAdjustors.length === 0) {
              none += 1;
            } else {
              for (const costAdjustor of costAdjustors) {
                if (!costAdjustorIdToCount[costAdjustor.adjustorId]) {
                  costAdjustorIdToCount[costAdjustor.adjustorId] = 0;
                }
                costAdjustorIdToCount[costAdjustor.adjustorId] += 1;
              }
            }
          }

          const res = Object.entries(costAdjustorIdToCount).map(([costAdjustorId, count] )=> {
            return {
              key: costAdjustorId,
              count: count,
            };
          });
          res.push({
            key: 'none',
            count: none,
          });
          res.push({
            key: 'total',
            count: total,
          });

          return res;
        }}
      />
    );
  };

  const getStatusRowByTicketStatus = (appliedTicketOption: TicketOption | null) => {
    return (
      <StatusRow
        detailedTicketOrders={detailedTicketOrders}
        rowLabel={appliedTicketOption ? appliedTicketOption.name : 'total'}
        aggregationFn={(user, ticketOrder, virtualStatus) => {
          const matchingTicketCount = ticketOrder.tickets
            .filter((ticket) => !appliedTicketOption || ticket.ticketOptionId === appliedTicketOption.id)
            .length;

          return [{
            key: virtualStatus.toString(),
            count: matchingTicketCount,
          }];
        }}
      />
    );
  };

  return (
    <Box>
      {!hideTitle && (<Text size='banner' ml={16} mt={16}>{eventTemplateName}</Text>)}
      {topChildren}
      <Box mt={20}>
        <TextField
          variant='standard'
          label=''
          placeholder='Search by ticketOrderId or userId'
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
          sx={{ width: 350 }}
          InputProps={{
            startAdornment: <InputAdornment position='start'><SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} /></InputAdornment>,
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', mb: 8, justifyContent: 'flex-end' }}>
        {
          hasWrapText ? (
            <Button size='small' sx={{ mr: 20 }} onClick={() => setEnableMultiline((val) => !val)}>
              <Checkbox size='small' sx={{ pr: 5, mb: 3 }} checked={enableMultiline} />Multiline
            </Button>
          ) : <Box />
        }
        <Button
          size='small'
          startIcon={<DownloadOutlinedIcon />}
          sx={{ mr: 20 }}
          onClick={() => agGridRef.current?.api.exportDataAsCsv()}
        >
            Export
        </Button>
        <Button size='small' startIcon={<RefreshOutlinedIcon />} onClick={handleRefreshTicketOrders}>Refresh</Button>
      </Box>
      <Box className='ag-theme-alpine' sx={{ height: 500, mb: 10 }}>
        <AgGridReact
          ref={agGridRef}
          rowData={userData}
          columnDefs={effectiveCols} // Column Defs for Columns
          defaultColDef={defaultColDef}
          animateRows // Optional - set to 'true' to have rows animate when sorted
          suppressRowClickSelection
          rowSelection={disableMultiSelect ? 'single' :'multiple'}
          isRowSelectable={isRowSelectable}
          rowDragManaged
          onSelectionChanged={handleSelectionChanged}
          onCellClicked={handleCellClicked}
          undoRedoCellEditing
          onCellEditingStopped={handleCellEditingStopped}
        />
      </Box>
      {!!selectionSummary && (
        <Text ml={8} variant='italics' size='note'>Selected [{selectionSummary}]</Text>
      )}

      {children}

      <Text textAlign='end' size='note' variant='bold'>By User x Status</Text>
      {getStatusRowByGender(Gender.MALE)}
      {getStatusRowByGender(Gender.FEMALE)}
      {getStatusRowByGender(Gender.UNKNOWN)}
      {getStatusRowByGender(null)}

      <Text textAlign='end' size='note' variant='bold' mt={15}>By Ticket x Status</Text>
      {eventTemplate?.payload.hostedEventPayload.ticketOptions.map((ticketOption) => {
        return (
          <Box key={ticketOption.id}>
            {getStatusRowByTicketStatus(ticketOption)}
          </Box>
        );
      })}

      <Text textAlign='end' size='note' variant='bold' mt={15}>By (Confirmed) Ticket x Discount</Text>
      {eventTemplate?.payload.hostedEventPayload.ticketOptions.map((ticketOption) => {
        return (
          <Box key={ticketOption.id}>
            {getStatusRowByTicketCoupon(ticketOption)}
          </Box>
        );
      })}
    </Box>
  );
};

export default TicketOrdersTable;
