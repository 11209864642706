import * as Yup from 'yup';

import { EventContact, EventLocation, EventTemplate, EventTemplateGroup, GeoStateCode, TaroPassRecord, Vendor } from 'common/src/models/event';
import { EventFeePolicy } from 'common/src/models/event/common';
import { GroupedEventTemplate } from 'common/src/models/event/eventTemplateGroup';

export const eventIdSchema = Yup.string()
  .min(8, '活动ID >= 3字符') // account for prefix
  .max(30, '活动ID <= 30字符')
  .test('idValidation', '活动ID不符合要求', (val) => !!val && /^((202\d)|(_test))_[a-z][a-z0-9_]{2,29}$/.test(val))
  .required('请填写活动ID');

export const eventNameSchema = Yup.string()
  .min(5, '活动名 >= 5字符')
  .max(40, '活动名 <= 40字符')
  .required('请填写活动名');

export const optEventNameSchema = Yup.string()
  .max(40, '活动名 <= 40字符')
  .defined();

export const eventIntroSchema = Yup.string()
  .min(10, '活动简介 >= 10字符')
  .max(100, '活动简介 <= 100字符')
  .required('请填写活动简介');

export const eventDescriptionSchema = Yup.string()
  .min(50, '活动介绍 >= 50字符')
  .max(10000, '活动介绍 <= 10000字符')
  .required('请填写活动介绍');

export const eventMediaSchema = Yup.string().required('请添加活动图片');

const MIN_DATE = 946684800000; // 2000/01/01
const MAX_DATE = 2524608000000; // 2050/01/01

export const eventTsSchema: Yup.SchemaOf<{
    registrationFromTs: number;
    registrationToTs: number;
    eventFromTs: number;
    eventToTs: number;
  }> = Yup.object().shape({
    registrationFromTs: Yup.number().required().min(MIN_DATE, '报名时间太早').max(MAX_DATE, '报名时间太晚'),
    registrationToTs: Yup.number().required().min(MIN_DATE, '报名时间太早').max(MAX_DATE, '报名时间太晚'),
    eventFromTs: Yup.number().required().min(MIN_DATE, '活动时间太早').max(MAX_DATE, '活动时间太晚'),
    eventToTs: Yup.number().required().min(MIN_DATE, '活动时间太早').max(MAX_DATE, '活动时间太晚'),
  })
    .test('rangeValidation', '时间不符合要求', (val) => {
      const eventFromTs = val.eventFromTs || 0;
      const eventToTs = val.eventToTs || 0;
      const registrationFromTs = val.registrationFromTs || 0;
      const registrationToTs = val.registrationToTs || 0;

      return eventFromTs < eventToTs &&
      registrationFromTs < registrationToTs &&
      registrationToTs <= eventToTs;
    });

export const maxTotalParticipantsSchema = Yup.number()
  .required('请填写总共人数')
  .min(0, '总共人数 >= 0');

export const latlngSchema = Yup.string().required('请输入Latitude & Longitude').matches(/-?[0-9.]+,-?[0-9.]+/, 'Invalid Latitude & Longitude');

export const eventLocationStateSchema = Yup.string()
  .required('请输入州')
  .min(2, '州太短')
  .max(2, '州太长')
  .test('stateValidation', '州暂不支持', (val) => {
    return !!val && Object.values(GeoStateCode).includes(val.toLowerCase() as GeoStateCode);
  });
export const eventLocationSchema: Yup.SchemaOf<EventLocation> = Yup.object().shape({
  summary: Yup.string().defined(),
  address: Yup.string().required('请输入地址').min(5, '地址太短').max(70, '地址太长'),
  city: Yup.string().required('请输入城市').min(4, '城市太短').max(20, '城市太长'),
  state: eventLocationStateSchema,
  zipcode: Yup.string().required('请输入邮编').min(5, '邮编太短').max(6, '邮编太长'),
  latlng: latlngSchema,
  addressNotes: Yup.string().defined(),
});

export const eventCostInCentsSchema = Yup.number()
  .required('请填写活动价格')
  .min(0, '价格必须是正数')
  .max(300000, '价格太高');

export const cancellationHoursAfterPaidEventConfirmationSchema = Yup.number()
  .required()
  .max(72, '付费活动退款期限 < 72小时')
  .min(0, '退款期限不能早于注册时间');
export const cancellationHoursAfterFreeEventConfirmationSchema = Yup.number()
  .required()
  .max(8760, '免费活动退款期限 < 365天')
  .min(0, '退款期限不能早于注册时间');
export const cancellationHoursBeforeEventSchema = Yup.number()
  .required()
  .max(8760, '退款期限太长')
  .min(0, '退款期限不能早于注册时间');
export const urlSchema = Yup.string().url('不是URL格式');

export const waiverSchema = Yup.string().defined();

export const surveyQuestionSchema = Yup.string()
  .required('请填写问题')
  .min(5, '问题太短')
  .max(300, '问题太长');

export const surveyQuestionChoiceSchema = Yup.string()
  .required('请填写选项')
  .min(1, '选项太短')
  .max(100, '选项太长');

const ticketOptionSchemaPartial = {
  id: Yup.string().required(),
  name: Yup.string().required(),
  description: Yup.string().defined(),

  maxMaleParticipants: Yup.number().required(),
  maxFemaleParticipants: Yup.number().required(),
  maxGenericParticipants: Yup.number().required(),
  maxTotalParticipants: Yup.number().required(),

  maleCostInCents: Yup.number().required(),
  femaleCostInCents: Yup.number().required(),
  genericCostInCents: Yup.number().required(),

  maxQuantityPerUser: Yup.number().required(),

  addOns: Yup.array().of(Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
    description: Yup.string().required(),
    maxQuantity: Yup.number().required(),
    costAdjustmentInCents: Yup.number().required(),
  })).required(),

  allowWaitlist: Yup.bool().required(),
  registrationFromTs: Yup.number().required(),
  registrationToTs: Yup.number().required(),
  visibilitySetting: Yup.string().required(),
};

export const eventFeePolicySchema: Yup.SchemaOf<EventFeePolicy> = Yup.object().shape({
  taxPercentage: Yup.number().min(0, 'Tax Percentage必须 >= 0%').max(0.2, 'Tax Percentage必须 <= 20%').defined('请输入Tax Percentage'),
  feePercentage: Yup.number().min(0, 'Fee Percentage必须 >= 0%').max(0.5, 'Fee Percentage必须 <= 50%').defined('请输入Fee Percentage'),
  feeInCents: Yup.number().min(0, 'Fee必须 >= 0%').max(10000, 'Fee必须 <= $100').defined('请输入Fee'),
});

export const singlesEventMaxSelectableTicketOptionsSchema =
  Yup.number().min(1, 'Singles Event 最多可选票种类 == 1').max(1, 'Singles Event 最多可选票种类 == 1');
export const singlesEventMaxQuantityPerUserSchema =
  Yup.number().min(1, 'Singles Event 用户可购买数量 == 1').max(1, 'Singles Event 用户可购买数量 == 1');

export const couponCodeSchema = Yup.string().required('请输入折扣码').min(5, '折扣码太短').max(20, '折扣码太长')
  .test('couponCodeValidation', '折扣码不符合要求', (val) => !!val && /^[A-Z0-9-]*$/.test(val));
export const discountQualifierMinTicketsInOrderSchema =
  Yup.number().min(0, '用户需购买票数必须 >= 0').max(1000, '用户需购买票数必须 <= 1000').required();

export const discountValueUnitAdjustmentInCentsSchema =
  Yup.number().max(0, '金额调整必须 <= 0').required();
export const discountValuePercentAdjustmentSchema =
  Yup.number().min(-1.5, '%调整必须 >= -150%').max(0, '%调整必须 <= 0%').required();

const hostedEventSchemaPartial = {
  screeningType: Yup.mixed().required(),

  ticketOptions: Yup.array().of(Yup.object().shape(
    ticketOptionSchemaPartial,
  )),
  maxSelectableTicketOptions: Yup.number().required(),
  minSelectableTickets: Yup.number().required(),
  maxSelectableTickets: Yup.number().required(),
  allowMultipleTicketOrders: Yup.bool().required(),
  currency: Yup.mixed().defined(),

  surveyQuestions: Yup.array().of(Yup.object().shape({
    id: Yup.string().required(),
    type: Yup.mixed().required(),
    question: surveyQuestionSchema,
    choices: Yup.array().of(Yup.object().shape({
      id: Yup.string().required(),
      text: surveyQuestionChoiceSchema,
    })),
  })),
  detailsUrl: urlSchema.defined(),
  feePolicy: eventFeePolicySchema,
  contact: Yup.object().shape({
    name: Yup.string().defined(),
    type: Yup.mixed().required(),
    principal: Yup.string().defined(),
    refMedia: Yup.object().shape({
      id: Yup.string().defined(),
      downloadUrl: Yup.string().defined(),
    }),
  }),

  registrationFromTs: Yup.number().required(),
  registrationToTs: Yup.number().required(),
  cancellationHoursBeforeEvent: cancellationHoursBeforeEventSchema,
  // cancellationHoursAfterEventConfirmation defined later
  cancellationHoursAfterEventConfirmation: Yup.number().required(),
  waiver: Yup.string().defined(),
  vendorId: Yup.string().defined(),
  visibilitySetting: Yup.object().shape({
    visibleInApp: Yup.bool().defined(),
    visibleOnWeb: Yup.bool().defined(),
  }),
  discounts: Yup.array().of(Yup.object().shape({
    couponCode: couponCodeSchema,
    automatic: Yup.bool().required(),
    discountQualifier: Yup.object().shape({
      ticketOptionIds: Yup.array().of(Yup.string().required()),
      clientTypes: Yup.array().of(Yup.string().required()),
      minTicketsInOrder: discountQualifierMinTicketsInOrderSchema,
    }),
    discountValue: Yup.object().shape({
      unitAdjustmentInCents: discountValueUnitAdjustmentInCentsSchema,
      percentAdjustment: discountValuePercentAdjustmentSchema,
      adjustedUnitCostInCents: Yup.number().required(),
    }),
  })),
};

export const eventContactSchema: Yup.SchemaOf<EventContact> = Yup.object().shape({
  name: Yup.string().required('请输入联系人').min(2, '联系人太短').max(30, '联系人太长'),
  type: Yup.mixed().required('请输入联系方式类型'),
  principal: Yup.string().required('请输入联系方式').min(3, '联系方式太短').max(30, '联系方式太长'),
  refMedia: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }),
});

export const eventTagsSchema: Yup.SchemaOf<string[]> =
  Yup.array().of(Yup.string().required()).max(5, 'Tag <= 5个');

export const additionalAdvertisedStatesSchema: Yup.SchemaOf<string[]> =
  Yup.array().of(Yup.string().required());

export const eventTemplateSchema: Yup.SchemaOf<EventTemplate> = Yup.object().shape({
  id: eventIdSchema,
  published: Yup.bool().required(),
  archived: Yup.bool().required(),
  visible: Yup.bool().required(),

  singlesEvent: Yup.bool().required(),
  category: Yup.mixed().required(),
  name: eventNameSchema,
  intro: Yup.string().when('published', { is: true, then: eventIntroSchema }).defined(),
  description: Yup.string().when('published', { is: true, then: eventDescriptionSchema }).defined(),
  media: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }).when('published', { is: true, then: Yup.object().shape({
    id: eventMediaSchema,
    downloadUrl: eventMediaSchema,
  }) }),
  thumbnailMedia: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }),
  extraMedia: Yup.array().of(Yup.object().shape({
    id: eventMediaSchema,
    downloadUrl: eventMediaSchema,
  })),
  extraThumbnailMedia: Yup.array().of(Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  })),
  tags: eventTagsSchema,

  eventFromTs: Yup.number().required(),
  eventToTs: Yup.number().required(),
  eventTimeZone: Yup.string().required(),
  location: Yup.object().shape({
    summary: Yup.string().defined(),
    address: Yup.string().defined(),
    city: Yup.string().defined(),
    state: eventLocationStateSchema,
    zipcode: Yup.string().defined(),
    latlng: Yup.string().defined(),
    addressNotes: Yup.string().defined(),
  }).when('published', { is: true, then: eventLocationSchema }),
  additionalAdvertisedStates: additionalAdvertisedStatesSchema,
  seatMapId: Yup.string().defined(),

  payload: Yup.object().shape({
    hostedEventPayload: Yup.object().shape({
      ...hostedEventSchemaPartial,
    }),
  }).when('published', { is: true, then: Yup.object().shape({
    hostedEventPayload: Yup.object().shape({
      ...hostedEventSchemaPartial,

      ticketOptions: Yup.array().of(Yup.object().shape(
        {
          ...ticketOptionSchemaPartial,
          maxTotalParticipants: maxTotalParticipantsSchema,
          maleCostInCents: eventCostInCentsSchema,
          femaleCostInCents: eventCostInCentsSchema,
        },
      )),

      contact: eventContactSchema,
    }),
  }).when('singlesEvent', { is: true, then: Yup.object().shape({
    hostedEventPayload: Yup.object().shape({
      ...hostedEventSchemaPartial,

      ticketOptions: Yup.array().of(Yup.object().shape(
        {
          ...ticketOptionSchemaPartial,
          maxTotalParticipants: maxTotalParticipantsSchema,
          maleCostInCents: eventCostInCentsSchema,
          femaleCostInCents: eventCostInCentsSchema,
          maxQuantityPerUser: singlesEventMaxQuantityPerUserSchema,
        },
      )),
      maxSelectableTicketOptions: singlesEventMaxSelectableTicketOptionsSchema,

      contact: eventContactSchema,
    }),
  }) })
    .test('cancellationHoursAfterEventConfirmation', '活动退款期限不符合要求', (val) => {
      let isFree = true;
      if (val.hostedEventPayload.ticketOptions) {
        for (const ticketOption of val.hostedEventPayload.ticketOptions) {
          if (val.singlesEvent && (ticketOption.maleCostInCents || ticketOption.femaleCostInCents) ||
            !val.singlesEvent && ticketOption.genericCostInCents) {
            isFree = false;
            break;
          }
        }
      }

      const schema = isFree ? cancellationHoursAfterFreeEventConfirmationSchema : cancellationHoursAfterPaidEventConfirmationSchema;
      return schema.isValidSync(val.hostedEventPayload.cancellationHoursAfterEventConfirmation);
    }),
  }),

  createdTs: Yup.number().required(),
})
  .test('tsValidation', '时间不符合要求', (val) => {
    return !val.published || eventTsSchema.isValidSync({
      registrationFromTs: val.payload.hostedEventPayload.registrationFromTs,
      registrationToTs: val.payload.hostedEventPayload.registrationToTs,
      eventFromTs: val.eventFromTs,
      eventToTs: val.eventToTs,
    });
  });

export const groupedEventTemplatesSchema: Yup.SchemaOf<GroupedEventTemplate[]> = Yup.array().of(Yup.object().shape({
  eventTemplateId: Yup.string().required(),
  name: optEventNameSchema,
}))
  .defined()
  .test('duplicateEvents', '不可重复使用活动', (groupedEventTemplates) => {
    if (!groupedEventTemplates) {
      return true;
    }
    const eventTemplateIds = groupedEventTemplates.map((groupedEventTemplate) => groupedEventTemplate.eventTemplateId);
    return eventTemplateIds.length === new Set(eventTemplateIds).size;
  });


export const eventTemplateGroupSchema: Yup.SchemaOf<EventTemplateGroup> = Yup.object().shape({
  id: eventIdSchema,
  published: Yup.bool().required(),
  archived: Yup.bool().required(),
  visible: Yup.bool().required(),

  name: eventNameSchema,
  intro: Yup.string().when('published', { is: true, then: eventIntroSchema }).defined(),
  description: Yup.string().when('published', { is: true, then: eventDescriptionSchema }).defined(),
  media: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }),
  thumbnailMedia: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }),
  extraMedia: Yup.array().of(Yup.object().shape({
    id: eventMediaSchema,
    downloadUrl: eventMediaSchema,
  })),
  extraThumbnailMedia: Yup.array().of(Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  })),
  groupedEventTemplates: groupedEventTemplatesSchema.when('published',
    { is: true, then: groupedEventTemplatesSchema.min(1, '请添加至少一个活动') }),
  showGroupOnly: Yup.bool().required(),
  detailsUrl: urlSchema.defined(),
  visibilitySetting: Yup.object().shape({
    visibleInApp: Yup.bool().defined(),
    visibleOnWeb: Yup.bool().defined(),
  }),
  createdTs: Yup.number().required(),
});

// #### TaroPass #### //

export const taroPassIdSchema = Yup.string()
  .min(3, 'ID >= 3字符')
  .max(30, 'ID <= 30字符')
  .test('idValidation', 'ID不符合要求', (val) => !!val && /^[a-z][a-z0-9_]{2,29}$/.test(val))
  .required('请填写ID');

export const taroPassNameSchema = Yup.string()
  .min(2, '商家名 >= 2字符')
  .max(30, '商家名 <= 30字符')
  .required('请填写商家名');

export const taroPassCategorySchema = Yup.mixed()
  .test('categoryValidation', '请选择合适的Category', (val) => {
    const categoryStrLen = (val as string).length;
    return categoryStrLen >= 2 && categoryStrLen <= 5;
  })
  .required('请填写Category');

export const taroPassIntroSchema = Yup.string()
  .min(20, '商家介绍 >= 20字符')
  .max(500, '商家介绍 <= 500字符')
  .required('请填写商家介绍');

export const taroPassDetailSchema = Yup.string()
  .min(3, '折扣细节 >= 3字符')
  .max(50, '折扣细节 <= 50字符')
  .required('请填写折扣细节');

export const taroPassMediaSchema = Yup.string().required('请添加TaroPass图片');

export const taroPassTsSchema: Yup.SchemaOf<{
  createdTs: number;
  expiryTs: number;
}> = Yup.object().shape({
  createdTs: Yup.number().required().min(MIN_DATE, 'Created时间太早').max(MAX_DATE, 'Created时间太晚'),
  expiryTs: Yup.number().required().min(MIN_DATE, 'Expiry时间太早').max(MAX_DATE, 'Expiry时间太晚'),
})
  .test('rangeValidation', '时间不符合要求', (val) => {
    const createdTs = val.createdTs || 0;
    const expiryTs = val.expiryTs || 0;

    return createdTs < expiryTs;
  });

export const taroPassSchema: Yup.SchemaOf<TaroPassRecord> = Yup.object().shape({
  id: taroPassIdSchema,
  name: taroPassNameSchema,
  published: Yup.bool().required(),
  category: Yup.mixed().when('published', { is: true, then: taroPassCategorySchema }).defined(),
  intro: Yup.string().when('published', { is: true, then: taroPassIntroSchema }).defined(),
  detail: Yup.string().when('published', { is: true, then: taroPassDetailSchema }).defined(),
  contact: eventContactSchema,
  location: Yup.object().shape({
    summary: Yup.string().defined(),
    address: Yup.string().defined(),
    city: Yup.string().defined(),
    state: eventLocationStateSchema,
    zipcode: Yup.string().defined(),
    latlng: Yup.string().defined(),
    addressNotes: Yup.string().defined(),
  }).when('published', { is: true, then: eventLocationSchema }),
  refMedia: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }).when('published', { is: true, then: Yup.object().shape({
    id: taroPassMediaSchema,
    downloadUrl: taroPassMediaSchema,
  }) }),
  createdTs: Yup.number().required(),
  expiryTs: Yup.number().required(),
})
  .test('tsValidation', '时间不符合要求', (val) => {
    return !val.published || taroPassTsSchema.isValidSync({
      createdTs: val.createdTs,
      expiryTs: val.expiryTs,
    });
  });

// #### Vendor #### //

export const vendorIdSchema = Yup.string()
  .min(3, 'ID >= 3字符')
  .max(30, 'ID <= 30字符')
  .test('idValidation', 'ID不符合要求', (val) => !!val && /^[a-z][a-z0-9_]{2,29}$/.test(val))
  .required('请填写ID');

export const vendorNameSchema = Yup.string()
  .min(2, '商家名 >= 2字符')
  .max(30, '商家名 <= 30字符')
  .required('请填写商家名');

export const vendorDescriptionSchema = Yup.string()
  .min(20, '商家介绍 >= 20字符')
  .max(500, '商家介绍 <= 500字符')
  .required('请填写商家介绍');

export const vendorMediaSchema = Yup.string().required('请添加Vendor图片');

export const vendorSchema: Yup.SchemaOf<Vendor> = Yup.object().shape({
  id: vendorIdSchema,
  name: vendorNameSchema,
  description: vendorDescriptionSchema,
  refMedia: Yup.object().shape({
    id: Yup.string().defined(),
    downloadUrl: Yup.string().defined(),
  }),
  createdTs: Yup.number().required(),
});
