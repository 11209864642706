import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Page, Text } from 'common/src/components/base';
import { EventTemplateSelector } from '../../../components/event';

export default function TicketOrderSelectorScreen() {
  const navigate = useNavigate();

  const handleSelectEventTemplate = React.useCallback((eventTemplateId: string) => {
    navigate(`/ticketOrders/${eventTemplateId}`);
  }, [navigate]);

  return (
    <Page sx={{ mt: 16 }}>
      <Box sx={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', display: 'grid' }}>
        <Text size='banner' mb={15}>Ticket Orders</Text>
        <EventTemplateSelector onSelectEventTemplate={handleSelectEventTemplate} />
      </Box>
    </Page>
  );
}
