import React from 'react';
import { PhotoSlider } from 'react-photo-view';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import { Box, IconButton } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';

import { TaroPassRecord } from 'common/src/models/event';
import { getHumanReadableDateTime } from 'common/src/utils/time';

import { AgGridTableTooltip } from '../../../components/base';

interface IProps {
  taroPasses: TaroPassRecord[] | null;
  onEdit: (taroPassId: string) => void;
}
const TaroPassViewer: React.FC<IProps> = ({ taroPasses, onEdit }) => {
  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
    filter: true,
    tooltipComponent: AgGridTableTooltip,
  }), []);

  const columnDefs = [
    { field: 'id' },
    { field: 'name' },
    { field: 'category', width: 115 },
    { field: 'intro', wrapText: true, width: 400 },
    { field: 'detail', wrapText: true, width: 400 },
    { field: 'location', width: 250 },
    { field: 'contact', width: 250 },
    { field: 'createTs', headerName: 'Created Time' },
    { field: 'expiryTs', headerName: 'Expiry Time' },
  ];
  let effectiveColumns = columnDefs.map((col) => {
    const effectiveColumn = {
      ...col,
      tooltipField: col.field,
    };
    return effectiveColumn;
  }) as any[];
  effectiveColumns = [
    // Add a fake column on left for drag operations
    { field: '', rowDrag: true, editable: false, lockPosition: 'left', width: 130,
      cellRenderer: FirstCellRenderer,
      cellRendererParams: {
        onEdit: onEdit,
        onViewImage: (taroPassId: string) => {
          if (!taroPasses) {
            return;
          }
          const matchingTaroPass = taroPasses.find((taroPass) => taroPass.id === taroPassId);
          if (!matchingTaroPass) {
            return;
          }
          setShownRowDetails(matchingTaroPass);
        },
      },
    },
    ...effectiveColumns,
  ];
  effectiveColumns[1].lockPosition = 'left';

  const agGridRef = React.useRef<AgGridReactType>(null);

  const [shownRowDetails, setShownRowDetails] = React.useState<TaroPassRecord | null>(null);
  const rowDetailsModal = !!shownRowDetails && (
    <PhotoSlider
      images={[shownRowDetails.refMedia].map((img)=>
        ({ src: img.downloadUrl, key: img.id }))}
      visible
      onClose={() => setShownRowDetails(null)}
    />
  );

  const taroPassData = React.useMemo(() => {
    if (taroPasses === null) {
      return null;
    }

    return taroPasses.map((taroPass) => {
      let addressStr = '';
      if (taroPass.location.address) {
        addressStr += (taroPass.location.address + ', ');
      }
      if (taroPass.location.city) {
        addressStr += (taroPass.location.city + ' ');
      }
      if (taroPass.location.state) {
        addressStr += (taroPass.location.state + ', ');
      }
      if (taroPass.location.zipcode) {
        addressStr += (taroPass.location.zipcode + ' ');
      }
      if (taroPass.location.addressNotes) {
        if (addressStr) {
          addressStr += ('(' + taroPass.location.addressNotes + ')');
        } else {
          addressStr = taroPass.location.addressNotes;
        }
      }

      return {
        id: taroPass.id,
        name: taroPass.name,
        category: taroPass.category,
        intro: taroPass.intro,
        detail: taroPass.detail,
        location: addressStr,
        createTs: getHumanReadableDateTime(taroPass.createdTs, 'local'),
        expiryTs: getHumanReadableDateTime(taroPass.expiryTs, 'local'),
      };
    });
  }, [taroPasses]);

  if (taroPassData === null) {
    return null;
  }

  return (
    <Box>
      <Box className='ag-theme-alpine' style={{ height: 500 }}>
        <AgGridReact
          ref={agGridRef}
          rowData={taroPassData}
          columnDefs={effectiveColumns}
          defaultColDef={defaultColDef}
          animateRows
          rowDragManaged
          undoRedoCellEditing
          onCellEditingStopped={() => {
            try {
              // automatically reset cell content, so it doesn't change
              agGridRef.current?.api.undoCellEditing();
            } catch (e) {
              // do nothing
            }
          }}
        />
      </Box>
      {rowDetailsModal}
    </Box>
  );
};

const FirstCellRenderer = (props: any) => {
  return (
    <Box>
      <IconButton onClick={() => props.onEdit(props.data.id)}>
        <EditIcon sx={{ fontSize: 18 }}></EditIcon>
      </IconButton>
      <IconButton onClick={() => props.onViewImage(props.data.id)}>
        <ImageIcon sx={{ fontSize: 18 }}></ImageIcon>
      </IconButton>
    </Box>
  );
};

export default TaroPassViewer;
