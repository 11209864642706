import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Button, Container } from '@mui/material';

import SessionAccessor from 'common/src/system/session';
import { useEventTemplate } from '../../../hooks/useResource';

import { Page } from 'common/src/components/base';
import { CheckInTable } from '../../../components/event';

export default function CheckInScreen() {
  const { eventTemplateId } = useParams();
  const navigate = useNavigate();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const accessToken = query.get('token');

  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for CheckInScreen');
  }

  React.useEffect(() => {
    if (accessToken) {
      SessionAccessor.setStandaloneSessionToken(accessToken);
    } else if (!SessionAccessor.getStandaloneSessionToken()) {
      navigate('/error');
    }
  }, [accessToken, navigate]);

  const eventTemplate = useEventTemplate(eventTemplateId);
  if (!eventTemplate) {
    return null;
  }

  return (
    <Page>
      <Container>
        <CheckInTable eventTemplateId={eventTemplateId} showSensitiveData={false}>
          <Button
            variant='contained'
            onClick={() => navigate(`/checkIn/scanner?eventTemplateId=${eventTemplateId}&token=${accessToken}`)}
            sx={{ mt: 10, ml: 20 }}
            startIcon={<QrCodeScannerIcon />}
            color='primary'
          >
            Checkin Scanner
          </Button>
        </CheckInTable>
      </Container>
    </Page>
  );
}
