import { Page, Text } from 'common/src/components/base';

export default function AccessDeniedScreen() {
  return (
    <Page sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mt: 60,
    }}>
      <img src={require('../../assets/images/access-denied.png')} width='600'/>
      <Text fontSize={30} mb={24}>You do not have access to this page</Text>
    </Page>
  );
}
