import {
  EditCalendarOutlined, FunctionsOutlined, HandshakeOutlined, LocalActivityOutlined,
  StorefrontOutlined, TableViewOutlined,
} from '@mui/icons-material';

import { UserRole } from 'common/src/models/user';

import EventTemplateSelectorScreen from '../screens/events/eventTemplate/EventTemplateSelectorScreen';
import EventTemplateGroupSelectorScreen from '../screens/events/eventTemplateGroup/EventTemplateGroupSelectorScreen';
import ReportScreen from '../screens/events/report/ReportScreen';
import TaroPassSelectorScreen from '../screens/events/taroPass/TaroPassSelectorScreen';
import TicketOrderSelectorScreen from '../screens/events/ticketOrder/TicketOrderSelectorScreen';
import VendorSelectorScreen from '../screens/events/vendor/VendorSelectorScreen';

const centralScreens = [
  {
    name: 'Tickets',
    path: '/ticketOrders',
    element: <TicketOrderSelectorScreen />,
    icon: <LocalActivityOutlined />,
    allowedUserRoles: [UserRole.ADMIN],
  }, {
    name: 'Manage Event Template',
    path: '/eventTemplate',
    element: <EventTemplateSelectorScreen />,
    icon: <EditCalendarOutlined />,
    allowedUserRoles: [UserRole.ADMIN, UserRole.LOGISTICS_MANAGER],
  }, {
    name: 'Manage Event Template Group',
    path: '/eventTemplateGroup',
    element: <EventTemplateGroupSelectorScreen />,
    icon: <TableViewOutlined />,
    allowedUserRoles: [UserRole.ADMIN, UserRole.LOGISTICS_MANAGER],
  }, {
    name: 'Manage Taro Pass',
    path: '/taroPass',
    element: <TaroPassSelectorScreen />,
    icon: <HandshakeOutlined />,
    allowedUserRoles: [UserRole.ADMIN, UserRole.LOGISTICS_MANAGER],
  }, {
    name: 'Manage Vendor',
    path: '/vendor',
    element: <VendorSelectorScreen />,
    icon: <StorefrontOutlined />,
    allowedUserRoles: [UserRole.ADMIN, UserRole.LOGISTICS_MANAGER],
  }, {
    name: 'Report',
    path: '/report',
    element: <ReportScreen />,
    icon: <FunctionsOutlined />,
    allowedUserRoles: [UserRole.ADMIN],
  },
];

export default centralScreens;
