import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GeoStateCode } from 'common/src/models/event';

import { RootState } from '../store';

// ### State ###

interface SettingState {
  stateFilter: GeoStateCode | null;
}

const initialState: SettingState = {
  stateFilter: null,
};


// ### Actions ### //


// ### Slice ###

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setStateFilter: (state, action: PayloadAction<GeoStateCode | null>) => {
      state.stateFilter = action.payload;
    },
  },
});


// ### Selectors ### //

export const selectStateFilter = (state: RootState): GeoStateCode | null => {
  return state.setting.stateFilter;
};


// ### Exports ### //

export const { setStateFilter } = settingSlice.actions;
export default settingSlice.reducer;
