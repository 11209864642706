import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, ButtonBase, Grid, Paper } from '@mui/material';

import { AuthContext } from 'common/src/context/auth';
import { centralScreens } from '../navigation';

import { Page, Text } from 'common/src/components/base';

export default function HomeScreen() {
  const navigate = useNavigate();
  const { userRole } = React.useContext(AuthContext);

  return (
    <Page sx={{
      mt: 64,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Text size='banner' mb={32}>Select Your Action</Text>
      <Grid container justifyContent='center'>
        {centralScreens
          .filter((centralScreen) => centralScreen.allowedUserRoles.includes(userRole))
          .map((centralScreen) => (
            <Grid item key={centralScreen.path} sm={5}>
              <ButtonBase sx={{ my: 16, marginX: '10%', width: '80%' }}>
                <Paper
                  sx={{
                    typography: 'body2',
                    padding: 8,
                    color: 'text.secondary',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    navigate(centralScreen.path);
                  }}
                >
                  <Avatar sx={{ m: 8, bgcolor: 'primary.light' }}>
                    {centralScreen.icon}
                  </Avatar>
                  {centralScreen.name}
                </Paper>
              </ButtonBase>
            </Grid>
          ))}
      </Grid>
    </Page>
  );
}
