import React from 'react';
import { PhotoProvider } from 'react-photo-view';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

import { DefaultTheme } from 'common/src/constants';
import AuthProvider, { AuthContext } from 'common/src/context/auth';
import NotifierProvider from 'common/src/context/notifier';
import { suppressConsoleError } from 'common/src/utils/suppressError';
import { RootNavigator } from './navigation';
import store from './redux/store';

import AppLoader from 'common/src/components/AppLoader';
import Notifier from 'common/src/components/notifier';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'react-photo-view/dist/react-photo-view.css';
import './global.css';

// This is due to bug in react 17. Will be addressed in react 18.
// See https://github.com/ag-grid/ag-grid/issues/5258
suppressConsoleError((m) => m.startsWith('Warning: Invalid aria prop'));

// BootstrappedApp takes care of all bootstrapping (e.g. auth, wss connection, etc) needed by the app.
function BootstrappedApp() {
  const { authStatus, userRole } = React.useContext(AuthContext);

  const loggedIn = authStatus === 'loggedIn';
  const showApp = loggedIn || authStatus === 'loggedOut';

  // Root is required for certain native-base components like Toast.
  return (
    <Notifier>
      <AppLoader showApp={showApp}>
        <RootNavigator loggedIn={loggedIn} userRole={userRole} />
      </AppLoader>
    </Notifier>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <AuthProvider>
        <ReduxProvider store={store}>
          <PhotoProvider>
            <NotifierProvider>
              <BootstrappedApp />
            </NotifierProvider>
          </PhotoProvider>
        </ReduxProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
