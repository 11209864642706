import React from 'react';
import { PhotoSlider } from 'react-photo-view';
import {
  EventBusyOutlined as EventBusyOutlinedIcon,
  PersonSearchOutlined as PersonSearchOutlinedIcon,
  SendOutlined as SendOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import {
  CostAdjustor, EventTemplate, getSelectedTicketOptions, QuestionType, TicketOrder,
  TicketOrderVirtualStatus,
} from 'common/src/models/event';
import { PredefinedProfileStoryTitle, ProfileStoryType, User, UserContact } from 'common/src/models/user';
import { getAge, getHumanReadableDateTime } from 'common/src/utils/time';

import { TicketOrdersTable } from '../../../../../components/event';
import CancelModal from './CancelModal';
import ScreenModal from './ScreenModal';
import SendSmsModal from './SendSmsModal';

interface IProps {
  eventTemplate: EventTemplate;
}

enum ActionModalType {
  SCREEN = 'screen',
  CANCEL = 'cancel',
  SEND_SMS = 'send_sms',
}

const OrdersView: React.FC<IProps> = ({
  eventTemplate,
}) => {
  const [selectedDetailedTicketOrders, setSelectedDetailedTicketOrders] =
    React.useState<{ user: User | null, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus }[]>([]);
  const [photoSliderImages, setPhotoSliderImages] =
    React.useState<{src: string, key: string}[]>([]);

  const [actionModalType, setActionModalType] = React.useState<ActionModalType | null>(null);

  const columns = React.useMemo(() => {
    return [
      { field: 'status', width: 160 },
      { field: 'selectedTicketOptions', width: 150 },
      { field: 'registeredTs', width: 150, valueFormatter: (data: any) => {
        const registrationTs = data.value;
        return registrationTs && eventTemplate ?
          getHumanReadableDateTime(registrationTs, eventTemplate.eventTimeZone) : '-';
      } },
      { field: 'id', width: 130 },
      { field: 'userId', width: 90 },
      { field: 'name', width: 150 },
      { field: 'gender', width: 105 },
      { field: 'age', width: 80 },
      { field: 'height', width: 100 },
      ...eventTemplate.payload.hostedEventPayload.surveyQuestions.map((surveyQuestion) => ({
        field: surveyQuestion.id,
        headerName: surveyQuestion.question,
      })),
      { field: 'intro', wrapText: true, autoHeight: true, flex: 1, minWidth: 200 },
      { field: 'interests', wrapText: true, autoHeight: true, flex: 1, minWidth: 200 },
      { field: 'idealMatch', wrapText: true, autoHeight: true, flex: 1, minWidth: 200 },
      { field: 'location', width: 150 },
      { field: 'discount', width: 150 },
      { field: 'tracking', width: 150 },
    ];
  }, [eventTemplate]);

  const transformRowFn = React.useCallback((
    user: User, userContact: UserContact, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus,
  ) => {
    const userProfile = user.userProfile;
    const coreUserInfo = userProfile.coreUserInfo;
    const surveyResponses: Record<string, any> = {};
    ticketOrder.surveyResponses.forEach((surveyResponse) => {
      const matchingSurveyQuestion = eventTemplate.payload.hostedEventPayload.surveyQuestions
        .find((surveyQuestion) => surveyQuestion.id === surveyResponse.id);
      if (!matchingSurveyQuestion) {
        return;
      }

      switch (matchingSurveyQuestion.type) {
        case QuestionType.FREEFORM:
          surveyResponses[matchingSurveyQuestion.id] = surveyResponse.response;
          break;
        case QuestionType.SELECT: {
          const matchingChoice = matchingSurveyQuestion.choices
            .find((choice) => choice.id === surveyResponse.response);

          if (matchingChoice) {
            surveyResponses[matchingSurveyQuestion.id] = matchingChoice.text;
          }
          break;
        }
        default:
          break;
      }
    });

    const ticketOption = getSelectedTicketOptions(ticketOrder).map((selectedTicketOption) => {
      const ticket = eventTemplate.payload.hostedEventPayload.ticketOptions
        .find((ticketOption) => ticketOption.id === selectedTicketOption.id);
      return ticket?.name + ' x ' + selectedTicketOption.quantity;
    }).join(', ');

    const costAdjustorIds = Object.values(ticketOrder.costDetails.ticketIdToCost)
      .flatMap((ticketCost) => ticketCost.costAdjustors)
      .filter((costAdjustor: CostAdjustor) => !!costAdjustor.adjustorId)
      .map((costAdjustor: CostAdjustor) => costAdjustor.adjustorId);
    const deduppedCostAdjustorIds= Array.from(new Set(costAdjustorIds)).join(', ');

    return {
      status: virtualStatus,
      selectedTicketOptions: ticketOption,
      id: ticketOrder.id,
      userId: user.id,
      registeredTs: ticketOrder.registrationTs,
      name: coreUserInfo.name,
      gender: coreUserInfo.gender,
      age: getAge(coreUserInfo.dob),
      height: coreUserInfo.height + 'cm',
      ...surveyResponses,
      intro: userProfile.profileStories.find(
        (profileStory) => profileStory.type === ProfileStoryType.PREDEFINED &&
          profileStory.title === PredefinedProfileStoryTitle.INTRO,
      )?.text,
      interests: userProfile.profileStories.find(
        (profileStory) => profileStory.type === ProfileStoryType.PREDEFINED &&
          profileStory.title === PredefinedProfileStoryTitle.INTERESTS,
      )?.text,
      idealMatch: userProfile.profileStories.find(
        (profileStory) => profileStory.type === ProfileStoryType.PREDEFINED &&
          profileStory.title === PredefinedProfileStoryTitle.IDEAL_MATCH,
      )?.text,
      location: coreUserInfo.location.state + ' - ' + coreUserInfo.location.city,
      discount: deduppedCostAdjustorIds,
      tracking: ticketOrder.utm,
    };
  }, [eventTemplate]);

  const handleShowPhotos = React.useCallback(
    (user: User, _unused: TicketOrder) => {
      const refs = [...user.userMedia.profileImages, ...user.userMedia.selfImages]
        .map((img) => ({ src: img.ref.downloadUrl, key: img.ref.id }));
      setPhotoSliderImages(refs);
    },
    []);

  const hasOnlyStatuses = (statuses: TicketOrderVirtualStatus[])=>{
    return selectedDetailedTicketOrders
      .every((detailedTicketOrder) => {
        return statuses.includes(detailedTicketOrder.virtualStatus);
      });
  };

  const actionBtns = (
    <Box>
      <Button
        startIcon={<PersonSearchOutlinedIcon />}
        disabled={!selectedDetailedTicketOrders.length || !hasOnlyStatuses([TicketOrderVirtualStatus.PENDING, TicketOrderVirtualStatus.WAITLIST])}
        onClick={() => setActionModalType(ActionModalType.SCREEN)}
      >
        Screen
      </Button>
      <Button
        startIcon={<EventBusyOutlinedIcon />}
        disabled={!selectedDetailedTicketOrders.length || !hasOnlyStatuses([
          TicketOrderVirtualStatus.PENDING,
          TicketOrderVirtualStatus.WAITLIST,
          TicketOrderVirtualStatus.CONFIRMING,
          TicketOrderVirtualStatus.CONFIRMED,
          TicketOrderVirtualStatus.ERROR,
          TicketOrderVirtualStatus.CHECKED_IN,
        ])}
        onClick={() => setActionModalType(ActionModalType.CANCEL)}
      >
        Cancel
      </Button>
      <Button
        startIcon={<SendOutlinedIcon />}
        disabled={!selectedDetailedTicketOrders.length}
        onClick={() => setActionModalType(ActionModalType.SEND_SMS)}
      >
        Send SMS
      </Button>
    </Box>
  );

  let actionModal;
  if (actionModalType) {
    switch (actionModalType) {
      case ActionModalType.SCREEN:
        actionModal = (
          <ScreenModal
            selectedDetailedTicketOrders={selectedDetailedTicketOrders}
            onClose={() => setActionModalType(null)}
          />
        );
        break;
      case ActionModalType.CANCEL:
        actionModal = (
          <CancelModal
            eventTemplate={eventTemplate}
            selectedDetailedTicketOrders={selectedDetailedTicketOrders}
            onClose={() => setActionModalType(null)}
          />
        );
        break;
      case ActionModalType.SEND_SMS:
        actionModal = (
          <SendSmsModal
            selectedDetailedTicketOrders={selectedDetailedTicketOrders}
            onClose={() => setActionModalType(null)}
          />
        );
        break;
      default:
        throw new Error('Invalid action modal type');
    }
  }

  const photosModal = !!photoSliderImages.length && (
    <PhotoSlider
      images={photoSliderImages}
      visible
      onClose={() => setPhotoSliderImages([])}
    />
  );

  return (
    <Box>
      <TicketOrdersTable
        eventTemplateId={eventTemplate.id}
        columns={columns}
        transformRowFn={transformRowFn}
        statusSortOrder={statusSortOrder}
        hideTitle
        onSelectedDetailedTicketOrdersChange={setSelectedDetailedTicketOrders}
        onShowRowDetails={handleShowPhotos}
      >
        {actionBtns}
      </TicketOrdersTable>
      {photosModal}
      {actionModal}
    </Box>
  );
};

const statusSortOrder = [
  TicketOrderVirtualStatus.PENDING,
  TicketOrderVirtualStatus.WAITLIST,
  TicketOrderVirtualStatus.CONFIRMING,
  TicketOrderVirtualStatus.CONFIRMED,
  TicketOrderVirtualStatus.ERROR,
  TicketOrderVirtualStatus.CHECKED_IN,
  TicketOrderVirtualStatus.CANCELLED,
  TicketOrderVirtualStatus.INIT,
];


export default OrdersView;
