import React from 'react';
import {
  Cancel as CancelIcon, Image as ImageIcon,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';

import { Colors } from 'common/src/constants';
import { EventContact, EventContactType } from 'common/src/models/event';
import { ResourceRef } from 'common/src/models/shared';
import { eventContactSchema } from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';

interface IProps {
  contact: EventContact;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  onChangeContact: (newContact: EventContact) => void;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const ContactEditor: React.FC<IProps> = ({
  contact, readOnlyMode, showValidationErr, onChangeContact, onUploadImg,
}) => {
  const [uploadingImg, setUploadingImg] = React.useState(false);

  return (
    <Box display='flex' alignItems='center' my={16}>
      <Text size='paragraph' color='system' width={80}>联系方式</Text>
      <UserInput validationFn={showValidationErr ? () => eventContactSchema.validate(contact) : null}>
        <Box boxShadow={1} px={16}>
          <TextField
            margin='dense'
            variant='standard'
            fullWidth
            label='Name'
            placeholder='请输入联系人'
            value={contact.name}
            onChange={(event) => {
              onChangeContact({
                ...contact,
                name: event.target.value,
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
          <Box display='flex' flexDirection='row'>
            <RadioGroup row sx={{ mr: 50, alignContent: 'flex-end' }}
              value={contact.type}
              onChange={(event) => {
                if (readOnlyMode) {
                  return;
                }
                onChangeContact({
                  ...contact,
                  type: event.target.value as EventContactType,
                });
              }}
            >
              <FormControlLabel value='wechat' control={<Radio />} label='微信' />
              <FormControlLabel value='phone' control={<Radio />} label='电话' />
            </RadioGroup>
            <TextField
              margin='dense'
              variant='standard'
              required
              label='Principal'
              placeholder='请输入联系方式（电话号码，微信号）'
              value={contact.principal}
              onChange={(event) => {
                onChangeContact({
                  ...contact,
                  principal: event.target.value,
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
              sx={{ width: 400 }}
            />
          </Box>

          <Box>
            {
              contact.refMedia.downloadUrl ? (
                <Box sx={{ py: 20 }}>
                  <Box sx={{ position: 'relative', border: 1, borderColor: Colors.GREY500, boxShadow: 1, width: 200 }}>
                    <img src={contact.refMedia.downloadUrl} width='100%' />
                    {
                      !readOnlyMode && (
                        <IconButton
                          onClick={() => {
                            onChangeContact({
                              ...contact,
                              refMedia: { id: '', downloadUrl: '' },
                            });
                          }}
                          sx={{ position: 'absolute', right: 0, top: 0 }}
                        >
                          <CancelIcon sx={{ bgcolor: Colors.WHITE, borderRadius: 50 }} />
                        </IconButton>
                      )
                    }
                  </Box>
                </Box>
              ) : !readOnlyMode ? (
                <Button
                  variant='contained'
                  component='label'
                  startIcon={<ImageIcon />}
                  endIcon={uploadingImg && <CircularProgress size={25} sx={{ color: Colors.GREY100 }} />}
                  sx={{ my: 20 }}
                >
                  Upload Image
                  <input hidden accept='image/*' multiple={false} type='file'
                    onChange={async (e)=> {
                      if (e.target.files && e.target.files.length > 0) {
                        setUploadingImg(true);
                        const ref = await onUploadImg(e.target.files[0]);
                        onChangeContact({
                          ...contact,
                          refMedia: ref,
                        });
                        setUploadingImg(false);
                      }
                    }}
                  />
                </Button>
              ) : null
            }
          </Box>
        </Box>
      </UserInput>
    </Box>
  );
};

export default ContactEditor;
