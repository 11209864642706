import React from 'react';
import { Box, Button, CircularProgress, Modal, TextField } from '@mui/material';

import { TicketOrder, TicketOrderVirtualStatus } from 'common/src/models/event';
import { User } from 'common/src/models/user';
import { sendSmsToUsersRemote } from 'common/src/system/network/event';

import { Text } from 'common/src/components/base';

interface IProps {
  selectedDetailedTicketOrders: { user: User | null, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus }[];
  onClose: () => void;
}

const SendSmsModal: React.FC<IProps> = ({ selectedDetailedTicketOrders, onClose }) => {
  const [submittingAction, setSubmittingAction] = React.useState(false);

  const [smsContent, setSmsContent] = React.useState('');
  const [showConfirm, setShowConfirmation] = React.useState(false);

  const userIds = React.useMemo(() => {
    return selectedDetailedTicketOrders
      .filter((detailedTicketOrder) => !!detailedTicketOrder.user)
      .map((detailedTicketOrder) => detailedTicketOrder.user!.id);
  }, [selectedDetailedTicketOrders]);

  const handleSendSms = React.useCallback(async () => {
    setSubmittingAction(true);
    await sendSmsToUsersRemote(userIds, smsContent);

    setSubmittingAction(false);
    onClose();
  }, [userIds, onClose, smsContent]);

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Send SMS</Text>
        {submittingAction ? (
          <Box sx={{ display: 'flex', mt: 16 }}>
            <CircularProgress size={20} sx={{ mr: 8 }} />
            Submitting Action
          </Box>
        ) : (
          <Box>
            <Text variant='italics' color='system' mb={16}>Please enter the SMS content</Text>
            <Box alignItems='center' justifyContent='space-between'>
              <Text size='paragraph' color='primary'>用户 ID</Text>
              <Box alignItems='center' >
                <Text size='paragraph' color='system'>{userIds.join(', ')}</Text>
              </Box>
            </Box>
            <Box alignItems='center' justifyContent='space-between' py={20}>
              <Text size='paragraph' color='primary'>SMS 内容</Text>
              <TextField
                required
                multiline
                fullWidth
                value={smsContent}
                onChange={(event) => setSmsContent(event.target.value)}
              />
            </Box>

            <Box display='flex' justifyContent='space-between'>
              <Button
                variant='contained'
                onClick={() => setShowConfirmation(true)}
                disabled={showConfirm || !smsContent}
              >
                发送
              </Button>

              {showConfirm && (
                <Button
                  variant='contained'
                  onClick={handleSendSms}
                >
                  确定发送
                </Button>
              )}
            </Box>
          </Box>
        )}

      </Box>
    </Modal>
  );
};

export default SendSmsModal;
