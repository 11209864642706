import React from 'react';
import {
  ArrowDownwardOutlined as ArrowDownwardOutlinedIcon, Close as CloseIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { Box, Button, IconButton, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Colors } from 'common/src/constants';
import { QuestionType, SurveyQuestion } from 'common/src/models/event';
import { randStr } from 'common/src/utils/rand';
import { surveyQuestionChoiceSchema, surveyQuestionSchema } from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';


interface IProps {
  surveyQuestions: SurveyQuestion[];
  readOnlyMode: boolean;
  showValidationErr: boolean;
  onChangeSurveyQuestions: (newQuestions: SurveyQuestion[]) => void;
}

const SurveyEditor: React.FC<IProps> = ({ surveyQuestions, readOnlyMode, showValidationErr, onChangeSurveyQuestions }) => {
  return (
    <Box>
      {surveyQuestions.map((surveyQuestion, questionIdx)=>{
        const canMoveDown = questionIdx < surveyQuestions.length - 1;

        return (
          <Box key={surveyQuestion.id} display='flex' alignItems='center' px={20}>
            <Text size='paragraph' color='system-light' width={50}>{'Q' + (questionIdx+1)}</Text>
            <SurveyEntryEditor
              surveyQuestion={surveyQuestion}
              readOnlyMode={readOnlyMode}
              showValidationErr={showValidationErr}
              onChangeSurveyQuestion={
                (newSurveyQuestion) => {
                  const updatedSurveyQuestions = [...surveyQuestions];
                  updatedSurveyQuestions.splice(questionIdx, 1, newSurveyQuestion);
                  onChangeSurveyQuestions(updatedSurveyQuestions);
                }
              }
            />
            {!readOnlyMode && (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <IconButton
                  onClick={()=>{
                    const updatedSurveyQuestions = [...surveyQuestions];
                    updatedSurveyQuestions.splice(questionIdx, 1);
                    onChangeSurveyQuestions(updatedSurveyQuestions);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={()=>{
                    const updatedSurveyQuestions = [...surveyQuestions];
                    const tmp = updatedSurveyQuestions[questionIdx];
                    updatedSurveyQuestions[questionIdx] = updatedSurveyQuestions[questionIdx+1];
                    updatedSurveyQuestions[questionIdx+1] = tmp;

                    onChangeSurveyQuestions(updatedSurveyQuestions);
                  }}
                  sx={{ opacity: canMoveDown ? 0.8 : 0 }}
                >
                  <ArrowDownwardOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

interface ISurveyEntryEditorProps {
  surveyQuestion: SurveyQuestion;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  onChangeSurveyQuestion: (newQuestion: SurveyQuestion) => void;
}

const SurveyEntryEditor: React.FC<ISurveyEntryEditorProps> = ({
  surveyQuestion, readOnlyMode, showValidationErr, onChangeSurveyQuestion,
}) => {
  return (
    <Box m={16} p={16} width='100%' border={1} borderRadius={5} borderColor={Colors.TEXT_SYSTEM_LIGHT}>
      <Box>
        <FormControl variant='standard' sx={{ width: 150 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={surveyQuestion.type}
            onChange={(event: SelectChangeEvent) => {
              onChangeSurveyQuestion({
                ...surveyQuestion,
                type: event.target.value as QuestionType,
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          >
            <MenuItem value={QuestionType.FREEFORM}>FreeForm</MenuItem>
            <MenuItem value={QuestionType.SELECT}>Select</MenuItem>
          </Select>
        </FormControl>
        <Box my={8}>
          <UserInput validationFn={showValidationErr ? () => surveyQuestionSchema.validate(surveyQuestion.question) : null}>
            <TextField
              variant='standard'
              required
              fullWidth
              label='Question'
              placeholder='请输入问题内容'
              value={surveyQuestion.question}
              onChange={(event) => {
                onChangeSurveyQuestion({
                  ...surveyQuestion,
                  question: event.target.value,
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
            />
          </UserInput>
        </Box>

        { surveyQuestion.type == QuestionType.SELECT && surveyQuestion.choices.map((choice, choiceIdx) => {
          const canMoveDown = choiceIdx < surveyQuestion.choices.length - 1;
          return (
            <Box key={choice.id} display='flex' alignItems='center'>
              <Text size='paragraph' color='system-light' width={30} mr={16}>{'C' + (choiceIdx+1)}</Text>
              <Box my={8} sx={{ width: '100%' }}>
                <UserInput validationFn={showValidationErr ? () => surveyQuestionChoiceSchema.validate(choice.text) : null}>
                  <TextField
                    variant='standard'
                    required
                    fullWidth
                    label='Choice'
                    placeholder='请输入选择内容'
                    value={choice.text}
                    onChange={(event) => {
                      onChangeSurveyQuestion({
                        ...surveyQuestion,
                        choices: surveyQuestion.choices.map((choice, idx) => {
                          if (idx === choiceIdx) {
                            return {
                              ...choice,
                              text: event.target.value,
                            };
                          }
                          return choice;
                        }),
                      });
                    }}
                    inputProps={{ readOnly: readOnlyMode }}
                  />
                </UserInput>
              </Box>

              {!readOnlyMode && (
                <IconButton
                  onClick={() => {
                    const updatedChoices = [...surveyQuestion.choices];
                    const tmp = updatedChoices[choiceIdx];
                    updatedChoices[choiceIdx] = updatedChoices[choiceIdx+1];
                    updatedChoices[choiceIdx+1] = tmp;

                    onChangeSurveyQuestion({
                      ...surveyQuestion,
                      choices: updatedChoices,
                    });
                  }}
                  sx={{ opacity: canMoveDown ? 0.8 : 0 }}
                >
                  <ArrowDownwardOutlinedIcon />
                </IconButton>
              )}
              {!readOnlyMode && (
                <IconButton
                  onClick={() => {
                    onChangeSurveyQuestion({
                      ...surveyQuestion,
                      choices: surveyQuestion.choices.filter((_, idx) => idx !== choiceIdx),
                    });
                  }}
                  sx={{ opacity: 0.8 }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          );
        })}

        {!readOnlyMode && surveyQuestion.type == QuestionType.SELECT && (
          <Button
            onClick={() => {
              onChangeSurveyQuestion({
                ...surveyQuestion,
                choices: [
                  ...surveyQuestion.choices,
                  {
                    id: 'c_'+randStr(5),
                    text: '',
                  },
                ],
              });
            }}
          >
          Add choice
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SurveyEditor;
