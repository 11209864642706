import React from 'react';
import { Box, Button, InputAdornment, Modal, TextField } from '@mui/material';
import { ValidationError } from 'yup';

import { eventIdSchema, eventNameSchema } from '../../../../utils/validation';

import { Text } from 'common/src/components/base';

const GROUP_ID_PREFIX = new Date().getFullYear() + '_';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (id: string, name: string) => void;
}

const NewGroupModal: React.FC<IProps> = ({ isOpen, onClose, onConfirm }) => {
  const [groupId, setGroupId] = React.useState('');
  const [groupName, setGroupName] = React.useState('');

  const effectiveGroupId = `${GROUP_ID_PREFIX}${groupId}`;

  const [errMsg, setErrMsg] = React.useState('');
  const [showErrMsg, setShowErrMsg] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          eventIdSchema.validate(effectiveGroupId),
          eventNameSchema.validate(groupName),
        ]);

        setErrMsg('');
      } catch (e) {
        if (e instanceof ValidationError && e.errors.length) {
          setErrMsg(e.errors[0]);
        } else {
          setErrMsg('错误'); // This means there's a bug in the code.
        }
      }
    })();
  }, [effectiveGroupId, groupName]);

  const handleConfirm = () => {
    if (
      !eventIdSchema.isValidSync(effectiveGroupId) ||
      !eventNameSchema.isValidSync(groupName)
    ) {
      setShowErrMsg(true);
      return;
    }
    onConfirm(effectiveGroupId, groupName);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Create a new event group</Text>
        <Text variant='italics' color='system' mb={16}>Please confirm creation of a new event group</Text>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>活动合集 ID</Text>
          <TextField
            margin='normal'
            size='small'
            required
            autoFocus
            placeholder='jay_chou_concert'
            value={groupId}
            onChange={(event) => setGroupId(event.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position='start'>{`${GROUP_ID_PREFIX}`}</InputAdornment>,
            }}
            sx={{ width: 320 }}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>活动合集名</Text>
          <TextField
            margin='normal'
            size='small'
            required
            placeholder='周杰伦世界巡回演唱会'
            value={groupName}
            onChange={(event) => setGroupName(event.target.value)}
            sx={{ width: 320 }}
          />
        </Box>

        <Button
          variant='contained'
          disabled={!groupId || !groupName || (showErrMsg && !!errMsg)}
          onClick={handleConfirm}
          sx={{ marginTop: 24 }}
        >
            Confirm
        </Button>
        {showErrMsg && <Text size='paragraph' color='error' mt={4}>{errMsg}</Text>}
      </Box>
    </Modal>
  );
};

export default NewGroupModal;
