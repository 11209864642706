import React from 'react';
import {
  ArrowDownwardOutlined as ArrowDownwardOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import {
  Box, Checkbox, Chip, FormControl, IconButton, InputAdornment,
  InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';

import { Colors } from 'common/src/constants';
import { TicketOption } from 'common/src/models/event';
import { EventDiscount } from 'common/src/models/event/eventTemplate';
import { ClientType } from 'common/src/models/shared';
import { sortSubsetByRef } from '../../../../utils/string';
import { couponCodeSchema, discountQualifierMinTicketsInOrderSchema, discountValuePercentAdjustmentSchema, discountValueUnitAdjustmentInCentsSchema } from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';

interface IProps {
  discounts: EventDiscount[];
  readOnlyMode: boolean;
  showValidationErr: boolean;
  allTicketOptions: TicketOption[];
  onChangeDiscounts: (newDiscounts: EventDiscount[]) => void;
}

const DiscountEditor: React.FC<IProps> = ({
  discounts, readOnlyMode, showValidationErr, allTicketOptions, onChangeDiscounts,
}) => {
  return (
    <Box>
      {discounts.map((discount, discountIdx)=>{
        const canMoveDown = discountIdx < discounts.length - 1;

        return (
          <Box key={discountIdx} display='flex' alignItems='center' px={20}>
            <Text size='paragraph' color='system-light' width={50}>{'折扣' + (discountIdx+1)}</Text>
            <DiscountEntryEditor
              discount={discount}
              readOnlyMode={readOnlyMode}
              showValidationErr={showValidationErr}
              allTicketOptions={allTicketOptions}
              onChangeDiscount={
                (newDiscount) => {
                  const updatedDiscounts = [...discounts];
                  updatedDiscounts.splice(discountIdx, 1, newDiscount);
                  onChangeDiscounts(updatedDiscounts);
                }
              }
            />
            {!readOnlyMode && (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <IconButton
                  onClick={()=>{
                    const updatedDiscounts = [...discounts];
                    updatedDiscounts.splice(discountIdx, 1);
                    onChangeDiscounts(updatedDiscounts);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={()=>{
                    const updatedDiscounts = [...discounts];
                    const tmp = updatedDiscounts[discountIdx];
                    updatedDiscounts[discountIdx] = updatedDiscounts[discountIdx+1];
                    updatedDiscounts[discountIdx+1] = tmp;

                    onChangeDiscounts(updatedDiscounts);
                  }}
                  sx={{ opacity: canMoveDown ? 0.8 : 0 }}
                >
                  <ArrowDownwardOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

interface IDiscountEntryEditorProps {
  discount: EventDiscount;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  allTicketOptions: TicketOption[];
  onChangeDiscount: (newDiscount: EventDiscount) => void;
}

const DiscountEntryEditor: React.FC<IDiscountEntryEditorProps> = ({
  discount, readOnlyMode, showValidationErr, allTicketOptions, onChangeDiscount,
}) => {
  const {
    couponCode, automatic, discountQualifier, discountValue,
  } = discount;

  const [inputUnitAdjustment, setInputUnitAdjustment] = React.useState(
    discountValue.unitAdjustmentInCents % 100 === 0 ?
      (discountValue.unitAdjustmentInCents / 100).toFixed(0) :
      (discountValue.unitAdjustmentInCents / 100).toFixed(2),
  );
  const [inputPercentAdjustment, setInputPercentAdjustment] = React.useState((discountValue.percentAdjustment * 100).toFixed(0));
  const [inputAdjustedUnitCost, setInputAdjustedUnitCost] = React.useState(
    discountValue.adjustedUnitCostInCents % 100 === 0 ?
      (discountValue.adjustedUnitCostInCents / 100).toFixed(0) :
      (discountValue.adjustedUnitCostInCents / 100).toFixed(2),
  );

  return (
    <Box m={16} p={16} width='100%' border={1} borderRadius={5} borderColor={Colors.TEXT_SYSTEM_LIGHT}>
      <Box>
        <Box display='flex' alignItems='center'>
          <Text size='paragraph' color='system' width={80}>基本</Text>
          <Box>
            <UserInput validationFn={showValidationErr ?
              () => couponCodeSchema.validate(couponCode) : null}
            >
              <TextField
                variant='standard'
                required
                label='折扣码'
                placeholder='请输入折扣码'
                value={couponCode}
                onChange={(event) => {
                  onChangeDiscount({
                    ...discount,
                    couponCode: event.target.value,
                  });
                }}
                inputProps={{ readOnly: readOnlyMode }}
                sx={{ mr: 32, width: 365 }}
              />
            </UserInput>

            <Stack direction='row' alignItems='center' alignSelf='flex-end'>
              <Checkbox
                checked={automatic}
                onChange={(event) => {
                  if (readOnlyMode) {
                    return;
                  }
                  onChangeDiscount({
                    ...discount,
                    automatic: event.target.checked,
                  });
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Text>自动生效</Text>
            </Stack>
          </Box>
        </Box>

        <Box display='flex' alignItems='center'>
          <Text size='paragraph' color='system' width={80}>限定条件</Text>
          <Box>
            <Box sx={{ mb: 15 }}>
              <FormControl sx={{ minWidth: 250 }} variant='standard' >
                <InputLabel>门票限制</InputLabel>
                <Select
                  multiple
                  value={discountQualifier.ticketOptionIds}
                  onChange={(event: SelectChangeEvent<string[]>) => {
                    // On autofill we get a stringified value.
                    const newTicketOptionIds = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

                    onChangeDiscount({
                      ...discount,
                      discountQualifier: {
                        ...discountQualifier,
                        ticketOptionIds: sortSubsetByRef(newTicketOptionIds, allTicketOptions.map((ticketOption) => ticketOption.id)),
                      },
                    });
                  }}
                  renderValue={(ticketOptionIds) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                      {
                        ticketOptionIds.map((ticketOptionId) => {
                          const ticketOption = allTicketOptions.find((ticketOption) => ticketOption.id === ticketOptionId);
                          return (
                            <Chip
                              key={ticketOptionId}
                              label={ticketOption ? ticketOption.name : 'Unknown'}
                              variant='outlined' size='small'
                            />
                          );
                        })
                      }
                    </Box>
                  )}
                  inputProps={{ readOnly: readOnlyMode }}
                >
                  {allTicketOptions.map((ticketOption) => (
                    <MenuItem
                      key={ticketOption.id}
                      value={ticketOption.id}
                      style={discountQualifier.ticketOptionIds.includes(ticketOption.id) ? { 'fontWeight': 'bold' } : { 'fontWeight': 'normal' }}
                    >
                      {ticketOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mb: 15 }}>
              <FormControl sx={{ minWidth: 250 }} variant='standard' >
                <InputLabel>客户端限制</InputLabel>
                <Select
                  multiple
                  value={discountQualifier.clientTypes}
                  onChange={(event: SelectChangeEvent<string[]>) => {
                    // On autofill we get a stringified value.
                    const newClientTypes = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;

                    onChangeDiscount({
                      ...discount,
                      discountQualifier: {
                        ...discountQualifier,
                        clientTypes: sortSubsetByRef(newClientTypes, Object.values(ClientType)),
                      },
                    });
                  }}
                  renderValue={(clientTypes) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                      {
                        clientTypes.map((clientType) => (
                          <Chip key={clientType} label={clientType} variant='outlined' size='small' />
                        ))
                      }
                    </Box>
                  )}
                  inputProps={{ readOnly: readOnlyMode }}
                >
                  {Object.values(ClientType).map((clientType) => (
                    <MenuItem
                      key={clientType}
                      value={clientType}
                      style={discountQualifier.clientTypes.includes(clientType) ? { 'fontWeight': 'bold' } : { 'fontWeight': 'normal' }}
                    >
                      {clientType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <UserInput validationFn={showValidationErr ?
              () => discountQualifierMinTicketsInOrderSchema.validate(discountQualifier.minTicketsInOrder) : null}
            >
              <TextField
                variant='standard'
                required
                label='用户需购买票数'
                placeholder='请输入用户需购买票数'
                value={discountQualifier.minTicketsInOrder}
                onChange={(event) => {
                  onChangeDiscount({
                    ...discount,
                    discountQualifier: {
                      ...discountQualifier,
                      minTicketsInOrder: +event.target.value,
                    },
                  });
                }}
                InputProps={{ readOnly: readOnlyMode }}
                sx={{ mr: 16, width: 160 }}
              />
            </UserInput>
          </Box>
        </Box>

        <Box display='flex' alignItems='center'>
          <Text size='paragraph' color='system' width={80}>折扣金额</Text>
          <Box>
            <Box mt={10}>
              <UserInput
                validationFn={showValidationErr ? () => discountValueUnitAdjustmentInCentsSchema.validate(discountValue.unitAdjustmentInCents) : null}
              >
                <TextField
                  variant='standard'
                  label='金额调整'
                  placeholder='请输入金额调整'
                  InputProps={{
                    readOnly: readOnlyMode,
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  }}
                  value={inputUnitAdjustment}
                  onChange={(event) => {
                    setInputUnitAdjustment(event.target.value.replace(/[^\d.-]/g, ''));
                  }}
                  onBlur={() => {
                    let roundedUnitAdjustmentInCents = Math.round(+inputUnitAdjustment*100);
                    if (roundedUnitAdjustmentInCents > 0) {
                      roundedUnitAdjustmentInCents *= -1;
                    }

                    if (roundedUnitAdjustmentInCents % 100 === 0) {
                      setInputUnitAdjustment((roundedUnitAdjustmentInCents/100).toFixed(0));
                    } else {
                      setInputUnitAdjustment((roundedUnitAdjustmentInCents/100).toFixed(2));
                    }
                    onChangeDiscount({
                      ...discount,
                      discountValue: {
                        ...discountValue,
                        unitAdjustmentInCents: roundedUnitAdjustmentInCents,
                      },
                    });
                  }}
                  sx={{ mr: 32, width: 100 }}
                />
              </UserInput>
            </Box>

            <Box mt={10}>
              <UserInput
                validationFn={showValidationErr ? () => discountValuePercentAdjustmentSchema.validate(discountValue.percentAdjustment) : null}
              >
                <TextField
                  variant='standard'
                  label='%调整'
                  placeholder='请输入%调整'
                  InputProps={{
                    readOnly: readOnlyMode,
                    endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                  }}
                  value={inputPercentAdjustment}
                  onChange={(event) => {
                    setInputPercentAdjustment(event.target.value.replace(/[^\d-]/g, ''));
                  }}
                  onBlur={() => {
                    let newPercentAdjustment = +inputPercentAdjustment;
                    if (newPercentAdjustment > 0) {
                      newPercentAdjustment *= -1;
                    }

                    setInputPercentAdjustment(newPercentAdjustment.toFixed(0));
                    onChangeDiscount({
                      ...discount,
                      discountValue: {
                        ...discountValue,
                        percentAdjustment: newPercentAdjustment / 100,
                      },
                    });
                  }}
                  sx={{ mr: 32, width: 100 }}
                />
              </UserInput>
            </Box>

            <Box mt={10}>
              <TextField
                variant='standard'
                label='固定金额'
                placeholder='请输入固定金额'
                InputProps={{
                  readOnly: readOnlyMode,
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                value={inputAdjustedUnitCost}
                onChange={(event) => {
                  setInputAdjustedUnitCost(event.target.value.replace(/[^\d.-]/g, ''));
                }}
                onBlur={() => {
                  const roundedAdjustedUnitCostInCents = Math.round(+inputAdjustedUnitCost*100);

                  if (roundedAdjustedUnitCostInCents % 100 === 0) {
                    setInputAdjustedUnitCost((roundedAdjustedUnitCostInCents/100).toFixed(0));
                  } else {
                    setInputAdjustedUnitCost((roundedAdjustedUnitCostInCents/100).toFixed(2));
                  }
                  onChangeDiscount({
                    ...discount,
                    discountValue: {
                      ...discountValue,
                      adjustedUnitCostInCents: roundedAdjustedUnitCostInCents,
                    },
                  });
                }}
                sx={{ mr: 32, width: 100 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DiscountEditor;
