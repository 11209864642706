import React from 'react';
import { Box, Button, Modal, Switch } from '@mui/material';

import { Text } from 'common/src/components/base';


interface IProps {
  archived: boolean;
  onArchivedChange: (archived: boolean) => void;
}

const ArchivalToggle: React.FC<IProps> = ({ archived, onArchivedChange }) => {
  const [archiveModalOpen, setArchiveModalOpen] = React.useState(false);

  return (
    <Box>
      <Box display='flex'>
        <Text size='subtitle' alignSelf='baseline' mr={8}>Archive this event</Text>
        <Box alignSelf='flex-start'>
          <Switch
            checked={archived}
            onChange={(e) => {
              if (e.target.checked) {
                setArchiveModalOpen(true);
              } else {
                onArchivedChange(false);
              }
            }}
          />
        </Box>
      </Box>
      <ArchivalModal
        isOpen={archiveModalOpen}
        onConfirm={(archived)=> {
          onArchivedChange(archived);
          setArchiveModalOpen(false);
        }}
      />
    </Box>
  );
};

interface IArchivalModalProps {
  isOpen: boolean;
  onConfirm: (archived: boolean) => void;
}

const ArchivalModal: React.FC<IArchivalModalProps> = ({ isOpen, onConfirm }) => {
  return (
    <Modal
      open={isOpen}
      onClose={() => onConfirm(false)}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Archive this event</Text>
        <Text variant='italics' color='red' mb={16}>This operation is irreversible, Please confirm archival of this event</Text>

        <Box mt={24}>
          <Button
            variant='contained'
            onClick={() => onConfirm(true)}
            sx={{ mr: 10 }}
          >
          Confirm
          </Button>
          <Button
            variant='contained'
            onClick={() => onConfirm(false)}
          >
          Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ArchivalToggle;
