import React from 'react';
import { Box, Button, Checkbox, Stack, TextField } from '@mui/material';

import { EventTemplateGroup } from 'common/src/models/event';
import { ResourceRef } from 'common/src/models/shared';
import { randStr } from 'common/src/utils/rand';
import {
  eventDescriptionSchema, eventIntroSchema, eventNameSchema, urlSchema,
} from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';
import { ImageEditor } from '../../../../components/base';
import { ArchivalToggle, StatusBanner } from '../../../../components/event';
import EventTemplatesEditor from './EventTemplatesEditor';

interface IProps {
  eventTemplateGroup: EventTemplateGroup;
  confirmPublished: boolean;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  validateEventTemplateId: (eventTemplateId: string) => void;
  onEventTemplateGroupChange: (mutationFn: ((prevEventTemplateGroup: EventTemplateGroup) => void)) => void;
  onUploadImg: (file: File) => Promise<ResourceRef>;
}

const Editor: React.FC<IProps> = ({
  eventTemplateGroup, confirmPublished, readOnlyMode, showValidationErr, validateEventTemplateId,
  onEventTemplateGroupChange, onUploadImg,
}) => {
  const {
    id, published, archived, name, intro, description, media, extraMedia, groupedEventTemplates,
    showGroupOnly, visibilitySetting, detailsUrl,
  } = eventTemplateGroup;

  return (
    <Box sx={{ mx: 16, mt: 16 }}>
      <StatusBanner
        confirmPublished={confirmPublished}
        draftPublished={published}
        readOnlyMode={readOnlyMode}
        onChangePublished={(published) => {
          onEventTemplateGroupChange((prevEventTemplateGroup) => {
            prevEventTemplateGroup.published = published;
          });
        }}
        archived={archived}
      />
      <Box display='flex'>
        <Text size='paragraph' mr={8} alignSelf='flex-end' mb={3}>ID:</Text>
        <Text size='title'>{id}</Text>
      </Box>
      <Box sx={{ mt: 16, mr: 80 }}>
        <UserInput validationFn={showValidationErr ? () => eventNameSchema.validate(name) : null}>
          <TextField
            variant='standard'
            required
            label='Name'
            placeholder='请输入活动合集名称'
            value={name}
            onChange={(event) => {
              onEventTemplateGroupChange((prevEventTemplateGroup) => {
                prevEventTemplateGroup.name = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
            sx={{ width: 300 }}
          />
        </UserInput>
      </Box>

      <Box sx={{ my: 16 }}>
        <ImageEditor
          imgUrls={media.downloadUrl ? [media.downloadUrl] : []}
          multipleImgs={false}
          required={false}
          readOnlyMode={readOnlyMode}
          showValidationErr={showValidationErr && published}
          onUploadImgs={async (files: File[]) => {
            if (files.length > 0) {
              const ref = await onUploadImg(files[0]);
              onEventTemplateGroupChange((prevEventTemplateGroup) => {
                prevEventTemplateGroup.media = ref;
              });
            }
          }}
          onRemoveImg={()=>{
            onEventTemplateGroupChange((prevEventTemplateGroup) => {
              prevEventTemplateGroup.media = { id: '', downloadUrl: '' };
            });
          }}
        />
      </Box>

      <Box sx={{ my: 16 }}>
        <UserInput validationFn={showValidationErr && published ? () => eventIntroSchema.validate(intro) : null}>
          <TextField
            variant='standard'
            required
            fullWidth
            label='Event Introduction'
            placeholder='请输入活动合集介绍'
            value={intro}
            onChange={(event) => {
              onEventTemplateGroupChange((prevEventTemplateGroup) => {
                prevEventTemplateGroup.intro = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr && published ? () => eventDescriptionSchema.validate(description): null}>
          <TextField
            required
            multiline
            fullWidth
            label='Event Description'
            placeholder='请输入活动合集描述'
            value={description}
            onChange={(event) => {
              onEventTemplateGroupChange((prevEventTemplateGroup) => {
                prevEventTemplateGroup.description = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>

      <EventTemplatesEditor
        eventTemplates={groupedEventTemplates}
        readOnlyMode={readOnlyMode}
        showValidationErr={showValidationErr}
        validateEventTemplateId={validateEventTemplateId}
        onChangeEventTemplates={((newEventTemplates) => {
          onEventTemplateGroupChange((prevEventTemplateGroup) => {
            prevEventTemplateGroup.groupedEventTemplates = newEventTemplates;
          });
        })}
      />
      {!readOnlyMode && (
        <Button
          sx={{ mb: 20, ml: 70 }}
          onClick={() => {
            onEventTemplateGroupChange((prevEventTemplateGroup) => {
              const updatedEventTemplates = [
                ...groupedEventTemplates,
                {
                  eventTemplateId: 'event_template_id_'+randStr(5),
                  name: '',
                },
              ];

              prevEventTemplateGroup.groupedEventTemplates = updatedEventTemplates;
            });
          }}
        >
          Add Event Template to Group
        </Button>
      )}

      <Box display='flex' alignItems='flex-start' my={15}>
        <Text size='paragraph' color='system' width={80} pt={12}>显示设置</Text>

        <Box>
          <Stack direction='row' alignItems='center'>
            <Checkbox
              checked={visibilitySetting.visibleInApp}
              onChange={(event) => {
                if (readOnlyMode) {
                  return;
                }
                onEventTemplateGroupChange((prevEventTemplateGroup) => {
                  prevEventTemplateGroup.visibilitySetting.visibleInApp = event.target.checked;
                });
              }}
            />
            <Text>在app显示</Text>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <Checkbox
              checked={visibilitySetting.visibleOnWeb}
              onChange={(event) => {
                if (readOnlyMode) {
                  return;
                }
                onEventTemplateGroupChange((prevEventTemplateGroup) => {
                  prevEventTemplateGroup.visibilitySetting.visibleOnWeb = event.target.checked;
                });
              }}
            />
            <Text>在web显示</Text>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <Checkbox
              checked={showGroupOnly}
              onChange={(event) => {
                if (readOnlyMode) {
                  return;
                }
                onEventTemplateGroupChange((prevEventTemplateGroup) => {
                  prevEventTemplateGroup.showGroupOnly = !!event.target.value;
                });
              }}
            />
            <Text>只显示合集</Text>
            <Text size='note' variant='italics'>（如选择，用户在列表中只能看到合集，不能单独看到合集中的活动）</Text>
          </Stack>
        </Box>
      </Box>

      {(!!extraMedia.length || !readOnlyMode) && (
        <Box display='flex' alignItems='flex-start' my={15}>
          <Text size='paragraph' color='system' width={80} pt={12}>附加图片</Text>
          <Box>
            <ImageEditor
              imgUrls={extraMedia.map((media_) => media_.downloadUrl)}
              multipleImgs
              required={false}
              readOnlyMode={readOnlyMode}
              showValidationErr={false}
              onUploadImgs={async (files: File[]) => {
                const extraMediaCopy = [...extraMedia];
                const promises = files.map(async (file) => {
                  const ref = await onUploadImg(file);
                  extraMediaCopy.push(ref);
                  onEventTemplateGroupChange((prevEventTemplateGroup) => prevEventTemplateGroup.extraMedia = extraMediaCopy);
                });
                await Promise.all(promises);
              }}
              onRemoveImg={(idx: number)=>{
                onEventTemplateGroupChange((prevEventTemplateGroup) => {
                  prevEventTemplateGroup.extraMedia.splice(idx, 1);
                });
              }}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ my: 32 }}>
        <UserInput validationFn={showValidationErr ? () => urlSchema.validate(detailsUrl) : null}>
          <TextField
            variant='standard'
            fullWidth
            label='详情链接'
            value={detailsUrl}
            onChange={(event) => {
              onEventTemplateGroupChange((prevEventTemplateGroup) => {
                prevEventTemplateGroup.detailsUrl = event.target.value;
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </UserInput>
      </Box>

      {!readOnlyMode && (
        <ArchivalToggle
          archived={archived}
          onArchivedChange={(archived: boolean) => {
            onEventTemplateGroupChange((prevEventTemplateGroup) => {
              prevEventTemplateGroup.archived = archived;
            });
          }}
        />
      )}
    </Box>
  );
};

export default Editor;
