import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Box, Stack, TextField } from '@mui/material';

import { EventLocation } from 'common/src/models/event';
import { Location } from 'common/src/models/user';
import { resolveLocationRemote } from 'common/src/system/network/map';
import Logger from 'common/src/utils/logger';
import { eventLocationSchema, latlngSchema } from '../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';

const LOGGER = Logger.getInstance();

interface IProps {
  location: EventLocation;
  title: string;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  onChangeLocation: (newLocation: EventLocation) => void;
}

const LocationEditor: React.FC<IProps> = ({ location, title, readOnlyMode, showValidationErr, onChangeLocation }) => {
  const [locationFromLatLng, setLocationFromLatLng] = React.useState<Location | null>(null);

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyBAt5SOlYts6igsIEahCrHdridiwukiUnQ',
    onPlaceSelected: (dat) => {
      const loc = locationFromGeodata(dat);
      setLocationFromLatLng(null);
      onChangeLocation({
        ...location,
        address: loc.address,
        city: loc.city,
        state: loc.state,
        latlng: loc.latlng,
        zipcode: loc.zipcode,
      });
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: ['us', 'ca'] },
    },
  });

  return (
    <Box display='flex' alignItems='center' my={16}>
      <Text size='paragraph' color='system' width={80}>{title}</Text>
      <UserInput validationFn={showValidationErr ? () => eventLocationSchema.validate(location) : null}>
        <Box boxShadow={1} px={16}>
          <TextField
            margin='dense'
            variant='standard'
            fullWidth
            label='Summary'
            placeholder='请输入总结'
            value={location.summary}
            onChange={(event) => {
              onChangeLocation({
                ...location,
                summary: event.target.value,
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />

          <Box sx={{
            height: readOnlyMode ? undefined : 0,
            opacity: readOnlyMode ? undefined : 0,
          }}>
            <TextField
              margin='dense'
              variant='standard'
              required
              fullWidth
              label='Address'
              placeholder='请输入地址'
              value={location.address}
              onChange={(event) => {
                onChangeLocation({
                  ...location,
                  address: event.target.value,
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
            />
          </Box>
          <Box sx={{
            height: readOnlyMode ? 0 : undefined,
            opacity: readOnlyMode ? 0 : undefined,
          }}>
            <TextField
              inputRef={ref}
              margin='dense'
              variant='standard'
              required
              fullWidth
              label='Address'
              placeholder='请输入地址'
              value={location.address}
              onChange={(event) => {
                onChangeLocation({
                  ...location,
                  address: event.target.value,
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
              autoComplete='off'
            />
          </Box>

          <TextField
            margin='dense'
            variant='standard'
            required
            label='City'
            placeholder='请输入城市'
            value={location.city}
            onChange={(event) => {
              let newCity = event.target.value;
              if (newCity) {
                newCity = newCity.charAt(0).toUpperCase() + newCity.slice(1);
              }
              onChangeLocation({
                ...location,
                city: newCity,
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
            sx={{ mr: 32 }}
          />
          <TextField
            margin='dense'
            variant='standard'
            required
            label='State'
            placeholder='请输入State'
            value={location.state}
            onChange={(event) => {
              onChangeLocation({
                ...location,
                state: event.target.value.toUpperCase(),
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
            sx={{ mr: 32 }}
          />
          <TextField
            margin='dense'
            variant='standard'
            required
            label='Zipcode'
            placeholder='请输入Zipcode'
            value={location.zipcode}
            onChange={(event) => {
              onChangeLocation({
                ...location,
                zipcode: event.target.value.toUpperCase(), // postal code has characters
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
            sx={{ mr: 32 }}
          />

          <Stack direction='row' alignItems='center'>
            <TextField
              margin='dense'
              variant='standard'
              required
              label='Latitude & Longitude'
              placeholder='请输入经纬度'
              value={location.latlng}
              onChange={async (event) => {
                const cleanedLatlng = event.target.value.replaceAll(/[^0-9,.-]/g, '');
                onChangeLocation({
                  ...location,
                  latlng: cleanedLatlng,
                });

                try {
                  let resp = null;
                  if (latlngSchema.isValidSync(cleanedLatlng)) {
                    resp = await resolveLocationRemote(cleanedLatlng, /* zipcode */ '');
                  }
                  if (resp && resp.location && resp.location.address && resp.location.city && resp.location.state && resp.location.zipcode) {
                    setLocationFromLatLng(resp.location);
                  } else {
                    setLocationFromLatLng(null);
                  }
                } catch (e) {
                  LOGGER.error('Failed to get address from long lat', e);
                  // Best effort
                }
              }}
              inputProps={{ readOnly: readOnlyMode }}
            />
            {
              locationFromLatLng && (
                <>
                  <Text mx={20}>{'=>'}</Text>
                  <Box>
                    <Text size='subnote' color='system-light'>Resolved Address</Text>
                    <Text size='paragraph' color='system'>{`${locationFromLatLng.address}, ${locationFromLatLng.city}, ${locationFromLatLng.state} ${locationFromLatLng.zipcode}`}</Text>
                  </Box>

                </>
              )
            }
          </Stack>

          <TextField
            margin='dense'
            variant='standard'
            fullWidth
            label='Address Note'
            placeholder='请输入地址注释'
            value={location.addressNotes}
            onChange={(event) => {
              onChangeLocation({
                ...location,
                addressNotes: event.target.value,
              });
            }}
            inputProps={{ readOnly: readOnlyMode }}
          />
        </Box>
      </UserInput>
    </Box>
  );
};


function locationFromGeodata(
  data: any,
): Location {
  try {
    const city = data.address_components.filter((t: any) =>
      t.types.includes('locality'),
    )[0]?.long_name;
    const state = data.address_components.filter((t: any) =>
      t.types.includes('administrative_area_level_1'),
    )[0]?.short_name;
    const zipcode = data.address_components.filter((t: any) =>
      t.types.includes('postal_code'),
    )[0]?.long_name;
    const latlng = data.geometry.location.lat() + ',' + data.geometry.location.lng();

    const streetNumber = data.address_components.filter((t: any) =>
      t.types.includes('street_number'),
    )[0]?.long_name;
    const address = data.address_components.filter((t: any) =>
      t.types.includes('route'),
    )[0]?.short_name;
    const unit = data.address_components.filter((t: any) =>
      t.types.includes('subpremise'),
    )[0]?.short_name;

    return {
      longlat: '',
      latlng: latlng,
      address: ((streetNumber || '') + (address ? (' ' + address) : '') + (unit ? (', ' + unit) : '')) .trim(),
      city: city || '',
      zipcode: zipcode || '',
      state: state || '',
    };
  } catch (e) {
    LOGGER.error('Failed to parse location from geodata. Payload: ' + JSON.stringify(data) +
      ' Error: ' + JSON.stringify(e));
    throw e;
  }
}

export default LocationEditor;
