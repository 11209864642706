import React from 'react';
import {
  DownloadOutlined as DownloadOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';

import { Text } from 'common/src/components/base';

interface IProps {
  title: string;
  rows: {label: string, content: Record<string, any>}[];
  dynamicTable?: boolean;
  allowExport?: boolean;
}

const ReportTable: React.FC<IProps> = ({
  title, rows, dynamicTable, allowExport,
}) => {
  const agGridRef = React.useRef<AgGridReactType>(null);

  const defaultColDef = React.useMemo(() => ({
    sortable: !!dynamicTable,
    resizable: !!dynamicTable,
    editable: false,
    filter: !!dynamicTable,
  }), [dynamicTable]);

  const rowData = rows.map((row) => {
    return {
      label: row.label,
      ...row.content,
    };
  });

  const columnDefs = React.useMemo(() => {
    const allKeys = rows.reduce((prevVal, row) => {
      Object.keys(row.content).forEach((key) => {
        if (!prevVal.includes(key)) {
          prevVal.push(key);
        }
      });
      return prevVal;
    }, [] as string[]);

    return [
      { field: 'label', headerName: '', width: 150 },
      ...allKeys.map((key) => ({
        field: key,
        headerName: key,
        width: 110,
      })),
    ];
  }, [rows]);

  return (
    <Box>
      <Stack direction='row'>
        <Text size='subtitle' my={10}>{title}</Text>
        {allowExport && (
          <Button
            size='small'
            startIcon={<DownloadOutlinedIcon />}
            sx={{ ml: 30 }}
            onClick={() => agGridRef.current?.api.exportDataAsCsv()}
          >
          Export
          </Button>
        )}
      </Stack>
      <Box className='ag-theme-alpine' sx={{ mb: 20 }}>
        <AgGridReact
          ref={agGridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          domLayout='autoHeight'
          headerHeight={35}
          rowHeight={35}
        />
      </Box>
    </Box>
  );
};

export default ReportTable;
