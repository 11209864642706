import React from 'react';

import { EventTemplate, EventTemplateGroup, TaroPassRecord, TicketOrder, Vendor } from 'common/src/models/event';
import { CacheCompleteness } from '../redux/slices/common';
import {
  EventTemplatesCacheCompleteness, refreshEventTemplateGroups, refreshEventTemplates,
  refreshSelectEventTemplates, refreshTaroPasses, refreshTicketOrders, refreshVendors,
  selectEventTemplateGroupsCache, selectEventTemplatesCache, selectOptEventTemplate,
  selectOptEventTemplateGroup, selectTaroPassesCache, selectTicketOrdersByTemplate,
  selectVendorsCache,
} from '../redux/slices/event';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useEventTemplate = (eventTemplateId: string, forceRefresh?: boolean): EventTemplate | null => {
  const dispatch = useAppDispatch();

  const eventTemplate = useAppSelector((store) => selectOptEventTemplate(store, eventTemplateId));
  React.useEffect(() => {
    dispatch(refreshSelectEventTemplates({ eventTemplateIds: [eventTemplateId], onlyIfUncached: !forceRefresh }));
  }, [dispatch, eventTemplateId, forceRefresh]);

  return eventTemplate;
};

const useEventTemplates = (includePastEvents: boolean, forceRefresh?: boolean): {
  data: Record<string, EventTemplate>,
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const eventTemplatesCache = useAppSelector(selectEventTemplatesCache);
  React.useEffect(() => {
    dispatch(refreshEventTemplates({ includePastEvents: includePastEvents, onlyIfUncached: !forceRefresh }));
  }, [dispatch, includePastEvents, forceRefresh]);

  return {
    data: eventTemplatesCache.recordById,
    ready: includePastEvents ? eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.FULL :
      eventTemplatesCache.completeness === EventTemplatesCacheCompleteness.CURRENT,
  };
};

const useEventTemplatesWithFilter = (includePastEvents: boolean, forceRefresh?: boolean): {
  data: EventTemplate[],
  ready: boolean,
} => {
  const stateFilter = useAppSelector((state) => state.setting.stateFilter);

  const { data: eventTemplates, ready } = useEventTemplates(includePastEvents, forceRefresh);

  const filteredEventTemplates = React.useMemo(() => {
    return Object.values(eventTemplates).filter((eventTemplate) =>
      stateFilter === null || eventTemplate.location.state.toLowerCase() === stateFilter);
  }, [eventTemplates, stateFilter]);

  return {
    data: filteredEventTemplates,
    ready,
  };
};

const useEventTemplateGroup = (eventTemplateGroupId: string, forceRefresh?: boolean): EventTemplateGroup | null => {
  const dispatch = useAppDispatch();

  const eventTemplateGroup = useAppSelector((store) => selectOptEventTemplateGroup(store, eventTemplateGroupId));
  React.useEffect(() => {
    dispatch(refreshEventTemplateGroups({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return eventTemplateGroup;
};

const useEventTemplateGroups = (forceRefresh?: boolean): {
  data: EventTemplateGroup[],
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const eventTemplateGroupsCache = useAppSelector(selectEventTemplateGroupsCache);
  React.useEffect(() => {
    dispatch(refreshEventTemplateGroups({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: eventTemplateGroupsCache.records,
    ready: eventTemplateGroupsCache.completeness === CacheCompleteness.FULL,
  };
};

const useTicketOrdersByEventTemplate = (eventTemplateId: string, forceRefresh?: boolean): TicketOrder[] | null => {
  const dispatch = useAppDispatch();

  const ticketOrders = useAppSelector((state) =>
    selectTicketOrdersByTemplate(state, eventTemplateId));
  React.useEffect(() => {
    dispatch(refreshTicketOrders({ eventTemplateId: eventTemplateId, onlyIfUncached: !forceRefresh }));
  }, [dispatch, eventTemplateId, forceRefresh]);

  return ticketOrders;
};

const useTaroPasses = (forceRefresh?: boolean): {
  data: TaroPassRecord[],
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const taroPassesCache = useAppSelector(selectTaroPassesCache);
  React.useEffect(() => {
    dispatch(refreshTaroPasses({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: taroPassesCache.records,
    ready: taroPassesCache.completeness === CacheCompleteness.FULL,
  };
};

const useVendors = (forceRefresh?: boolean): {
  data: Vendor[],
  ready: boolean,
} => {
  const dispatch = useAppDispatch();

  const vendorsCache = useAppSelector(selectVendorsCache);
  React.useEffect(() => {
    dispatch(refreshVendors({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return {
    data: vendorsCache.records,
    ready: vendorsCache.completeness === CacheCompleteness.FULL,
  };
};

export {
  useEventTemplate, useEventTemplates, useEventTemplatesWithFilter, useEventTemplateGroup,
  useEventTemplateGroups, useTaroPasses, useTicketOrdersByEventTemplate, useVendors,
};
