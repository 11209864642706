import React from 'react';
import {
  WhereToVoteOutlined as WhereToVoteOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';

import {
  CostAdjustor, getSelectedTicketOptions, QuestionType, TicketOrder, TicketOrderVirtualStatus,
} from 'common/src/models/event';
import { User, UserContact } from 'common/src/models/user';
import { getHumanReadableDateTime } from 'common/src/utils/time';
import useAppDispatch from '../../hooks/useAppDispatch';
import { useEventTemplate } from '../../hooks/useResource';
import { checkInTickets } from '../../redux/slices/event';

import { Page } from 'common/src/components/base';
import { TicketOrdersTable } from '.';

interface IProps {
  children?: React.ReactNode;
  eventTemplateId: string;
  showSensitiveData: boolean;
}

const CheckInTable: React.FC<IProps> = ({
  children, eventTemplateId, showSensitiveData,
}) => {
  const dispatch = useAppDispatch();

  const eventTemplate = useEventTemplate(eventTemplateId);

  const [selectedDetailedTicketOrders, setSelectedDetailedTicketOrders] =
    React.useState<{ user: User | null, ticketOrder: TicketOrder }[]>([]);
  const [submittingCheckIn, setSubmittingCheckIn] = React.useState(false);

  const columns = React.useMemo(() => {
    const tmp: {field:string, headerName?: string, width?: number, valueFormatter?: (data:any) => string}[] = [
      { field: 'status', width: 160 },
      { field: 'selectedTicketOptions', width: 150 },
      { field: 'id', width: 130 },
      { field: 'userId', width: 90 },
      { field: 'phone', width: 90 },
      { field: 'registeredTs', width: 150, valueFormatter: (data: any) => {
        const registrationTs = data.value;
        return registrationTs && eventTemplate ?
          getHumanReadableDateTime(registrationTs, eventTemplate.eventTimeZone) : '-';
      } },
      { field: 'checkedInTs', width: 150, valueFormatter: (data: any) => {
        const checkedInTs = data.value;
        return checkedInTs && eventTemplate ?
          getHumanReadableDateTime(checkedInTs, eventTemplate.eventTimeZone) : '-';
      } },
    ];
    if (eventTemplate) {
      tmp.push(
        ...eventTemplate.payload.hostedEventPayload.surveyQuestions.map((surveyQuestion) => ({
          field: surveyQuestion.id,
          headerName: surveyQuestion.question,
        })),
      );
    }
    if (showSensitiveData) {
      tmp.push(
        { field: 'name', width: 150 },
        { field: 'gender', width: 105 },
      );
    }
    tmp.push(
      { field: 'discount', width: 150 },
    );
    return tmp;
  }, [showSensitiveData, eventTemplate]);

  const transformRowFn = React.useCallback((
    user: User, userContact: UserContact, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus,
  ) => {
    if (!eventTemplate) {
      return [];
    }

    const coreUserInfo = user.userProfile.coreUserInfo;
    const ticketOption = getSelectedTicketOptions(ticketOrder).map((selectedTicketOption) => {
      const ticketOption = eventTemplate.payload.hostedEventPayload.ticketOptions
        .find((ticketOption: any) => ticketOption.id === selectedTicketOption.id);
      return ticketOption?.name + ' x ' + selectedTicketOption.quantity;
    }).join(', ');

    const costAdjustorIds = Object.values(ticketOrder.costDetails.ticketIdToCost)
      .flatMap((ticketCost) => ticketCost.costAdjustors)
      .filter((costAdjustor: CostAdjustor) => !!costAdjustor.adjustorId)
      .map((costAdjustor: CostAdjustor) => costAdjustor.adjustorId);
    const deduppedCostAdjustorIds= Array.from(new Set(costAdjustorIds)).join(', ');

    const surveyResponses: Record<string, any> = {};
    ticketOrder.surveyResponses.forEach((surveyResponse) => {
      const matchingSurveyQuestion = eventTemplate.payload.hostedEventPayload.surveyQuestions
        .find((surveyQuestion) => surveyQuestion.id === surveyResponse.id);
      if (!matchingSurveyQuestion) {
        return;
      }
      switch (matchingSurveyQuestion.type) {
        case QuestionType.FREEFORM:
          surveyResponses[matchingSurveyQuestion.id] = surveyResponse.response;
          break;
        case QuestionType.SELECT: {
          const matchingChoice = matchingSurveyQuestion.choices
            .find((choice) => choice.id === surveyResponse.response);
          if (matchingChoice) {
            surveyResponses[matchingSurveyQuestion.id] = matchingChoice.text;
          }
          break;
        }
        default:
          break;
      }
    });

    let checkedInTs = 0;
    if (virtualStatus === TicketOrderVirtualStatus.CHECKED_IN) {
      checkedInTs = ticketOrder.tickets[0].checkedInTs;
    }

    return {
      status: virtualStatus,
      selectedTicketOptions: ticketOption,
      id: ticketOrder.id,
      userId: user.id,
      phone: userContact.phone.length ? `*${userContact.phone.slice(-4)}` : '',
      registeredTs: ticketOrder.registrationTs,
      checkedInTs: checkedInTs,
      name: coreUserInfo.name,
      gender: coreUserInfo.gender,
      discount: deduppedCostAdjustorIds,
      ...surveyResponses,
    };
  }, [eventTemplate]);

  const handleCheckIn = React.useCallback(async () => {
    setSubmittingCheckIn(true);
    for (const detailedTicketOrder of selectedDetailedTicketOrders) {
      await dispatch(checkInTickets({
        ticketOrderId: detailedTicketOrder.ticketOrder.id,
        ticketIds: detailedTicketOrder.ticketOrder.tickets.map((ticket) => ticket.id),
      }));
    }

    setSubmittingCheckIn(false);
    setSelectedDetailedTicketOrders([]);
  }, [dispatch, selectedDetailedTicketOrders]);

  const btnView = submittingCheckIn ? (
    <Box sx={{ display: 'flex', mt: 16 }}>
      <CircularProgress size={20} sx={{ mr: 8 }} />
      Submitting Results
    </Box>
  ) : (
    <Box>
      <Button
        startIcon={<WhereToVoteOutlinedIcon />}
        disabled={!selectedDetailedTicketOrders.length}
        onClick={handleCheckIn}
      >
        Check In
      </Button>
    </Box>
  );

  return (
    <Page>
      <TicketOrdersTable
        topChildren={children}
        eventTemplateId={eventTemplateId}
        columns={columns}
        transformRowFn={transformRowFn}
        statusSortOrder={statusSortOrder}
        selectableStatuses={selectableStatuses}
        disableMultiSelect
        onSelectedDetailedTicketOrdersChange={setSelectedDetailedTicketOrders}
      >
        {btnView}
      </TicketOrdersTable>
    </Page>
  );
};

const selectableStatuses = [TicketOrderVirtualStatus.CONFIRMED];

const statusSortOrder = [
  TicketOrderVirtualStatus.CONFIRMED,
  TicketOrderVirtualStatus.CHECKED_IN,
  TicketOrderVirtualStatus.CONFIRMING,
  TicketOrderVirtualStatus.PENDING,
  TicketOrderVirtualStatus.WAITLIST,
  TicketOrderVirtualStatus.ERROR,
  TicketOrderVirtualStatus.CANCELLED,
  TicketOrderVirtualStatus.INIT,
];

export default CheckInTable;
