import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { Page } from 'common/src/components/base';
import EventOverviewView from './EventOverviewView';
import FinanceView from './FinanceView';

const ReportScreen = () => {
  const [tabIdx, setTabIdx] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newTabIdx: number) => {
    setTabIdx(newTabIdx);
  };

  return (
    <Page>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIdx} onChange={handleTabChange}>
          <Tab label='Event Overview' />
          <Tab label='Finance' />
        </Tabs>
      </Box>
      <Box role='tabpanel' hidden={tabIdx !== 0}>
        {tabIdx === 0 && (
          <EventOverviewView />
        )}
      </Box>
      <Box role='tabpanel' hidden={tabIdx !== 1}>
        {tabIdx === 1 && (
          <FinanceView />
        )}
      </Box>
    </Page>
  );
};

export default ReportScreen;
