import React from 'react';
import { Box } from '@mui/material';
import { ITooltipParams } from 'ag-grid-community';

import { Colors } from 'common/src/constants';

import { Text } from 'common/src/components/base';


const AgGridTableTooltip: React.FC<ITooltipParams> = ({ value }) => {
  return (
    <Box sx={{
      maxWidth: 500, border: 1, overflow: 'hidden', background: Colors.WHITE, p: 16,
    }}>
      {
        (typeof value === 'string' || value instanceof String) ?
          value.split(/\r?\n/)
            .map((line: string, idx: number) => <Text key={idx} size='paragraph'>{line}</Text>) :
          <Text size='paragraph'>{value}</Text>
      }
    </Box>
  );
};

export default AgGridTableTooltip;
