export function sortSubsetByRef(arr: string[], ref: string[]): string[] {
  // Create a map for storing the indices of the elements in the ref array
  const refIndices = new Map<string, number>();
  ref.forEach((item, index) => {
    refIndices.set(item, index);
  });

  // Custom sort function for the arr array
  const newArr = [...arr];
  newArr.sort((a, b) => {
    const indexA = refIndices.get(a) ?? -1;
    const indexB = refIndices.get(b) ?? -1;
    return indexA - indexB;
  });

  return newArr;
}

export function snakeToTitleCase(snakeCaseString: string): string {
  return snakeCaseString.split('_').map((word) =>
    word.charAt(0).toUpperCase() + word.slice(1),
  ).join(' ');
}
