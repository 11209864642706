import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import {
  CostAdjustor, EventTemplate, getTicketOrderVirtualStatus, TicketOption, TicketOrder,
  TicketOrderVirtualStatus,
} from 'common/src/models/event';
import { Gender, User, UserContact } from 'common/src/models/user';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import useAppSelector from '../../../../../hooks/useAppSelector';
import { useTicketOrdersByEventTemplate } from '../../../../../hooks/useResource';
import {
  fetchUserContactsIfUncached, fetchUsersIfUncached, selectAllUserContacts, selectAllUsers,
} from '../../../../../redux/slices/user';

import ReportTable from './ReportTable';

interface IProps {
  eventTemplate: EventTemplate;
}

const ReportView: React.FC<IProps> = ({
  eventTemplate,
}) => {
  const dispatch = useAppDispatch();

  const ticketOrders = useTicketOrdersByEventTemplate(eventTemplate.id);
  const filteredTicketOrders = React.useMemo(() => {
    if (!ticketOrders) {
      return null;
    }
    return ticketOrders.filter((ticketOrder) => !ticketOrder.internal);
  }, [ticketOrders]);

  const [detailedTicketOrders, setDetailedTicketOrders] = React.useState<{
    user: User | null,
    userContact: UserContact | null,
    ticketOrder: TicketOrder,
    virtualStatus: TicketOrderVirtualStatus,
  }[] | null>(null);

  const users = useAppSelector(selectAllUsers);
  const userContacts = useAppSelector(selectAllUserContacts);

  // Load users for ticketOrders
  React.useEffect(() => {
    if (ticketOrders === null) {
      return;
    }
    const userIds = ticketOrders.map((ticketOrder) => ticketOrder.userId);
    const deduppedUserIds = Array.from(new Set(userIds));
    dispatch(fetchUsersIfUncached(deduppedUserIds));
    dispatch(fetchUserContactsIfUncached(deduppedUserIds));
  }, [dispatch, ticketOrders]);

  React.useEffect(() => {
    if (filteredTicketOrders === null) {
      setDetailedTicketOrders(null);
      return;
    }

    setDetailedTicketOrders(filteredTicketOrders //
      .map((ticketOrder) => {
        const user = users[ticketOrder.userId] || null;
        const userContact = userContacts[ticketOrder.userId] || null;
        return {
          user: user,
          userContact: userContact,
          ticketOrder: ticketOrder,
          virtualStatus: getTicketOrderVirtualStatus(ticketOrder),
        };
      }),
    );
  }, [filteredTicketOrders, users, userContacts]);

  if (detailedTicketOrders === null) {
    return <CircularProgress />;
  }

  const getTicketOrderRowByGender = (gender: Gender | null) => {
    return calcStatRow(detailedTicketOrders,
      (user, ticketOrder, virtualStatus) => {
        return [{
          key: virtualStatus.toString(),
          count: !!gender && user.userProfile.coreUserInfo.gender !== gender ? 0 : 1,
        }];
      });
  };

  const getTicketsRowByOption = (ticketOption: TicketOption | null) => {
    return calcStatRow(detailedTicketOrders,
      (user, ticketOrder, virtualStatus) => {
        const matchingTicketCount = ticketOrder.tickets
          .filter((ticket) => !ticketOption || ticket.ticketOptionId === ticketOption.id)
          .length;

        return [{
          key: virtualStatus.toString(),
          count: matchingTicketCount,
        }];
      });
  };

  const getTicketsRowByDiscount = (ticketOption: TicketOption | null) => {
    return calcStatRow(detailedTicketOrders,
      (user, ticketOrder, virtualStatus) => {
        if (virtualStatus !== TicketOrderVirtualStatus.CONFIRMING &&
            virtualStatus !== TicketOrderVirtualStatus.CONFIRMED &&
            virtualStatus !== TicketOrderVirtualStatus.CHECKED_IN) {
          return [];
        }


        let total = 0;
        let none = 0;
        const costAdjustorIdToCount: Record<string, number> = {};
        for (const ticket of ticketOrder.tickets) {
          if (ticketOption && ticket.ticketOptionId !== ticketOption.id) {
            continue;
          }
          total += 1;

          const costAdjustors = ticketOrder.costDetails.ticketIdToCost[ticket.id].costAdjustors
            .filter((costAdjustor: CostAdjustor) => !!costAdjustor.adjustorId);
          if (!costAdjustors || costAdjustors.length === 0) {
            none += 1;
          } else {
            for (const costAdjustor of costAdjustors) {
              if (!costAdjustorIdToCount[costAdjustor.adjustorId]) {
                costAdjustorIdToCount[costAdjustor.adjustorId] = 0;
              }
              costAdjustorIdToCount[costAdjustor.adjustorId] += 1;
            }
          }
        }

        const res = Object.entries(costAdjustorIdToCount).map(([costAdjustorId, count] )=> {
          return {
            key: costAdjustorId,
            count: count,
          };
        });
        res.push({
          key: 'none',
          count: none,
        });
        res.push({
          key: 'total',
          count: total,
        });

        return res;
      });
  };

  let spendingByUser: Record<string, any> | null = null;
  if (eventTemplate.id.match(/^2024_wa_hengda\d$/)) {
    spendingByUser = detailedTicketOrders.reduce((prevVal, detailedTicketOrder) => {
      if (detailedTicketOrder.virtualStatus !== TicketOrderVirtualStatus.CONFIRMED &&
        detailedTicketOrder.virtualStatus !== TicketOrderVirtualStatus.CHECKED_IN) {
        return prevVal;
      }

      const userId = detailedTicketOrder.ticketOrder.userId;
      const surveyResponses = detailedTicketOrder.ticketOrder.surveyResponses;
      const phone = surveyResponses[0].response.replace(/\D/g, '');
      const name = surveyResponses[1].response;
      const surveyQuestion = eventTemplate.payload.hostedEventPayload.surveyQuestions
        .find((question) => question.id === surveyResponses[2].id)!;
      const classroomChoice = surveyQuestion.choices
        .find((choice) => choice.id === surveyResponses[2].response);
      const classroom = classroomChoice ? classroomChoice.text : '-';

      const key = `${userId}_${phone}_${name}_${classroom}`;

      if (!prevVal[key]) {
        prevVal[key] = {
          userId: userId,
          phone: phone,
          name: name,
          classroom: classroom,
          ticketSpendingInCents: 0,
          totalSpendingInCents: 0,
        };
      }
      prevVal[key].ticketSpendingInCents += detailedTicketOrder.ticketOrder.costDetails.subtotalInCents;
      prevVal[key].totalSpendingInCents += detailedTicketOrder.ticketOrder.costDetails.finalCostInCents;
      return prevVal;
    }, {} as Record<string, any>);
  }

  const ticketOptions = eventTemplate.payload.hostedEventPayload.ticketOptions;
  const ticketsByDiscountData = ticketOptions.map((ticketOption) => ({
    label: ticketOption.name,
    content: getTicketsRowByDiscount(ticketOption),
  }));
  const hasDiscount = ticketsByDiscountData.some((data) => Object.keys(data.content).length > 2);
  return (
    <Box>
      <ReportTable
        title='Tickets By Status'
        rows={[
          ...ticketOptions.map((ticketOption) => ({
            label: ticketOption.name,
            content: getTicketsRowByOption(ticketOption),
          })),
          ...(ticketOptions.length > 1 ? [{
            label: 'Total',
            content: getTicketsRowByOption(null),
          }] : []),
        ]}
      />

      <ReportTable
        title='Ticket Orders By Gender'
        rows={[
          { label: 'Male', content: getTicketOrderRowByGender(Gender.MALE) },
          { label: 'Female', content: getTicketOrderRowByGender(Gender.FEMALE) },
          { label: 'Unknown', content: getTicketOrderRowByGender(Gender.UNKNOWN) },
          { label: 'Total', content: getTicketOrderRowByGender(null) },
        ]}
      />

      {spendingByUser && (
        <ReportTable
          title='Spending By User'
          rows={
            Object.values(spendingByUser)
              .sort((data1, data2) => data2.ticketSpendingInCents - data1.ticketSpendingInCents)
              .map((data) => ({
                label: `${data.userId}`,
                content: {
                  'phone': data.phone,
                  'name': data.name,
                  'classroom': data.classroom,
                  'ticketSpending': `$${(data.ticketSpendingInCents/100).toFixed(2)}`,
                  'totalSpending': `$${(data.totalSpendingInCents/100).toFixed(2)}`,
                },
              }))
          }
          dynamicTable
          allowExport
        />
      )}

      {hasDiscount && (
        <ReportTable
          title='Tickets By Discount'
          rows={[
            ...ticketsByDiscountData,
            ...(ticketOptions.length > 1 ? [{
              label: 'Total',
              content: getTicketsRowByDiscount(null),
            }] : []),
          ]}
        />
      )}
    </Box>
  );
};


const calcStatRow = (
  detailedTicketOrders: {
    user: User | null,
    ticketOrder: TicketOrder,
    virtualStatus: TicketOrderVirtualStatus,
  }[],
  aggregationFn: (user: User, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus) => { key: string, count: number }[],
) => {
  const allKeys= detailedTicketOrders.reduce((prevVal, detailedTicketOrder) => {
    if (!detailedTicketOrder.user) {
      return prevVal;
    }
    aggregationFn(detailedTicketOrder.user, detailedTicketOrder.ticketOrder, detailedTicketOrder.virtualStatus).forEach((entry) => {
      if (prevVal.findIndex((elem) => elem.key === entry.key) === -1) {
        prevVal.push({ key: entry.key, count: 0 });
      }
    });
    return prevVal;
  }, [] as {key: string, count: number}[]);

  const keyCountList = detailedTicketOrders
    .reduce((prevVal, detailedTicketOrder) => {
      if (!detailedTicketOrder.user) {
        return prevVal;
      }
      aggregationFn(detailedTicketOrder.user, detailedTicketOrder.ticketOrder, detailedTicketOrder.virtualStatus).forEach((aggEntry) => {
        const entry = prevVal.find((elem) => elem.key === aggEntry.key)!;
        entry.count += aggEntry.count;
      });

      return prevVal;
    }, allKeys);

  // convert to map of key to count
  return keyCountList.reduce((prevVal, entry) => {
    prevVal[entry.key] = entry.count;
    return prevVal;
  }, {} as { [key: string]: number });
};


export default ReportView;
