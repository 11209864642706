import React from 'react';
import {
  Box, Button, InputAdornment, Modal, TextField,
} from '@mui/material';
import { ValidationError } from 'yup';

import { EventTemplate } from 'common/src/models/event';
import { eventIdSchema } from '../../../../utils/validation';

import { Text } from 'common/src/components/base';

const EVENT_ID_PREFIX = new Date().getFullYear() + '_';

interface IProps {
  originEvent: EventTemplate
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (originEventTemplateid: string, newEventTemplateId: string) => void;
}

const CloneEventModal: React.FC<IProps> = ({ originEvent, isOpen, onClose, onConfirm }) => {
  const [eventId, setEventId] = React.useState('');
  const originEventId = originEvent.id;
  const eventState = originEvent.location.state.toLowerCase();

  const effectiveEventId = `${EVENT_ID_PREFIX}${eventState}_${eventId}`;

  const [errMsg, setErrMsg] = React.useState('');
  const [showErrMsg, setShowErrMsg] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          eventIdSchema.validate(effectiveEventId),
        ]);

        setErrMsg('');
      } catch (e) {
        if (e instanceof ValidationError && e.errors.length) {
          setErrMsg(e.errors[0]);
        } else {
          setErrMsg('错误'); // This means there's a bug in the code.
        }
      }
    })();
  }, [effectiveEventId]);

  const handleConfirm = () => {
    if (!eventIdSchema.isValidSync(effectiveEventId)) {
      setShowErrMsg(true);
      return;
    }
    onConfirm(originEventId, effectiveEventId);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Clone current event</Text>
        <Text variant='italics' color='system' mb={16}>Please confirm clone of current event</Text>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>当前活动 ID</Text>
          <TextField
            disabled
            margin='normal'
            size='small'
            required
            autoFocus
            value={originEventId}
            sx={{ width: 320 }}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Text size='paragraph' color='primary'>新的活动 ID</Text>
          <TextField
            margin='normal'
            size='small'
            required
            autoFocus
            placeholder='halloween_dance'
            value={eventId}
            onChange={(event) => setEventId(event.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position='start'>{`${EVENT_ID_PREFIX}${eventState}_`}</InputAdornment>,
            }}
            sx={{ width: 320 }}
          />
        </Box>
        <Button
          variant='contained'
          disabled={!eventId || (showErrMsg && !!errMsg)}
          onClick={handleConfirm}
          sx={{ marginTop: 24 }}
        >
            Confirm
        </Button>
        {showErrMsg && <Text size='paragraph' color='error' mt={4}>{errMsg}</Text>}
      </Box>
    </Modal>
  );
};

export default CloneEventModal;
