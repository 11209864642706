import React from 'react';
import { Box, Switch } from '@mui/material';

import { Colors } from 'common/src/constants';

import { Text } from 'common/src/components/base';

const LABELS = {
  published: '已发布',
  pending: '待发布',
  unpublished: '未发布',
  archived: '已隐藏',
};

interface IProps {
  draftPublished: boolean;
  confirmPublished: boolean;
  readOnlyMode: boolean;
  onChangePublished: (published: boolean) => void;
  archived: boolean;
}

const StatusBanner: React.FC<IProps> = ({ draftPublished, confirmPublished, readOnlyMode, onChangePublished, archived }) => {
  const bannerBgColor = confirmPublished ? Colors.PRIMARY_BACKGROUND : Colors.DISABLED;
  const publishState = archived ? 'archived' :confirmPublished ? 'published' : draftPublished? 'pending' : 'unpublished';
  const publishLabel = LABELS[publishState];

  let note = '';
  switch (publishState) {
    case 'unpublished':
      note = '用户见不到此活动，但Taro管理员可在app预览此活动。';
      break;
    case 'pending':
      note = '保存完成发布。一旦发布，此操作不可撤销。';
  }

  return (
    <Box bgcolor={bannerBgColor} width='100%' mb={16} p={16} borderRadius={3}>
      <Box display='flex'>
        <Text size='subtitle' alignSelf='baseline' mr={8}>活动状态:</Text>
        <Text size='banner' alignSelf='baseline'>{publishLabel}</Text>
        {!readOnlyMode && !confirmPublished && (
          <Box alignSelf='flex-start'>
            <Switch
              checked={draftPublished}
              onChange={(e) => onChangePublished(e.target.checked)}
            />
          </Box>
        )}
      </Box>
      {!!note && (
        <Text size='paragraph' color='system' mt={8}>{note}</Text>
      )}

    </Box>
  );
};

export default StatusBanner;
