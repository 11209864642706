import React from 'react';
import { DownloadOutlined as DownloadOutlinedIcon, RefreshOutlined as RefreshOutlinedIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact';

import { EventTemplate, getTicketVirtualStatus, Ticket, TicketOrder, TicketVirtualStatus } from 'common/src/models/event';
import { User, UserContact } from 'common/src/models/user';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { useEventTemplate, useTicketOrdersByEventTemplate } from '../../hooks/useResource';
import { refreshTicketOrders } from '../../redux/slices/event';
import { fetchUserContactsIfUncached, fetchUsersIfUncached, selectAllUserContacts, selectAllUsers } from '../../redux/slices/user';

import { Text } from 'common/src/components/base';
import AgGridTableTooltip from '../base/AgGridTableTooltip';

interface IProps {
  topChildren?: React.ReactNode;
  children: React.ReactNode;
  eventTemplateId: string;
  columns: {
    field: string, width?: number, minWidth?: number, flex?: number, wrapText?: boolean,
    autoHeight?: boolean, valueFormatter?: (val: any) => any
  }[];
  transformRowFn: (
    eventTemplate: EventTemplate, user: User, userContact: UserContact, ticket: Ticket, ticketOrder: TicketOrder, virtualStatus: TicketVirtualStatus
  ) => Record<string, any>;
  statusSortOrder: TicketVirtualStatus[];
  selectableStatuses?: TicketVirtualStatus[];
  disableMultiSelect?: boolean;
  hideTitle?: boolean;
  onSelectedDetailedTicketsChange: (
    selectedDetailedTickets: { user: User | null, ticket: Ticket, ticketOrder: TicketOrder, virtualStatus: TicketVirtualStatus }[]
  ) => void;
  onShowRowDetails?: (user: User, ticketOrder: TicketOrder) => void;
}

const TicketsTable: React.FC<IProps> = ({
  topChildren, children, eventTemplateId, columns, transformRowFn, statusSortOrder,
  selectableStatuses, disableMultiSelect, hideTitle, onSelectedDetailedTicketsChange, onShowRowDetails,
}) => {
  const dispatch = useAppDispatch();

  const eventTemplate = useEventTemplate(eventTemplateId);
  const eventTemplateName = eventTemplate ? eventTemplate.name : '';

  const ticketOrders = useTicketOrdersByEventTemplate(eventTemplateId);
  const filteredTicketOrders = React.useMemo(() => {
    if (!ticketOrders) {
      return null;
    }
    return ticketOrders.filter((ticketOrder) => !ticketOrder.internal);
  }, [ticketOrders]);

  const agGridRef = React.useRef<AgGridReactType>(null);

  const [detailedTickets, setDetailedTickets] = React.useState<{
    eventTemplate: EventTemplate,
    ticket: Ticket,
    ticketOrder: TicketOrder,
    user: User | null,
    userContact: UserContact | null,
    virtualStatus: TicketVirtualStatus,
  }[] | null>(null);

  const users = useAppSelector(selectAllUsers);
  const userContacts = useAppSelector(selectAllUserContacts);

  // Load users for ticketOrders
  React.useEffect(() => {
    if (ticketOrders === null) {
      return;
    }
    const userIds = ticketOrders.map((ticketOrder) => ticketOrder.userId);
    const deduppedUserIds = Array.from(new Set(userIds));
    dispatch(fetchUsersIfUncached(deduppedUserIds));
    dispatch(fetchUserContactsIfUncached(deduppedUserIds));
  }, [dispatch, ticketOrders]);

  React.useEffect(() => {
    if (filteredTicketOrders === null || !eventTemplate) {
      setDetailedTickets(null);
      return;
    }

    setDetailedTickets(filteredTicketOrders //
      .flatMap((ticketOrder) => {
        const user = users[ticketOrder.userId] || null;
        const userContact = userContacts[ticketOrder.userId] || null;
        return ticketOrder.tickets.map((ticket) => ({
          eventTemplate: eventTemplate,
          ticket: ticket,
          ticketOrder: ticketOrder,
          user: user,
          userContact: userContact,
          virtualStatus: getTicketVirtualStatus(ticketOrder, ticket),
        }));
      }) //
      .sort((detailedTicket1, detailedTicket2) =>
        statusSortOrder.indexOf(detailedTicket1.virtualStatus) -
        statusSortOrder.indexOf(detailedTicket2.virtualStatus),
      ),
    );
  }, [eventTemplate, filteredTicketOrders, users, userContacts, statusSortOrder]);

  const defaultColDef = React.useMemo(() => ({
    sortable: true,
    resizable: true,
    editable: true,
    filter: true,
    tooltipComponent: AgGridTableTooltip,
  }), []);

  // See https://www.ag-grid.com/javascript-data-grid/column-properties/
  const effectiveCols = React.useMemo(() => {
    if (!columns.length) {
      // not loaded yet
      return [];
    }

    let effectiveColumns = columns.map((col) => {
      const effectiveColumn = {
        ...col,
        tooltipField: col.field,
      };
      if (effectiveColumn.wrapText) {
        effectiveColumn.wrapText = false;
      }
      return effectiveColumn;
    }) as any[];
    effectiveColumns = [
    // Add a fake column on left for drag operations
      { field: '', rowDrag: true, lockPosition: 'left', width: 30 },
      ...effectiveColumns,
    ];
    effectiveColumns[1].headerCheckboxSelection = false;
    effectiveColumns[1].checkboxSelection = true;
    effectiveColumns[1].showDisabledCheckboxes = true;
    effectiveColumns[1].lockPosition = 'left';

    return effectiveColumns;
  }, [columns]);

  const isRowSelectable = React.useMemo(() => {
    return (params: any) => {
      return !!params.data && (!selectableStatuses || selectableStatuses.includes(params.data.status));
    };
  }, [selectableStatuses]);

  const handleSelectionChanged = React.useCallback(() => {
    const selectedData = agGridRef.current?.api.getSelectedRows();
    const newSelectedTickets = (selectedData || []).map((data) => {
      const matchingDetailedTicket =
        detailedTickets!.find((detailedTicket) => detailedTicket.ticket.id === data.id && detailedTicket.ticketOrder.id === data.ticketOrderId);
      if (!matchingDetailedTicket) {
        throw new Error('BUG: no matching detailedTicket found for ' + data.id);
      }
      return matchingDetailedTicket;
    });

    onSelectedDetailedTicketsChange(newSelectedTickets);
  }, [detailedTickets, onSelectedDetailedTicketsChange]);

  const handleCellClicked = React.useCallback((e: any) => {
    if (!onShowRowDetails || e.column.colId !== '0') {
      return;
    }

    const matchingDetailedTicket =
        detailedTickets!.find(
          (detailedTicket) => detailedTicket.ticket.id === e.data.id &&
          detailedTicket.ticketOrder.id === e.data.ticketOrderId);

    if (matchingDetailedTicket && matchingDetailedTicket.user) {
      onShowRowDetails(matchingDetailedTicket.user, matchingDetailedTicket.ticketOrder);
    }
  }, [onShowRowDetails, detailedTickets]);

  const handleRefreshTicketOrders = React.useCallback(async () => {
    await dispatch(refreshTicketOrders({
      eventTemplateId: eventTemplateId, onlyIfUncached: false,
    }));
  }, [dispatch, eventTemplateId]);

  const handleCellEditingStopped = React.useCallback(() => {
    try {
      // automatically reset cell content, so it doesn't change
      agGridRef.current?.api.undoCellEditing();
    } catch (e) {
      // do nothing
    }
  }, []);

  if (detailedTickets === null) {
    return <CircularProgress />;
  }

  const userData = detailedTickets
    .map((detailedTicket) => {
      const eventTemplate = detailedTicket.eventTemplate;
      const user = detailedTicket.user;
      const userContact = detailedTicket.userContact;
      const ticket = detailedTicket.ticket;
      const ticketOrder = detailedTicket.ticketOrder;
      const virtualStatus = detailedTicket.virtualStatus;
      if (!user || !userContact) {
        // This can happen because in staging, users can expire. This should never happen in prod
        return {
          status: detailedTicket.virtualStatus,
          userId: ticketOrder.userId,
          name: 'MISSING',
        };
      }

      return transformRowFn(eventTemplate, user, userContact, ticket, ticketOrder, virtualStatus);
    });

  return (
    <Box>
      {!hideTitle && (<Text size='banner' ml={16} mt={16}>{eventTemplateName}</Text>)}
      {topChildren}
      <Box sx={{ display: 'flex', mb: 8, justifyContent: 'flex-end' }}>
        <Button
          size='small'
          startIcon={<DownloadOutlinedIcon />}
          sx={{ mr: 20 }}
          onClick={() => agGridRef.current?.api.exportDataAsCsv()}
        >
            Export
        </Button>
        <Button size='small' startIcon={<RefreshOutlinedIcon />} onClick={handleRefreshTicketOrders}>Refresh</Button>
      </Box>
      <Box className='ag-theme-alpine' sx={{ height: 500, mb: 10 }}>
        <AgGridReact
          ref={agGridRef}
          rowData={userData}
          columnDefs={effectiveCols} // Column Defs for Columns
          defaultColDef={defaultColDef}
          animateRows // Optional - set to 'true' to have rows animate when sorted
          suppressRowClickSelection
          rowSelection={disableMultiSelect ? 'single' :'multiple'}
          isRowSelectable={isRowSelectable}
          rowDragManaged
          onSelectionChanged={handleSelectionChanged}
          onCellClicked={handleCellClicked}
          undoRedoCellEditing
          onCellEditingStopped={handleCellEditingStopped}
        />
      </Box>

      {children}
    </Box>
  );
};

export default TicketsTable;
