import React from 'react';
import {
  EventBusyOutlined as EventBusyOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import {
  CostAdjustor, EventTemplate, getSelectedTicketOptions, TicketOrder, TicketOrderVirtualStatus,
} from 'common/src/models/event';
import { PaymentAuthorityRecord } from 'common/src/models/event/payment';
import { User, UserContact } from 'common/src/models/user';
import { queryPaymentAuthorityRecords } from 'common/src/system/network/event';
import { getHumanReadableDateTime } from 'common/src/utils/time';

import { Loading } from 'common/src/components/base';
import { TicketOrdersTable } from '../../../../../components/event';
import RefundModal from './RefundModal';

interface IProps {
  eventTemplate: EventTemplate;
}

enum ActionModalType {
  REFUND = 'refund',
}

const OrdersView: React.FC<IProps> = ({ eventTemplate }) => {
  const [selectedDetailedTicketOrders, setSelectedDetailedTicketOrders] =
    React.useState<{ user: User | null, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus }[]>([]);

  const [paymentAuthorityRecords, setPaymentAuthorityRecords] = React.useState<PaymentAuthorityRecord[] | null>(null);
  React.useEffect(() => {
    queryPaymentAuthorityRecords(eventTemplate.id).then((records) => {
      setPaymentAuthorityRecords(records);
    });
  }, [eventTemplate]);

  const [actionModalType, setActionModalType] = React.useState<ActionModalType | null>(null);

  const columns = React.useMemo(() => {
    if (!paymentAuthorityRecords) {
      return [];
    }

    return [
      { field: 'status', width: 160 },
      { field: 'authorityStatus', width: 150 },
      { field: 'intendedCharge', width: 100, valueFormatter: (data: any) => `$${(data.value / 100).toFixed(2)}` },
      { field: 'transactionFees', width: 100, valueFormatter: (data: any) => `$${(data.value / 100).toFixed(2)}` },
      { field: 'refund', width: 100, valueFormatter: (data: any) => `$${(data.value / 100).toFixed(2)}` },
      { field: 'adjustedCharge', width: 100, valueFormatter: (data: any) => `$${(data.value / 100).toFixed(2)}` },
      { field: 'capturedCharge', width: 100, valueFormatter: (data: any) => `$${(data.value / 100).toFixed(2)}` },
      { field: 'selectedTicketOptions', width: 150 },
      { field: 'registeredTs', width: 150, valueFormatter: (data: any) => {
        const registrationTs = data.value;
        return registrationTs && eventTemplate ?
          getHumanReadableDateTime(registrationTs, eventTemplate.eventTimeZone) : '-';
      } },
      { field: 'id', width: 130 },
      { field: 'userId', width: 90 },
      { field: 'discount', width: 150 },
      { field: 'paymentId', width: 150 },
    ];
  }, [eventTemplate, paymentAuthorityRecords]);

  const transformRowFn = React.useCallback((
    user: User, userContact: UserContact, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus,
  ) => {
    if (!paymentAuthorityRecords) {
      return [];
    }

    const ticketOption = getSelectedTicketOptions(ticketOrder).map((selectedTicketOption) => {
      const ticket = eventTemplate.payload.hostedEventPayload.ticketOptions
        .find((ticketOption) => ticketOption.id === selectedTicketOption.id);
      return ticket?.name + ' x ' + selectedTicketOption.quantity;
    }).join(', ');

    const costAdjustorIds = Object.values(ticketOrder.costDetails.ticketIdToCost)
      .flatMap((ticketCost) => ticketCost.costAdjustors)
      .filter((costAdjustor: CostAdjustor) => !!costAdjustor.adjustorId)
      .map((costAdjustor: CostAdjustor) => costAdjustor.adjustorId);
    const deduppedCostAdjustorIds= Array.from(new Set(costAdjustorIds)).join(', ');

    const paymentAuthorityRecord = paymentAuthorityRecords.find((record) => record.ticketOrderId === ticketOrder.id);

    return {
      status: virtualStatus,
      selectedTicketOptions: ticketOption,
      id: ticketOrder.id,
      userId: user.id,
      registeredTs: ticketOrder.registrationTs,
      discount: deduppedCostAdjustorIds,
      paymentId: paymentAuthorityRecord?.paymentId,
      authorityStatus: paymentAuthorityRecord?.authorityPaymentStatus,
      intendedCharge: paymentAuthorityRecord?.intendedAmountInCents,
      transactionFees: paymentAuthorityRecord?.feeInCents,
      refund: paymentAuthorityRecord?.refundInCents,
      adjustedCharge: paymentAuthorityRecord?.adjustedAmountInCents,
      capturedCharge: paymentAuthorityRecord?.capturedAmountInCents,
    };
  }, [eventTemplate, paymentAuthorityRecords]);

  const hasOnlyStatuses = (statuses: TicketOrderVirtualStatus[])=>{
    return selectedDetailedTicketOrders
      .every((detailedTicketOrder) => {
        return statuses.includes(detailedTicketOrder.virtualStatus);
      });
  };

  if (!paymentAuthorityRecords) {
    return <Loading />;
  }

  const selectedPaymentAuthorityRecords = selectedDetailedTicketOrders
    .map((detailedTicketOrder) => {
      return paymentAuthorityRecords.find((record) => record.ticketOrderId === detailedTicketOrder.ticketOrder.id);
    })
    .filter((record) => !!record) as PaymentAuthorityRecord[];

  const actionBtns = (
    <Box>
      <Button
        startIcon={<EventBusyOutlinedIcon />}
        disabled={!selectedPaymentAuthorityRecords.length ||
          selectedPaymentAuthorityRecords.length !== selectedDetailedTicketOrders.length ||
          !hasOnlyStatuses([
            TicketOrderVirtualStatus.PENDING,
            TicketOrderVirtualStatus.WAITLIST,
            TicketOrderVirtualStatus.CONFIRMING,
            TicketOrderVirtualStatus.CONFIRMED,
            TicketOrderVirtualStatus.ERROR,
            TicketOrderVirtualStatus.CHECKED_IN,
          ])}
        onClick={() => setActionModalType(ActionModalType.REFUND)}
      >
        Refund
      </Button>
    </Box>
  );

  let actionModal;
  if (actionModalType) {
    switch (actionModalType) {
      case ActionModalType.REFUND:
        actionModal = (
          <RefundModal
            eventTemplate={eventTemplate}
            selectedPaymentAuthorityRecords={selectedPaymentAuthorityRecords}
            onClose={() => setActionModalType(null)}
          />
        );
        break;
      default:
        throw new Error('Invalid action modal type');
    }
  }


  return (
    <Box>
      <TicketOrdersTable
        eventTemplateId={eventTemplate.id}
        columns={columns}
        transformRowFn={transformRowFn}
        statusSortOrder={statusSortOrder}
        hideTitle
        onSelectedDetailedTicketOrdersChange={setSelectedDetailedTicketOrders}
      >
        {actionBtns}
      </TicketOrdersTable>
      {actionModal}
    </Box>
  );
};

const statusSortOrder = [
  TicketOrderVirtualStatus.PENDING,
  TicketOrderVirtualStatus.WAITLIST,
  TicketOrderVirtualStatus.CONFIRMING,
  TicketOrderVirtualStatus.CONFIRMED,
  TicketOrderVirtualStatus.ERROR,
  TicketOrderVirtualStatus.CHECKED_IN,
  TicketOrderVirtualStatus.CANCELLED,
  TicketOrderVirtualStatus.INIT,
];


export default OrdersView;
