import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  WhereToVoteOutlined as WhereToVoteOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button, Link, Stack, Tab, Tabs } from '@mui/material';

import { getEventTemplateStandaloneToken } from 'common/src/system/network/event';
import { useEventTemplate } from '../../../../hooks/useResource';

import { Page, Text } from 'common/src/components/base';
import LedgerView from './LedgerView';
import OrdersView from './OrdersView';
import ReportView from './ReportView';
import TicketsView from './TicketsView';

export default function TicketOrderScreen() {
  const { eventTemplateId } = useParams();
  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for TicketOrderScreen');
  }
  const [standaloneToken, setStandaloneToken] = React.useState('');

  const navigate = useNavigate();

  const [tabIdx, setTabIdx] = React.useState(0);

  React.useEffect(() => {
    getEventTemplateStandaloneToken(eventTemplateId).then((token) => {
      setStandaloneToken(token);
    });
  }, [eventTemplateId]);

  const eventTemplate = useEventTemplate(eventTemplateId);
  if (!eventTemplate) {
    return <Page><Text>Loading...</Text></Page>;
  }

  const handleTabChange = (event: React.SyntheticEvent, newTabIdx: number) => {
    setTabIdx(newTabIdx);
  };

  return (
    <Page>
      <Link onClick={() => navigate(-1)} underline='hover' sx={{ display: 'flex' }}>
        <ArrowBackOutlinedIcon />
        Return to Event Template selection
      </Link>

      <Stack direction='row' mt={10} alignItems='center' justifyContent='space-between'>
        <Text size='subtitle'>{eventTemplate.name}</Text>
        {!!standaloneToken && (
          <Button
            onClick={() => {
              window.open(`/checkin/event/${eventTemplateId}?token=${standaloneToken}`, '_blank');
            }}
            endIcon={<WhereToVoteOutlinedIcon />}
          >
            Check In
          </Button>
        )}
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIdx} onChange={handleTabChange}>
          <Tab label='Orders' />
          <Tab label='Tickets' />
          <Tab label='Ledger' />
          <Tab label='Report' />
        </Tabs>
      </Box>
      <Box role='tabpanel' hidden={tabIdx !== 0}>
        {tabIdx === 0 && (
          <OrdersView eventTemplate={eventTemplate} />
        )}
      </Box>
      <Box role='tabpanel' hidden={tabIdx !== 1}>
        {tabIdx === 1 && (
          <TicketsView eventTemplate={eventTemplate} />
        )}
      </Box>
      <Box role='tabpanel' hidden={tabIdx !== 2}>
        {tabIdx === 2 && (
          <LedgerView eventTemplate={eventTemplate} />
        )}
      </Box>
      <Box role='tabpanel' hidden={tabIdx !== 3}>
        {tabIdx === 3 && (
          <ReportView eventTemplate={eventTemplate} />
        )}
      </Box>

    </Page>
  );
}
