import React from 'react';
import { Button } from '@mui/material';

import { EventContactType, TaroPassCategoryType, TaroPassRecord } from 'common/src/models/event';
import { uploadTaroPassImageRemote } from 'common/src/system/network/event';
import { readFile } from 'common/src/utils/file';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { useTaroPasses } from '../../../hooks/useResource';
import { saveTaroPass } from '../../../redux/slices/event';
import { selectStateFilter } from '../../../redux/slices/setting';

import { Page } from 'common/src/components/base';
import NewTaroPassModal from './NewTaroPassModal';
import TaroPassEditor from './TaroPassEditor';
import TaroPassViewer from './TaroPassViewer';

const TaroPassSelectorScreen = () => {
  const dispatch = useAppDispatch();

  const { data: taroPasses, ready: taroPassesReady } = useTaroPasses();
  const stateFilter = useAppSelector(selectStateFilter);
  const effectiveTaroPasses = React.useMemo(() => {
    return [...taroPasses.filter((taroPass) =>
      stateFilter === null || taroPass.location.state.toLowerCase() === stateFilter)];
  }, [taroPasses, stateFilter]);

  const [selectedTaroPassId, setSelectedTaroPassId] = React.useState<string|null>(null);
  const selectedTaroPass = React.useMemo(() => {
    if (!selectedTaroPassId || !effectiveTaroPasses) {
      return null;
    }
    const matchingTaroPass = effectiveTaroPasses.find((taroPass) => taroPass.id === selectedTaroPassId);
    if (!matchingTaroPass) {
      throw new Error('BUG: no matching taro pass found for ' + selectedTaroPassId);
    }
    return {
      ...matchingTaroPass,
    };
  }, [effectiveTaroPasses, selectedTaroPassId]);

  const [newTaroPassModalOpen, setNewTaroPassModalOpen] = React.useState(false);

  const handleSaveTaroPass = React.useCallback(async (taroPass: TaroPassRecord) => {
    await dispatch(saveTaroPass(taroPass));
  }, [dispatch]);

  if (!taroPassesReady) {
    return null;
  }

  return (
    <Page sx={{ mt: 16 }}>
      <TaroPassViewer
        taroPasses={effectiveTaroPasses}
        onEdit={setSelectedTaroPassId}
      />
      <Button
        sx={{ my: 24 }}
        variant='contained'
        onClick={() => setNewTaroPassModalOpen(true)}
      >
        Create New Taro Pass
      </Button>
      { !!selectedTaroPass &&(
        <TaroPassEditor
          taroPass={selectedTaroPass}
          onClose={() => setSelectedTaroPassId(null)}
          onSave = {handleSaveTaroPass}
          onUploadImg={async (f) => {
            if (!selectedTaroPassId) {
              throw new Error('BUG: adding taro pass image for invalid id: ' + selectedTaroPassId);
            }
            const base64Content = await readFile(f);
            const fileType = f.type.replace('image/', '');
            return await uploadTaroPassImageRemote(selectedTaroPassId, base64Content, fileType);
          }}
        />
      )}
      <NewTaroPassModal
        isOpen = {newTaroPassModalOpen}
        onConfirm = { async (state, id, name)=>{
          await dispatch(saveTaroPass({
            id: id,
            name: name,
            published: false,
            category: TaroPassCategoryType.UNDEFINED,
            intro: '',
            detail: '',
            location: { summary: '', address: '', city: '', state: state.toUpperCase(), zipcode: '', latlng: '', addressNotes: '' },
            refMedia: { id: '', downloadUrl: '' },
            contact: { name: '', type: EventContactType.PHONE, principal: '', refMedia: { id: '', downloadUrl: '' } },
            createdTs: 0,
            expiryTs: 0,
          }));
          setSelectedTaroPassId(id);
          setNewTaroPassModalOpen(false);
        }}
        onClose = {()=>setNewTaroPassModalOpen(false)}
      />
    </Page>
  );
};

export default TaroPassSelectorScreen;
