import { configureStore } from '@reduxjs/toolkit';

import eventSlice from './slices/event';
import settingSlice from './slices/setting';
import userSlice from './slices/user';

const PERSISTED_STATE_KEY = '__STORE__PERSISTED_STATE';

function saveToLocalStorage(state: RootState) {
  try {
    const serializedState = JSON.stringify({
      setting: state.setting,
    });
    localStorage.setItem(PERSISTED_STATE_KEY, serializedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(PERSISTED_STATE_KEY);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

const store = configureStore({
  reducer: {
    event: eventSlice,
    setting: settingSlice,
    user: userSlice,
  },
  // Use loadFromLocalStorage to overwrite any values that we already have saved
  preloadedState: loadFromLocalStorage(),
});

// listen for store changes and use saveToLocalStorage to save them to localStorage
store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
