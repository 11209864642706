import React from 'react';
import {
  EventBusyOutlined as EventBusyOutlinedIcon,
} from '@mui/icons-material';
import { Box, Button, CircularProgress, Modal, Stack, TextField } from '@mui/material';

import { Colors } from 'common/src/constants';
import { EventTemplate, TicketOrder, TicketOrderVirtualStatus } from 'common/src/models/event';
import { AdjustTicketOrderAction, TicketOrderStatus } from 'common/src/models/event/ticket';
import { User } from 'common/src/models/user';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { adjustTicketOrder } from '../../../../../redux/slices/event';

import { Text, UserInput } from 'common/src/components/base';

interface IProps {
  eventTemplate: EventTemplate;
  selectedDetailedTicketOrders: { user: User | null, ticketOrder: TicketOrder, virtualStatus: TicketOrderVirtualStatus }[];
  onClose: () => void;
}

const CancelModal: React.FC<IProps> = ({ eventTemplate, selectedDetailedTicketOrders, onClose }) => {
  const dispatch = useAppDispatch();

  const [submittingAction, setSubmittingAction] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState('');

  const handleCancel = React.useCallback(async (actionType: AdjustTicketOrderAction) => {
    setSubmittingAction(true);
    for (const detailedTicketOrder of selectedDetailedTicketOrders) {
      await dispatch(adjustTicketOrder({
        ticketOrderId: detailedTicketOrder.ticketOrder.id,
        actionType: actionType,
      }));
    }
    setSubmittingAction(false);
    onClose();
  }, [dispatch, onClose, selectedDetailedTicketOrders]);


  const statusMap = selectedDetailedTicketOrders
    .map((selectedTicketOrder) => selectedTicketOrder.virtualStatus)
    .reduce((prevVal, status) => {
      if (prevVal[status]) {
        prevVal[status] += 1;
      } else {
        prevVal[status] = 1;
      }
      return prevVal;
    }, {} as Record<string, number>);
  const selectionSummary = Object.keys(statusMap).map((key) => `${key}: ${statusMap[key]}`).join(', ');

  const ticketOrdersPassedFreeCancellation: string[] = [];
  let costIncurredInCents = 0;
  const now = Date.now();
  for (const selectedTicketOrder of selectedDetailedTicketOrders) {
    const ticketOrder = selectedTicketOrder.ticketOrder;
    if (![TicketOrderStatus.CONFIRMING, TicketOrderStatus.CONFIRMED, TicketOrderStatus.CONFIRMED].includes(ticketOrder.status)) {
      continue;
    }

    const cancellationHoursAfterEventConfirmation = 3*24;
    const cancellationHoursBeforeEvent = 8;
    const tsAfterEventConfirmation = ticketOrder.confirmationTs + cancellationHoursAfterEventConfirmation * 3600000;
    const tsBeforeEvent = eventTemplate.eventFromTs - cancellationHoursBeforeEvent * 3600000;
    const effectiveCancellationLimitTs = Math.min(tsAfterEventConfirmation, tsBeforeEvent);
    if (now < effectiveCancellationLimitTs) {
      continue;
    }

    // Anything at this point is no longer free cancellation
    ticketOrdersPassedFreeCancellation.push(ticketOrder.id);
    costIncurredInCents += ticketOrder.costDetails.finalCostInCents * 0.05;
  }

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text size='title'>Cancel Ticket Orders</Text>
        {submittingAction ? (
          <Box sx={{ display: 'flex', mt: 16 }}>
            <CircularProgress size={20} sx={{ mr: 8 }} />
            Submitting Action
          </Box>
        ) : (
          <Box>
            <Text variant='italics' color='system' mb={16}>You are about to cancel: [{selectionSummary}]</Text>

            {ticketOrdersPassedFreeCancellation.length > 0 && (
              <Box border={1} borderRadius={5} borderColor={Colors.TEXT_SYSTEM_LIGHT} p={10} mb={16}>
                <Text mb={16}>
                  <Text variant='bold' color='system'>The following ticket orders have passed the free cancellation period:</Text>
                  <Text variant='italics' color='system'>{ticketOrdersPassedFreeCancellation.join(', ')}</Text>
                </Text>

                <Text variant='italics' color='system' mb={16}>And will incur <b>${(costIncurredInCents / 100).toFixed(2)}</b> in fees if cancelled</Text>

                <Text variant='italics' color='error' mb={16}>If cancelled, your actions will generate an alert</Text>
              </Box>
            )}

            <Text variant='italics' color='system' mb={16}>This operation is irreversible. Please confirm.</Text>

            <Box mb={20}>
              <Text size='paragraph' color='primary'>Type <i>cancel</i> to confirm</Text>
              <UserInput>
                <TextField
                  variant='standard'
                  required
                  value={confirmationText}
                  onChange={(event) => {
                    setConfirmationText(event.target.value);
                  }}
                  autoComplete='off'
                  sx={{ mr: 32, width: 100 }}
                />
              </UserInput>
            </Box>

            <Stack direction='row' justifyContent='center'>
              <Button
                startIcon={<EventBusyOutlinedIcon />}
                variant='contained'
                onClick={() => onClose()}
              >
                Do not Cancel
              </Button>
              <Box width={15} />
              <Button
                startIcon={<EventBusyOutlinedIcon />}
                variant='outlined'
                disabled={confirmationText !== 'cancel'}
                onClick={() => handleCancel(AdjustTicketOrderAction.CANCEL_AND_REFUND_AND_ACCEPT_WAITLIST)}
              >
                Cancel Tickets
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CancelModal;
