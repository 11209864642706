import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LibraryAdd as LibraryAddIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { EventContactType, EventTaskCategory, ScreenType } from 'common/src/models/event';
import { Currency } from 'common/src/models/event';
import { TicketOptionVisibilitySetting } from 'common/src/models/event/ticket';
import { randStr } from 'common/src/utils/rand';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { saveEventTemplate } from '../../../../redux/slices/event';

import { Page, Text } from 'common/src/components/base';
import { EventTemplateSelector } from '../../../../components/event';
import NewEventModal from './NewEventModal';

export default function EventTemplateSelectorScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSelectEventTemplate = React.useCallback((eventTemplateId: string) => {
    navigate(`/editEventTemplate/${eventTemplateId}`);
  }, [navigate]);

  const [newEventModalOpen, setNewEventModalOpen] = React.useState(false);

  return (
    <Page sx={{ mt: 16 }}>
      <Box sx={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', display: 'grid' }}>
        <Text size='banner' mb={35}>Create or Edit an Event Template</Text>

        <Button
          sx={{ py: 10, mb: 30, width: 260 }}
          variant='contained'
          onClick={() => setNewEventModalOpen(true)}
        >
          <LibraryAddIcon sx={{ mr: 10 }}/>
          Create New Event
        </Button>

        <Text size='title' color='system-light'>- or select an event below to edit -</Text>

        <EventTemplateSelector onSelectEventTemplate={handleSelectEventTemplate} />
      </Box>
      <NewEventModal
        isOpen={newEventModalOpen}
        onClose={() => setNewEventModalOpen(false)}
        onConfirm={async (state, id, name) => {
          // Save new event and then navigate there
          await dispatch(saveEventTemplate({
            id: id,
            published: false,
            archived: false,
            visible: true,

            singlesEvent: true,
            category: EventTaskCategory.HOSTED_EVENT,
            name: name,
            intro: '',
            description: '',
            media: { id: '', downloadUrl: '' },
            thumbnailMedia: { id: '', downloadUrl: '' },
            extraMedia: [],
            extraThumbnailMedia: [],
            tags: [],

            eventFromTs: Date.now() + 364*24*60*60*1000,
            eventToTs: Date.now() + 365*24*60*60*1000,
            eventTimeZone: '',
            location: { summary: '', address: '', city: '', state: state.toUpperCase(), zipcode: '', latlng: '', addressNotes: '' },
            additionalAdvertisedStates: [],
            seatMapId: '',

            payload: {
              hostedEventPayload: {
                screeningType: ScreenType.FIRST_COME_FIRST_SERVE,

                ticketOptions: [{
                  id: 't_'+randStr(5),
                  name: 'General Admission',
                  description: '',

                  maxMaleParticipants: 0,
                  maxFemaleParticipants: 0,
                  maxGenericParticipants: 0,
                  maxTotalParticipants: 0,

                  maleCostInCents: 0,
                  femaleCostInCents: 0,
                  genericCostInCents: 0,

                  maxQuantityPerUser: 1,
                  addOns: [],

                  allowWaitlist: true,
                  registrationFromTs: 0,
                  registrationToTs: 0,
                  visibilitySetting: TicketOptionVisibilitySetting.ALWAYS,
                }],
                maxSelectableTicketOptions: 1,
                minSelectableTickets: 0,
                maxSelectableTickets: 0,
                allowMultipleTicketOrders: false,
                currency: Currency.USD,

                surveyQuestions: [],
                detailsUrl: '',

                feePolicy: {
                  taxPercentage: 0,
                  feePercentage: 0.08,
                  feeInCents: 0,
                },
                contact: {
                  name: '',
                  type: EventContactType.WECHAT,
                  principal: '',
                  refMedia: { id: '', downloadUrl: '' },
                },

                registrationFromTs: 0,
                registrationToTs: 0,
                cancellationHoursBeforeEvent: 48,
                cancellationHoursAfterEventConfirmation: 48,
                waiver: '',
                vendorId: '',
                visibilitySetting: { visibleInApp: true, visibleOnWeb: true },
                discounts: [],
              },
            },

            createdTs: 0,
          }));

          navigate(`/editEventTemplate/${id}`);
        }}
      />
    </Page>
  );
}
