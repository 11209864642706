import React from 'react';
import {
  ArrowDownwardOutlined as ArrowDownwardOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';

import { Colors } from 'common/src/constants';
import { GroupedEventTemplate } from 'common/src/models/event/eventTemplateGroup';
import { eventIdSchema, optEventNameSchema } from '../../../../utils/validation';

import { Text, UserInput } from 'common/src/components/base';

interface IProps {
  eventTemplates: GroupedEventTemplate[];
  readOnlyMode: boolean;
  showValidationErr: boolean;
  validateEventTemplateId: (eventTemplateId: string) => void;
  onChangeEventTemplates: (newEventTemplates: GroupedEventTemplate[]) => void;
}

const EventTemplatesEditor: React.FC<IProps> = ({
  eventTemplates, readOnlyMode, showValidationErr, validateEventTemplateId, onChangeEventTemplates,
}) => {
  return (
    <Box>
      {eventTemplates.map((eventTemplate, eventTemplateIdx)=>{
        const { eventTemplateId } = eventTemplate;
        const canMoveDown = eventTemplateIdx < eventTemplates.length - 1;

        return (
          <Box
            key={eventTemplateId}
            display='flex' alignItems='center'
            px={20}
          >
            <Text size='paragraph' color='system-light' width={50}>{'活动' + (eventTemplateIdx+1)}</Text>
            <EventTemplateEditor
              eventTemplate={eventTemplate}
              readOnlyMode={readOnlyMode}
              showValidationErr={showValidationErr}
              validateEventTemplateId={validateEventTemplateId}
              onChangeEventTemplate={
                (newEventTemplate) => {
                  const updatedTicketOptions = [...eventTemplates];
                  updatedTicketOptions.splice(eventTemplateIdx, 1, newEventTemplate);
                  onChangeEventTemplates(updatedTicketOptions);
                }
              }
            />
            {!readOnlyMode && (
              <Box display='flex' flexDirection='column' alignItems='center'>
                <IconButton
                  onClick={()=>{
                    const updatedTicketOptions = [...eventTemplates];
                    updatedTicketOptions.splice(eventTemplateIdx, 1);
                    onChangeEventTemplates(updatedTicketOptions);
                  }}
                  disabled={eventTemplates.length === 1}
                >
                  <DeleteOutlineIcon />
                </IconButton>
                <IconButton
                  onClick={()=>{
                    const updatedTicketOptions = [...eventTemplates];
                    const tmp = updatedTicketOptions[eventTemplateIdx];
                    updatedTicketOptions[eventTemplateIdx] = updatedTicketOptions[eventTemplateIdx+1];
                    updatedTicketOptions[eventTemplateIdx+1] = tmp;

                    onChangeEventTemplates(updatedTicketOptions);
                  }}
                  sx={{ opacity: canMoveDown ? 0.8 : 0 }}
                >
                  <ArrowDownwardOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

interface IEventTemplateEditorProps {
  eventTemplate: GroupedEventTemplate;
  readOnlyMode: boolean;
  showValidationErr: boolean;
  validateEventTemplateId: (eventTemplateId: string) => void;
  onChangeEventTemplate: (newEventTemplate: GroupedEventTemplate) => void;
}

const EventTemplateEditor: React.FC<IEventTemplateEditorProps> = ({
  eventTemplate, readOnlyMode, showValidationErr, validateEventTemplateId, onChangeEventTemplate,
}) => {
  const { eventTemplateId, name } = eventTemplate;

  return (
    <Box m={16} p={16} width='100%' border={1} borderRadius={5} borderColor={Colors.TEXT_SYSTEM_LIGHT}>
      <Box>
        <Box>
          <UserInput validationFn={showValidationErr ?
            async () => {
              await eventIdSchema.validate(eventTemplateId);
              validateEventTemplateId(eventTemplateId);
            } : null}
          >
            <TextField
              variant='standard'
              required
              label='活动 ID'
              placeholder='请输入活动 ID'
              value={eventTemplateId}
              onChange={(event) => {
                onChangeEventTemplate({
                  ...eventTemplate,
                  eventTemplateId: event.target.value,
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
              sx={{ mr: 32, width: 365 }}
            />
          </UserInput>
        </Box>
        <Box mt={16}>
          <UserInput validationFn={showValidationErr ?
            () => optEventNameSchema.validate(name) : null}
          >
            <TextField
              variant='standard'
              label='名字'
              placeholder='请输入活动名字（如空白，将使用活动本身名字）'
              value={name}
              onChange={(event) => {
                onChangeEventTemplate({
                  ...eventTemplate,
                  name: event.target.value,
                });
              }}
              inputProps={{ readOnly: readOnlyMode }}
              sx={{ mr: 32, width: 365 }}
            />
          </UserInput>
        </Box>
      </Box>
    </Box>
  );
};

export default EventTemplatesEditor;
