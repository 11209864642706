import React from 'react';
import { Avatar, Box, ButtonBase, CircularProgress, Divider, List, ListItem, Paper } from '@mui/material';

import { Colors } from 'common/src/constants';
import { EventTemplate, EventTemplateGroup } from 'common/src/models/event';
import { getEventDateLabel } from 'common/src/utils/time';
import { useEventTemplateGroups, useEventTemplates } from '../../hooks/useResource';

import { Text } from 'common/src/components/base';


interface IProps {
  onSelectEventTemplateGroup: (eventTemplateGroupId: string) => void;
}

const EventTemplateGroupSelector: React.FC<IProps> = ({ onSelectEventTemplateGroup }) => {
  const { data: eventTemplateGroups, ready: eventTemplateGroupsReady } = useEventTemplateGroups(/* forceRefresh */ false);
  const { data: eventTemplates, ready: eventTemplatesReady } = useEventTemplates(
    /* includePastEvents */ true, /* forceRefresh */ false);

  if (!eventTemplateGroupsReady || !eventTemplatesReady) {
    return <CircularProgress />;
  }

  const groupToEventTemplates:Record<string, EventTemplate[]> = eventTemplateGroups.reduce((acc, group) => {
    acc[group.id] = Object.values(eventTemplates)
      .filter((eventTemplate) => group.groupedEventTemplates
        .some((groupedEventTemplate) => groupedEventTemplate.eventTemplateId === eventTemplate.id));
    return acc;
  }, {} as Record<string, EventTemplate[]>);

  const groupToMaxEventTs: Record<string, number> = {};
  Object.entries(groupToEventTemplates).forEach(([groupId, eventTemplates]) => {
    let maxTs = 0;
    eventTemplates.forEach((eventTemplate) => {
      maxTs = Math.max(maxTs, eventTemplate.eventToTs);
    });
    groupToMaxEventTs[groupId] = maxTs;
  });

  const currentEventTemplateGroups = eventTemplateGroups
    .filter((eventTemplateGroup) => groupToMaxEventTs[eventTemplateGroup.id] >= Date.now())
    .sort((a, b) => groupToMaxEventTs[a.id] - groupToMaxEventTs[b.id]);
  const pastEventTemplateGroups = eventTemplateGroups
    .filter((eventTemplateGroup) => groupToMaxEventTs[eventTemplateGroup.id] < Date.now())
    .sort((a, b) => groupToMaxEventTs[b.id] - groupToMaxEventTs[a.id]);

  return (
    <Box>
      <Text size='subtitle' mt={24}>Current Groups</Text>
      <List sx={{ mb: 8, width: 400 }} >
        {
          currentEventTemplateGroups.map((eventTemplateGroup) => {
            return (
              <ListItem key={eventTemplateGroup.id}>
                <EventTemplateGroupCard
                  eventTemplateGroup={eventTemplateGroup}
                  eventTemplates={groupToEventTemplates[eventTemplateGroup.id]}
                  isCurrent={true}
                  onSelectEventTemplateGroup={onSelectEventTemplateGroup}
                />
              </ListItem>
            );
          })
        }
      </List>

      <Divider />

      <Text size='subtitle' mt={24}>Past Groups</Text>
      <List sx={{ mb: 8, width: 400 }} >
        {
          pastEventTemplateGroups.map((eventTemplateGroup) => {
            return (
              <ListItem key={eventTemplateGroup.id}>
                <EventTemplateGroupCard
                  eventTemplateGroup={eventTemplateGroup}
                  eventTemplates={groupToEventTemplates[eventTemplateGroup.id]}
                  isCurrent={false}
                  onSelectEventTemplateGroup={onSelectEventTemplateGroup}
                />
              </ListItem>
            );
          })
        }
      </List>
    </Box>
  );
};

interface IEventTemplateGroupCardProps {
  eventTemplateGroup: EventTemplateGroup;
  eventTemplates: EventTemplate[];
  isCurrent: boolean;
  onSelectEventTemplateGroup: (eventTemplateGroupId: string) => void;
}
const EventTemplateGroupCard: React.FC<IEventTemplateGroupCardProps> = ({
  eventTemplateGroup, eventTemplates, isCurrent, onSelectEventTemplateGroup,
}) => {
  const handleSelectEventTemplateGroup = React.useCallback(() => {
    onSelectEventTemplateGroup(eventTemplateGroup.id);
  }, [eventTemplateGroup, onSelectEventTemplateGroup]);

  const tag = isCurrent ? 'Active' : 'Closed';
  const bgColor = isCurrent ? Colors.PRIMARY_BACKGROUND : Colors.DISABLED;

  let minEventFromTs = Number.MAX_SAFE_INTEGER;
  let maxEventToTs = 0;
  let eventTimeZone: undefined | null | string = undefined;
  for (const eventTemplate of eventTemplates) {
    minEventFromTs = Math.min(minEventFromTs, eventTemplate.eventFromTs);
    maxEventToTs = Math.max(maxEventToTs, eventTemplate.eventToTs);
    if (eventTimeZone === undefined) {
      eventTimeZone = eventTemplate.eventTimeZone;
    } else if (eventTimeZone !== eventTemplate.eventTimeZone) {
      eventTimeZone = null;
      break;
    }
  }
  const dateLabel = eventTimeZone ? getEventDateLabel(minEventFromTs, maxEventToTs, eventTimeZone) : 'Unknown';

  return (
    <ButtonBase sx={{ width: 400 }}>
      <Paper
        sx={{
          padding: 8,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: bgColor,
        }}
        onClick={handleSelectEventTemplateGroup}
      >
        <Box>
          <Avatar
            src={eventTemplates.length ? eventTemplates[0].thumbnailMedia.downloadUrl: ''} alt=''
            variant='rounded'
            sx={{ width: 100, height: 75 }}
          />
          <Box
            position='absolute' top={13} left={10}
            bgcolor={Colors.PRIMARY_BACKGROUND} borderRadius={1} px={5} py={2}
          >
            <Text size='subnote' lineHeight={1.1}>{tag}</Text>
          </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Text size='subtitle' variant='bold' color='primary' mb={10}>{eventTemplateGroup.name}</Text>
          <Text size='paragraph' color='system'>
            Group Date: {dateLabel}
          </Text>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

export default EventTemplateGroupSelector;
