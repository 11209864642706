import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { Html5QrcodeScanner } from 'html5-qrcode';

import { Colors } from 'common/src/constants';
import SessionAccessor from 'common/src/system/session';

import { Page } from 'common/src/components/base';
import ScanResult from './ScanResult';

export default function CheckInQRScannerScreen() {
  const navigate = useNavigate();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const eventTemplateId = query.get('eventTemplateId');
  const accessToken = query.get('token');

  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for CheckInQRScannerScreen');
  }

  React.useEffect(() => {
    if (accessToken) {
      SessionAccessor.setStandaloneSessionToken(accessToken);
    } else if (!SessionAccessor.getStandaloneSessionToken()) {
      navigate('/error');
    }
  }, [accessToken, navigate]);

  const [scannedData, setScannedData] = React.useState('');

  React.useEffect(() => {
    const onScanSuccess = (scannedData_: string) => {
      if (scannedData_) {
        setScannedData(scannedData_);
      }
    };

    const scanner = new Html5QrcodeScanner('qrscanner-elem',
      {
        fps: 10,
        qrbox: 120,
        videoConstraints: {
          facingMode: 'environment',
        },
      },
      /* verbose= */ false,
    );
    scanner.render(onScanSuccess, () => {});

    return () => {
      scanner.clear();
    };
  }, []);

  return (
    <Page>
      <Container>
        <Box display='flex' justifyContent='center'>
          <Box id='qrscanner-elem' width={200} height={200} />
        </Box>
        <Box
          mt={120 /* this is necessary because the buttons extend beyond the initial height on mobile */}
          py={20} bgcolor={Colors.DEFAULT_BACKGROUND}
        >
          <Container>
            <ScanResult
              eventTemplateId={eventTemplateId}
              scannedData={scannedData}
            />
          </Container>
        </Box>
      </Container>
    </Page>
  );
}
