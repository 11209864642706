import React from 'react';
import { Avatar, Box, ButtonBase, CircularProgress, Divider, List, ListItem, Paper } from '@mui/material';

import { Colors } from 'common/src/constants';
import { EventTemplate } from 'common/src/models/event';
import { getHumanReadableDateTimeRange } from 'common/src/utils/time';
import { useEventTemplatesWithFilter } from '../../hooks/useResource';

import { Text } from 'common/src/components/base';


interface IProps {
  onSelectEventTemplate: (eventTemplateId: string) => void;
}

const EventTemplateSelector: React.FC<IProps> = ({ onSelectEventTemplate }) => {
  const { data: eventTemplates, ready: eventTemplatesReady } = useEventTemplatesWithFilter(
    /* includePastEvents */ true, /* forceRefresh */ false);

  if (!eventTemplatesReady) {
    return <CircularProgress />;
  }

  const currentEventTemplates = eventTemplates
    .filter((eventTemplate) => eventTemplate.eventToTs >= Date.now())
    .sort((a, b) => a.eventToTs - b.eventToTs);
  const pastEventTemplates = eventTemplates
    .filter((eventTemplate) => eventTemplate.eventToTs < Date.now())
    .sort((a, b) => b.eventToTs - a.eventToTs);

  return (
    <Box>
      <Text size='subtitle' mt={24}>Current Event Templates</Text>
      <List sx={{ mb: 8, width: 400 }} >
        {
          currentEventTemplates.map((eventTemplate) => {
            return (
              <ListItem key={eventTemplate.id}>
                <EventTemplateCard
                  eventTemplate={eventTemplate}
                  isCurrent={true}
                  onSelectEventTemplate={onSelectEventTemplate}
                />
              </ListItem>
            );
          })
        }
      </List>

      <Divider />

      <Text size='subtitle' mt={24}>Past Event Templates</Text>
      <List sx={{ mb: 8, width: 400 }} >
        {
          pastEventTemplates.map((eventTemplate) => {
            return (
              <ListItem key={eventTemplate.id}>
                <EventTemplateCard
                  eventTemplate={eventTemplate}
                  isCurrent={false}
                  onSelectEventTemplate={onSelectEventTemplate}
                />
              </ListItem>
            );
          })
        }
      </List>
    </Box>
  );
};

interface IEventTemplateCardProps {
  eventTemplate: EventTemplate;
  isCurrent: boolean;
  onSelectEventTemplate: (eventTemplateId: string) => void;
}
const EventTemplateCard: React.FC<IEventTemplateCardProps> = ({
  eventTemplate, isCurrent, onSelectEventTemplate,
}) => {
  const handleSelectEventTemplate = React.useCallback(() => {
    onSelectEventTemplate(eventTemplate.id);
  }, [eventTemplate, onSelectEventTemplate]);

  let bgColor = Colors.PRIMARY_BACKGROUND;
  let tag = 'Active';
  if (eventTemplate.archived) {
    bgColor = Colors.DISABLED;
    tag = 'Archived';
  } else if (eventTemplate.payload.hostedEventPayload.registrationFromTs > Date.now()) {
    bgColor = Colors.DISABLED;
    tag = 'Not Open';
  } else if (eventTemplate.payload.hostedEventPayload.registrationToTs < Date.now()) {
    bgColor = isCurrent ? Colors.DISABLED : Colors.PRIMARY_BACKGROUND;
    tag = 'Closed';
  } else if (!eventTemplate.published) {
    bgColor = Colors.DISABLED;
    tag = 'Unpublished';
  }

  return (
    <ButtonBase sx={{ width: 400 }}>
      <Paper
        sx={{
          padding: 8,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: bgColor,
        }}
        onClick={handleSelectEventTemplate}
      >
        <Box>
          <Avatar
            src={eventTemplate.thumbnailMedia.downloadUrl} alt=''
            variant='rounded'
            sx={{ width: 100, height: 75 }}
          />
          <Box
            position='absolute' top={13} left={10}
            bgcolor={Colors.PRIMARY_BACKGROUND} borderRadius={1} px={5} py={2}
          >
            <Text size='subnote' lineHeight={1.1}>{tag}</Text>
          </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Text size='subtitle' variant='bold' color='primary' mb={10}>{eventTemplate.name}</Text>
          <Text size='paragraph' color='system'>
            {getHumanReadableDateTimeRange(
              eventTemplate.eventFromTs, eventTemplate.eventToTs, eventTemplate.eventTimeZone)}
          </Text>
        </Box>
      </Paper>
    </ButtonBase>
  );
};

export default EventTemplateSelector;
